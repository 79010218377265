import React, { useEffect } from 'react'; // eslint-disable-line
import 'firebase/analytics';

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';
import { Link } from 'react-router-dom';
import { Button } from './Button';

const policyVersionNumber = '1';

export const CookieBanner = () => {
  const [policyVersion, setPolicyVersion] = React.useState(localStorage.getItem('policyVersion') || '');

  useEffect(() => {
    localStorage.setItem('policyVersion', policyVersion);
  }, [policyVersion]);

  function HandleAccept() {
    setPolicyVersion(policyVersionNumber);

    try {
      // This is defined in the <head> declared in index.html
      const initAnalytics = (window as any).startAnalyticsTracking;
      if (initAnalytics) {
        // Timeout to allow the localstorage write in the effect hook
        setTimeout(initAnalytics, 100);
      } else {
        // the script in the head tag has broken
      }
    } catch (error) {
      // Analytics are disabled
    }
  }

  return policyVersion !== policyVersionNumber ? (
    <div css={[t.fixed, t.bottom('24px'), t.left('24px'), t.drop_shadow_3, t.p_5, t.bg_tint_5, t.w(`calc(100% - 48px)`), t.md([t.w('472px')]), t.z_50]}>
      <div css={[t.typeStyle_md4, t.mb_2]}>Review our cookie policy</div>
      <div css={[t.gridTypeStyle_6, t.mb_5]}>
        We use cookies to improve your experience and deliver personalized content. By continuing to use this site you agree to our{' '}
        <Link to="/cookie-policy" css={[t.text_dark_1]}>
          Cookie Policy
        </Link>
        .
      </div>
      <Button type="button" label="I agree" styleType="secondary" onClick={HandleAccept} />
    </div>
  ) : null;
};
