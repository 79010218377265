import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';
import { motion } from 'framer-motion';

export const HamburgerIcon = (props: unknown) => {
  return (
    <motion.svg role="img" aria-labelledby="menuIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <title id="menuIconTitleID">Menu Icon</title>
      <rect x="4" y="4" width="16" height="1" css={[t.fill_current]} />
      <rect x="4" y="10" width="16" height="1" css={[t.fill_current]} />
      <rect x="4" y="16" width="16" height="1" css={[t.fill_current]} />
    </motion.svg>
  );
};
