import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';

export const InstagramIcon = (props: {}) => {
  return (
    <svg role="img" aria-labelledby="instagramIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <title id="instagramIconTitleID">Instagram Icon</title>
      <rect x="1.5" y="1.5" width="21" height="21" rx="3.5" css={[t.stroke_current]} />
      <circle cx="12" cy="12" r="4.5" css={[t.stroke_current]} />
      <circle cx="18" cy="6" r="1.5" css={[t.stroke_current]} />
    </svg>
  );
};
