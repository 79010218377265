import React from 'react'; // eslint-disable-line
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { SearchBoxProvided, connectSearchBox } from 'react-instantsearch-core';

// This enables searching with arbitrary strings
function HiddenSearch(props: SearchBoxProvided & { query: string }) {
  const query = props.query;
  const refinement = props.currentRefinement;
  const refine = props.refine;
  React.useEffect(() => {
    if (query !== refinement) refine(query);
  }, [query, refinement, refine]);
  return <React.Fragment></React.Fragment>;
}

export default connectSearchBox(HiddenSearch);
