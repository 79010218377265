import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../theme/admin/adminStyles';
import { withAdminAccount } from '../../hocs/withAdminAccount';
import { AdminPage } from './components/AdminPage';
import {
  useDiscoveryContent,
  AddOccasion,
  RemoveOccasion,
  MoveOccasion,
  SetOccasionTitle,
  RemoveOccasionArtist,
  MoveOccasionArtist,
  PublishOccasions,
  DiscoveryContentOccasion,
  AddOccasionArtist,
} from '../../contexts/DiscoveryContentContext';
import { AdminButton } from './components/AdminButton';
import { ShuffleDirection } from './components/AdminShuffleCard';
import { AdminCardHeader } from './components/AdminCardHeader';
import { AdminListButton } from './components/AdminListButton';
import { AdminInputText } from './components/AdminInputText';
import { AdminArtistList } from './artists/AdminArtistList';
import { AdminCard } from './components/AdminCard';
import { AdminTabs } from './components/AdminTabs';
import { AdminTab } from './components/AdminTab';
import { AdminTabPanel } from './components/AdminTabPanel';

function DiscoveryOccasions() {
  const discoveryContent = useDiscoveryContent();
  if (!discoveryContent) return null;

  return (
    <React.Fragment>
      {discoveryContent.occasions.map((occasion, i, array) => {
        return <AdminOccasionCard key={occasion.id} occasion={occasion} index={i} totalOccasions={array.length} />;
      })}
    </React.Fragment>
  );
}

const AdminOccasionCard = (props: { occasion: DiscoveryContentOccasion; index: number; totalOccasions: number }) => {
  const [value, SetValue] = React.useState(0);

  const handleRemove = async () => {
    await RemoveOccasion(props.occasion.id);
  };

  const handleShuffle = async (direction: ShuffleDirection) => {
    await MoveOccasion(props.occasion.id, direction);
  };

  const handleAddArtist = async (artistId: string) => {
    await AddOccasionArtist(props.occasion.id, artistId);
  };

  const handleRemoveArtist = async (artistId: string) => {
    await RemoveOccasionArtist(props.occasion.id, artistId);
  };

  const handleArtistShuffle = async (artistId: string, direction: ShuffleDirection) => {
    await MoveOccasionArtist(props.occasion.id, artistId, direction);
  };

  const handleSetTitle = async (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.currentTarget.value !== props.occasion.title) await SetOccasionTitle(props.occasion.id, event.currentTarget.value);
  };

  const handleValueChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    SetValue(newValue);
  };

  return (
    <AdminCard>
      <AdminCardHeader
        key={props.occasion.id}
        onShuffle={handleShuffle}
        first={props.index === 0}
        last={props.index + 1 === props.totalOccasions}
        actionsChildren={
          <React.Fragment>
            <AdminListButton label="Remove" critical={true} onClick={handleRemove} />
          </React.Fragment>
        }
      >
        <div css={[t.flex_auto, t.flex, t.flex_row]}>
          <div css={[t.flex_auto, t.ml_4, t.mr_6, t.text_dark_3, t.text_base, t.flex, t.flex_col, t.justify_center]}>
            Occasion #{props.index + 1}: {props.occasion.title}
          </div>
        </div>
      </AdminCardHeader>
      <div css={[t.flex, t.flex_row]}>
        <div css={[t.flex_none, t.flex, t.flex_col, t.min_w('120px'), t.bg_tint_4, t.border_r, t.border_solid, t.border_tint_3, t.py_3]}>
          <AdminTabs orientation="vertical" value={value} onChange={handleValueChange}>
            <AdminTab label="Details" />
            <AdminTab label="Artists" />
          </AdminTabs>
        </div>
        <div css={[t.flex_auto]}>
          <AdminTabPanel value={value} index={0}>
            <div css={[t.pt_4, t.pb_6, t.px_6]}>
              <div css={[t.mb_4]}>
                <AdminInputText
                  id={`${props.occasion.id}_title`}
                  label="Title"
                  name={`${props.occasion.id}_title`}
                  defaultValue={props.occasion.title}
                  onBlur={handleSetTitle}
                />
              </div>
            </div>
          </AdminTabPanel>
          <AdminTabPanel value={value} index={1}>
            <AdminArtistList
              label="Artists"
              dialogLabel={`Add Artists: ${props.occasion.title} `}
              artists={props.occasion.artists}
              onAddArtist={handleAddArtist}
              onRemoveArtist={handleRemoveArtist}
              onArtistShuffle={handleArtistShuffle}
            />
          </AdminTabPanel>
        </div>
      </div>
    </AdminCard>
  );
};

const AdminOccasionsComponent = () => {
  const handlePublish = async () => {
    await PublishOccasions();
  };
  return (
    <AdminPage
      pageHeading="Occasions"
      headChildren={
        <div css={[t.flex_auto, t.flex, t.flex_row, t.justify_end, t.items_center]}>
          <AdminButton type="button" label="Publish" onClick={handlePublish} />
        </div>
      }
    >
      <div css={[t.mb_5]}>
        <AdminButton type="button" label="Add New Occasion" styleType="secondary" onClick={AddOccasion} />
      </div>
      <DiscoveryOccasions />
    </AdminPage>
  );
};

export const AdminOccasions = withAdminAccount(AdminOccasionsComponent);
