import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';
import { motion } from 'framer-motion';

export const SmallCloseIcon = (props: unknown) => {
  return (
    <motion.svg aria-labelledby="closeIconTitleID" width="8" height="8" viewBox="0 0 8 8" fill="none" {...props}>
      <title id="closeIconTitleID">Close Icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7.27276L0.727268 8.00004L4.00001 4.72726L7.27277 8.00006L8.00004 7.27278L4.72728 3.99998L7.99994 0.727276L7.27268 0L4.00001 3.27271L0.727362 2.33131e-05L9.48468e-05 0.727299L3.27274 3.99998L0 7.27276Z"
        css={[t.fill_current]}
      />
    </motion.svg>
  );
};
