import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx, ClassNames } from '@emotion/core';
import t, { CSSStyle } from '../../theme/newstyles';
import ReactModal from 'react-modal';

export function Dialog(props: {
  isOpen: boolean;
  aria?: ReactModal.Aria;
  closeOnOverlayClick?: boolean;
  onRequestClose?: (event: React.MouseEvent | React.KeyboardEvent) => void;
  children?: React.ReactNode;
  overlayStyle?: CSSStyle;
  dialogStyle?: CSSStyle;
}) {
  const portalStyle: CSSStyle = [t.fixed, t.inset_0, t.z_80, t.overflow_y_auto];
  const hiddenStyle: CSSStyle = [t.hidden];
  const overlayStyle: CSSStyle = [
    t.flex,
    t.flex_col,
    t.items_center,
    t.px_4,
    t.overflow_y_auto,
    t.before([t.fixed, t.inset_0, t.bg_dark_1_a40, t.z_minus_1, t.content_some]),
    props.overlayStyle,
  ];
  const dialogSyle: CSSStyle = [
    t.outline_none,
    t.mt('120px'),
    t.max_w('620px'),
    t.w_full,
    t.bg_tint_5,
    t.border,
    t.border_solid,
    t.border_tint_3,
    t.flex,
    t.flex_col,
    t.mb_8,
    props.dialogStyle,
  ];

  return (
    <ClassNames>
      {(css) => {
        const overlayClassName = css.css(overlayStyle);
        const dialogClassName = css.css(dialogSyle);
        const portalClassName = css.css(portalStyle);
        const hiddenPortalClassName = css.css([portalStyle, hiddenStyle]);
        const bodyClassName = css.css([t.overflow_y_hidden]);
        return (
          <ReactModal
            isOpen={props.isOpen}
            bodyOpenClassName={bodyClassName}
            portalClassName={props.isOpen ? portalClassName : hiddenPortalClassName}
            overlayClassName={overlayClassName}
            className={dialogClassName}
            onRequestClose={props.onRequestClose}
            shouldCloseOnOverlayClick={props.closeOnOverlayClick !== undefined ? props.closeOnOverlayClick : true} /* Or should this be false? */
            aria={props.aria}
          >
            {props.children}
          </ReactModal>
        );
      }}
    </ClassNames>
  );
}
