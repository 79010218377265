import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { motion, Variants } from 'framer-motion';
import t from '../../theme/newstyles';
import { withAdminAccount } from '../../hocs/withAdminAccount';
import { FacebookIcon } from '../../icons/FacebookIcon';
import { InstagramIcon } from '../../icons/InstagramIcon';
import { TwitterIcon } from '../../icons/TwitterIcon';
// import { ArtistPagePackages } from './ArtistPagePackages';
import { AnimationTestArtistPageInfo } from './AnimationTestArtistPageInfo';
// import { ArtistPageSampleMusic } from './ArtistPageSampleMusic';
// import { ArtistApproachPage } from './ArtistApproach';
import { ArtistPageSocialMedia, SocialMediaLink } from '../artistProfile/ArtistPageSocialMedia';
import { YoutubeIcon } from '../../icons/YoutubeIcon';
import { SpotifyIcon } from '../../icons/SpotifyIcon';
import { useAnimationTestController } from './AnimationTestContext';

const socialMediaIcon = css`
  width: 24px;
  height: 24px;
`;

const delay = 0.1;

const moduleVariants: Variants = {
  invisible: { opacity: 0, y: 40 },
  visible: { opacity: 1, y: 0 },
};

const AnimationTestArtistPagePage = (props: {}) => {
  const socialLinks: SocialMediaLink[] = [
    { url: '', label: `View the Facebook page `, icon: <FacebookIcon css={[socialMediaIcon]} /> },
    { url: '', label: `View the Instagram page`, icon: <InstagramIcon css={[socialMediaIcon]} /> },
    { url: '', label: `View the Twitter page`, icon: <TwitterIcon css={[socialMediaIcon]} /> },
    { url: '', label: `View the Youtube page`, icon: <YoutubeIcon css={[socialMediaIcon]} /> },
    { url: '', label: `View the Spotify page`, icon: <SpotifyIcon css={[socialMediaIcon]} /> },
  ];

  return (
    <div css={[t.pt_header]}>
      <main css={[t.bg_tint_5, t.min_h_screen, t.flex, t.flex_col]}>
        <AnimationTestArtistPageInfo
          artist={{
            name: 'Alyssa Beyer',
            to: '/admin/animationArtistPage',
            photoURL: '/b9204a00-707f-11ea-ac87-49d2be68a06f.jpg',
            additionalImages: ['/78a77040-7082-11ea-ab9e-1fd9c03fed17.jpg', '/786e85f0-7082-11ea-ab9e-1fd9c03fed17.jpg'],
            genreTags: ['Rock', 'Pop', 'Electronic'],
            relatedProjects: ['Cut Teeth', 'Veena', 'The Felix Culpa'],
            shortBio: `Alissa Beyer (The Forty-Eight) is a 24 year old Texas native based out of Los Angeles, CA. Although her roots are in rock music, her pop sensibility is a force to be reckoned with. Her lyrics are strong and relatable but what makes her stand out the most are her melodies. From intimate acoustic love songs to anthemic power pop, Alissa's melodies will hook any listener and bring them out of their shell into a world where emotions run wild.`,
          }}
        />
        <motion.div
          initial="invisible"
          animate="visible"
          variants={moduleVariants}
          transition={{ duration: 1.4, delay: delay + 0.5, ease: t.ease_1 }}
          css={[t.flex, t.flex_col]}
        >
          {/* <ArtistPageSampleMusic />
          <ArtistPagePackages packages={page.packages} />
          <ArtistApproachPage /> */}
          <ArtistPageSocialMedia links={socialLinks} />
        </motion.div>
      </main>
    </div>
  );
};

const AnimationTestArtistPageComponent = () => {
  const animationController = useAnimationTestController();

  return <AnimationTestArtistPagePage key={animationController.key} />;
};

export const AnimationTestArtistPage = withAdminAccount(AnimationTestArtistPageComponent);
