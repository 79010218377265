import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';

export type AdminSearchRowProps = {
  isHovered: boolean;
};

export const AdminSearchTableRow = (props: { children?: (rowProps: AdminSearchRowProps) => React.ReactNode }) => {
  const [isHovered, SetHover] = React.useState(false);

  function HandleMouseEnter(event: React.MouseEvent) {
    SetHover(true);
  }

  function HandleMouseLeave(event: React.MouseEvent) {
    SetHover(false);
  }

  return (
    <tr css={[t.relative, t.hover(t.bg_tint_4)]} onMouseEnter={HandleMouseEnter} onMouseLeave={HandleMouseLeave}>
      {props.children &&
        props.children({
          isHovered: isHovered,
        })}
    </tr>
  );
};
