import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';

export const WebsiteIcon = (props: {}) => {
  return (
    <svg role="img" aria-labelledby="websiteIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <title id="websiteIconTitleID">Personal Website Icon</title>
      <circle cx="11.5" cy="11.5" r="9" css={[t.stroke_current]} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4823 2.93556C8.86577 4.41022 7 7.66538 7 11.5C7 15.3346 8.86577 18.5897 11.4823 20.0644L10.9914 20.9356C8.01322 19.2571 6 15.638 6 11.5C6 7.36192 8.01322 3.74283 10.9914 2.06439L11.4823 2.93556Z"
        css={[t.fill_current]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7545 20.0644C14.371 18.5898 16.2368 15.3346 16.2368 11.5C16.2368 7.66543 14.371 4.41027 11.7545 2.93561L12.2455 2.06444C15.2236 3.74287 17.2368 7.36196 17.2368 11.5C17.2368 15.6381 15.2236 19.2572 12.2455 20.9356L11.7545 20.0644Z"
        css={[t.fill_current]}
      />
      <rect x="11" y="3" width="1" height="18" css={[t.fill_current]} />
      <rect x="2" y="11" width="19" height="1" css={[t.fill_current]} />
    </svg>
  );
};
