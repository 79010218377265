import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';

export const UploadIcon = (props: unknown) => {
  return (
    <svg aria-labelledby="uploadIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <title id="uploadIconTitleID">Upload Icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 3.73479L5.86634 10.3402L5.13354 9.65976L11.9999 2.26518L18.8663 9.65976L18.1335 10.3402L11.9999 3.73479Z"
        css={[t.fill_current]}
      />
      <rect x="11.5" y="17" width="14" height="1" transform="rotate(-90 11.5 17)" css={[t.fill_current]} />
      <rect x="3" y="20" width="18" height="1" css={[t.fill_current]} />
    </svg>
  );
};
