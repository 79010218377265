import { css } from '@emotion/core';

export const userSelect = {
  select_none: css`
    user-select: none;
  `,
  select_text: css`
    user-select: text;
  `,
  select_all: css`
    user-select: all;
  `,
  select_auto: css`
    user-select: auto;
  `,
};
