import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Footer } from './Footer';
import { useAccount } from '../../contexts/AccountContext';

export const AccountFooter = () => {
  const account = useAccount();
  if (account === undefined) return null;
  if (!account) return null;

  return <Footer />;
};
