import React from 'react'; // eslint-disable-line
import { motion, useViewportScroll, useTransform, useMotionValue } from 'framer-motion';

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../theme/newstyles';
import { useThemeController } from '../../contexts/ThemeContext';
import { useSiteWidePromo } from '../../contexts/SiteWidePromoContext';
import { useBehaviorData } from '../../contexts/BehaviorContext';
import { useWindowDimensions } from '../../contexts/BreakpointContext';
import { CloseIcon } from '../../icons/CloseIcon';
import { IconButton } from '../IconButton';
import { SiteWidePromo } from '../../types/shared-types';

const Marquee = (props: { promo: SiteWidePromo }) => {
  const { promo } = props;
  const { windowWidth } = useWindowDimensions();

  const marqueeVariants = {
    animate: {
      // may need to change end value based on promo length
      // longer promo length = larger ending value
      x: [windowWidth, -800],
      transition: {
        x: {
          repeat: Infinity,
          repeatType: 'loop',
          duration: 15,
          ease: 'linear',
        },
      },
    },
  };

  return (
    <div css={[t.relative, t.w_screen, t.max_w_screen, t.overflow_x_hidden]}>
      <motion.div variants={marqueeVariants} animate="animate" css={[t.absolute, t.whitespace_no_wrap, t.pt_2, t.pb('6px')]}>
        <span>{promo.name}</span> <span>{promo.description}</span>
      </motion.div>
    </div>
  );
};

const PromoBanner = () => {
  const promo = useSiteWidePromo();
  const { isScrollAtTop } = useBehaviorData();
  const { windowWidth } = useWindowDimensions();
  const shouldMarquee = windowWidth < 880; // value based on 110 promo character count limit

  const variants = {
    visible: { height: 'auto' },
    hidden: { height: '0px' },
  };

  if (!promo.active) return null;
  return (
    <motion.div
      css={[t.bg_color(t.primary_4), t.text_tint_5, t.flex, t.overflow_hidden, t.typeStyle_6, t.relative]}
      initial={{ height: 0 }}
      variants={variants}
      animate={isScrollAtTop || promo.hasDismissedPromo ? 'hidden' : 'visible'}
      transition={{ ease: 'easeOut', duration: 0.3 }}
      style={{ zIndex: 80, lineHeight: '28px' }}
    >
      {shouldMarquee ? (
        <Marquee promo={promo} />
      ) : (
        <div css={[t.text_center, t.pt_2, t.pb('6px'), t.ml('58px'), t.mr_4, t.sm([t.ml('68px'), t.mr_5]), t.md([t.pl_7, t.mx_auto])]}>
          <span>{promo.name}</span> <span>{promo.description}</span>
        </div>
      )}
      <div css={[t.mr('16px'), t.pt_2, t.pb('6px'), t.md([t.mr('24px')])]}>
        <IconButton type="button" onClick={promo.dismissPromo} outerStyles={[t.justify_center]}>
          <CloseIcon pathStyles={[t.text_white]} />
        </IconButton>
      </div>
    </motion.div>
  );
};

export const GlobalHeader = (props: {
  logoTo?: string;
  logoAriaLabel?: string;
  logoLockup?: boolean;
  leftAlignLogo?: boolean;
  hasHeaderBanner?: boolean;
  isHeaderVisible?: boolean;
  promoBannerEnabled?: boolean;
  children?: React.ReactNode;
}) => {
  const { dark } = useThemeController();
  const { scrollY } = useViewportScroll();
  const isHeaderVisible = props.isHeaderVisible;
  const noOpacity = useMotionValue(0);
  const opacity: number | any = useTransform(scrollY, [0, 56], [0, 1]);
  const dynamicOpacity = isHeaderVisible ? noOpacity : opacity;

  return (
    <motion.header
      css={[
        dark ? t.bg_dark_1 : t.bg_tint_5,
        t.fixed,
        t.top_0,
        t.max_w_full,
        t.w_screen,
        t.z_80,
        t.before([
          t.absolute,
          t.w_full,
          t.bottom_0,
          isHeaderVisible ? t.border_b : t.border_b_0,
          t.border_solid,
          dark ? t.border_dark_2 : t.border_tint_3,
          t.content_some,
        ]),
      ]}
    >
      {props.promoBannerEnabled && <PromoBanner />}
      <motion.div css={[t.absolute, t.inset_0, t.shadow_header]} style={{ opacity: dynamicOpacity, zIndex: 79 }} transition={{ ease: 'easeInOut' }} />
      <div css={[t.px_5, t.md([t.px('6vw')])]}>
        <div css={[t.max_w_page, t.mx_auto]}>
          <div css={[t.flex, t.flex_row, t.w_full, t.relative, t.h('56px')]} style={{ zIndex: 80 }}>
            {props.children}
          </div>
        </div>
      </div>
    </motion.header>
  );
};
