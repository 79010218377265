import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../theme/admin/adminStyles';
import { withAdminAccount } from '../../hocs/withAdminAccount';
import { AdminPage } from './components/AdminPage';
import {
  MoveFeaturedArtist,
  RemoveFeaturedArtist,
  useFeaturedArtistsContent,
  PublishFeaturedArtists,
  AddFeaturedArtist,
} from '../../contexts/FeaturedArtistContentContext';
import { AdminCard } from './components/AdminCard';
import { AdminButton } from './components/AdminButton';
import { AdminCardHeader } from './components/AdminCardHeader';
import { ShuffleDirection } from './components/AdminShuffleCard';
import { AdminArtistList } from './artists/AdminArtistList';

const AdminFeaturedArtistComponent = () => {
  const handleFeaturePublish = async () => {
    await PublishFeaturedArtists();
  };

  return (
    <AdminPage
      pageHeading="Featured"
      headChildren={
        <div css={[t.flex_auto, t.flex, t.flex_row, t.justify_end, t.items_center]}>
          <AdminButton type="button" label="Publish" onClick={handleFeaturePublish} />
        </div>
      }
    >
      <DiscoveryFeaturedArtists />
    </AdminPage>
  );
};

const DiscoveryFeaturedArtists = () => {
  const featuredArtists = useFeaturedArtistsContent();

  const handleShuffle = async (artistId: string, direction: ShuffleDirection) => {
    await MoveFeaturedArtist(artistId, direction);
  };

  const HandleAdd = async (artistId: string) => {
    await AddFeaturedArtist(artistId);
  };

  const HandleDelete = async (artistId: string) => {
    await RemoveFeaturedArtist(artistId);
  };

  return (
    <AdminCard>
      <AdminCardHeader>
        <div css={[t.flex_auto, t.flex, t.flex_row]}>
          <div css={[t.flex_auto, t.ml_4, t.text_dark_3, t.text_base, t.flex, t.flex_col, t.justify_center]}>Featured Artists</div>
        </div>
      </AdminCardHeader>
      <AdminArtistList
        label="Artists"
        dialogLabel="Add Featured Artists"
        artists={featuredArtists ? featuredArtists.featured : []}
        onAddArtist={HandleAdd}
        onRemoveArtist={HandleDelete}
        onArtistShuffle={handleShuffle}
      />
    </AdminCard>
  );
};

export const AdminFeaturedArtist = withAdminAccount(AdminFeaturedArtistComponent);
