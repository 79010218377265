import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t, { CSSStyle } from '../theme/newstyles';
import { CaretIcon } from '../icons/CaretIcon';
import { InputErrorInline } from './InputErrorInline';
import { InputLabel } from './InputLabel';

export function SimpleSelect(props: {
  defaultValue?: string | number | string[];
  name: string;
  fieldErrors?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  children?: React.ReactNode;
  label?: string;
  description?: string;
  containerStyle?: CSSStyle;
  selectStyle?: CSSStyle;
}) {
  const labelId = `${props.name}_label`;

  return (
    <div css={[t.w_full, props.containerStyle]}>
      {props.label && (
        <div css={[t.mb_2]}>
          <InputLabel label={props.label} id={labelId} for={props.name} />
        </div>
      )}
      {props.description && <div css={[t.gridTypeStyle_7, t.pb_3]}>{props.description}</div>}
      <div css={[t.relative, t.h('56px')]}>
        <select
          css={[
            t.appearance_none,
            t.w_full,
            t.outline_none,
            t.pl('18px'),
            t.pr('10px'),
            t.py('13px'),
            t.rounded_none,
            t.bg_transparent,
            t.border_1,
            props.fieldErrors ? t.border_error_1 : t.border_tint_2,
            t.border_solid,
            t.typeStyle_lg6,
            t.text_dark_1,
            t.hover(t.border_tint_1),
            t.focus(t.border_primary_4),
            props.selectStyle,
          ]}
          onChange={props.onChange}
          onBlur={props.onBlur}
          defaultValue={props.defaultValue}
          name={props.name}
        >
          {props.children}
        </select>
        <div css={[t.pointer_events_none, t.absolute, t.right_0, t.top_0, t.mt('18px'), t.mr('12px')]}>
          <CaretIcon direction={'down'} />
        </div>
      </div>
      <InputErrorInline id={`${props.name}_error`}>{props.fieldErrors}</InputErrorInline>
    </div>
  );
}
