import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import { AdminPlayIcon } from '../../icons/AdminPlayIcon';
import { AdminPauseIcon } from '../../icons/AdminPauseIcon';
import { AdminButton } from '../AdminButton';

export function AdminPlayPauseButton(props: { isPlaying?: boolean; onClick?: (event: React.MouseEvent) => void; isLoading?: boolean }) {
  return (
    <AdminButton
      type="button"
      icon={props.isPlaying ? <AdminPauseIcon /> : <AdminPlayIcon />}
      styleType="secondary"
      onClick={props.onClick}
      isInProgress={props.isLoading}
    />
  );
}
