import { css } from '@emotion/core';

export const backgroundImage = {
  bg_url: (url: string) => {
    return css`
      background-image: url(${url});
    `;
  },
  bg_repeat: css`
    background-repeat: repeat;
  `,
  bg_no_repeat: css`
    background-repeat: no-repeat;
  `,
  bg_sz_cover: css`
    background-size: cover;
  `,
  bg_sz_contain: css`
    background-size: contain;
  `,
};
