import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { AdminCaretIcon } from '../icons/AdminCaretIcon';
import { connectPagination } from 'react-instantsearch-dom';

// This isn't currently provided by the react-instantsearch types...
interface PaginationProvided {
  currentRefinement: number;
  nbPages: number;
  refine: (value: number) => void;
  resultsPerPage: number;
}

const caretSize = css`
  width: 12px;
  height: 12px;
`;

const Pagination = (props: PaginationProvided) => {
  const handlePageForward = () => {
    if (props.currentRefinement < props.nbPages) props.refine(props.currentRefinement + 1);
  };

  const handlePageBackward = () => {
    if (props.currentRefinement > 1) props.refine(props.currentRefinement - 1);
  };

  return (
    <div css={[t.h_12, t.flex, t.flex_row, t.justify_end, t.items_center, t.text_xs, t.text_tint_1]}>
      Page {props.nbPages === 0 ? 0 : props.currentRefinement} of {props.nbPages}
      <div css={[t.w_6, t.mx_2, t.flex, t.flex_row, t.justify_center]} onClick={handlePageBackward}>
        <AdminCaretIcon direction="left" css={[caretSize, props.currentRefinement > 1 ? t.text_tint_1 : t.text_tint_2]} />
      </div>
      <div css={[t.w_6, t.mx_2, t.flex, t.flex_row, t.justify_center]} onClick={handlePageForward}>
        <AdminCaretIcon direction="right" css={[caretSize, props.currentRefinement < props.nbPages ? t.text_tint_1 : t.text_tint_2]} />
      </div>
    </div>
  );
};

export const AdminListFooter = connectPagination(Pagination);
