import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';

export const DownloadIcon = (props: unknown) => {
  return (
    <svg role="img" aria-labelledby="downloadIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <title id="downloadIconTitleID">Download Icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 16.2652L18.1337 9.65979L18.8665 10.3402L12.0001 17.7348L5.13366 10.3402L5.86645 9.65979L12.0001 16.2652Z"
        css={[t.fill_current]}
      />
      <rect x="12.5" y="2" width="15" height="1" transform="rotate(90 12.5 2)" css={[t.fill_current]} />
      <rect x="3" y="20" width="18" height="1" css={[t.fill_current]} />
    </svg>
  );
};
