import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import dayjs from 'dayjs';

export const AdminEventListItem = (props: { label: string; timeStamp?: string; children?: React.ReactNode }) => {
  return (
    <div css={[t.px_3, t.py_3, t.text_xs, t.border_b, t.border_tint_3, t.border_solid, t.flex, t.flex_row]}>
      {props.timeStamp && (
        <div css={[t.text_tint_1, t.border_r, t.pr_2, t.mr_2, t.border_solid, t.border_tint_2]}>{dayjs(props.timeStamp).format('MM/DD/YY hh:mm A')}</div>
      )}
      <div css={[t.text_dark_3]}>{props.label}</div>
      {props.children}
    </div>
  );
};
