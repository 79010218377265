import React from 'react'; // eslint-disable-line
import { useHistory } from 'react-router-dom';

export function ScrollToTop(props: unknown) {
  const history = useHistory();
  React.useEffect(() => {
    return history.listen(() => {
      window.scrollTo(0, 0);
    });
  }, [history]);

  return null;
}
