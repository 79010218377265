import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';

export const NotificationIcon = (props: { alert?: boolean }) => {
  const { alert, ...rest } = props;
  const hasAlert = alert ? alert : false;
  if (hasAlert) {
    return (
      <svg role="img" aria-labelledby="notificationIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
        <title id="notificationIconTitleID">Notification Icon with alert</title>
        <rect x="3" y="18" width="18" height="1" css={[t.fill_current]} />
        <rect x="8" y="21" width="8" height="1" css={[t.fill_current]} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 4C10 2.89543 10.8954 2 12 2C12.9121 2 13.6815 2.61053 13.922 3.44519C13.5509 3.89181 13.2752 4.42049 13.126 5H10.2676C10.0974 4.70583 10 4.36429 10 4Z"
          css={[t.fill_current]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.4402 4.17396C12.9788 4.06029 12.4965 4 12 4C8.68629 4 6 6.68629 6 10V18V19H7H17H18V18V10C18 9.958 17.9996 9.91611 17.9987 9.87431C17.6795 9.95636 17.3448 10 17 10V18H7V10C7 7.23858 9.23858 5 12 5C12.3768 5 12.7439 5.04169 13.097 5.12071C13.1717 4.7875 13.288 4.47001 13.4402 4.17396ZM17.8928 8.86492C17.5171 6.90268 16.1856 5.27995 14.4014 4.49987C14.2356 4.78641 14.1159 5.10291 14.0523 5.43927C15.4965 6.09018 16.576 7.40693 16.8996 8.99835C16.933 8.99945 16.9664 9 17 9C17.3109 9 17.6108 8.9527 17.8928 8.86492Z"
          css={[t.fill_current]}
        />
        <circle cx="18" cy="6" r="5.5" css={[t.fill_error_1, t.stroke_tint_5]} />
      </svg>
    );
  }

  return (
    <svg role="img" aria-labelledby="notificationIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <title id="notificationIconTitleID">Notification Icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 10C17 7.23858 14.7614 5 12 5C9.23858 5 7 7.23858 7 10V18H17V10ZM12 4C15.3137 4 18 6.68629 18 10V19H6V10C6 6.68629 8.68629 4 12 4Z"
        css={[t.fill_current]}
      />
      <rect x="3" y="18" width="18" height="1" css={[t.fill_current]} />
      <rect x="8" y="21" width="8" height="1" css={[t.fill_current]} />
      <path
        d="M12 2C10.8954 2 10 2.89543 10 4C10 4.36429 10.0974 4.70583 10.2676 5H13.7324C13.9026 4.70583 14 4.36429 14 4C14 2.89543 13.1046 2 12 2Z"
        css={[t.fill_current]}
      />
    </svg>
  );
};
