import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';

export const ErrorMessage = (props: { id?: string; children?: React.ReactNode }) => {
  return (
    <div aria-live="polite" role="status" css={[t.text_error_1, t.typeStyle_lg7, t.mt_2]}>
      {props.children && <span id={props.id}>{props.children}</span>}
    </div>
  );
};
