/** @jsx jsx */
import React from 'react'; // eslint-disable-line
import { jsx, ClassNames } from '@emotion/core';
import * as braintree from 'braintree-web';
import { FirebaseObjects } from './FirebaseContext';
import { CSSStyle } from '../theme/newstyles';
import t from '../theme/newstyles';

export interface PaymentDetails {
  token: braintree.HostedFieldsTokenizePayload;
  deviceData: string;
  city: string;
  postalCode: string;
  countryCodeAlpha2: string;
  firstName: string;
  lastName: string;
  state?: string;
}

export interface CreditCardField {
  isEmpty: boolean;
  isValid: boolean;
  isPotentiallyValid: boolean;
  isFocused: boolean;
}

export interface CreditCardFields {
  number: CreditCardField;
  cvv: CreditCardField;
  expirationDate: CreditCardField;
}

export interface CreditCardDetails {
  fields?: CreditCardFields;
  getDetails: () => Promise<Pick<PaymentDetails, 'token' | 'deviceData'> | null>;
  serviceErrorMessage?: string;
  canGetDetails: boolean;
}

const PaymentClientContext = React.createContext<braintree.Client | null>(null);
export function PaymentClientProvider(props: { children: React.ReactNode }) {
  const [client, setClient] = React.useState<braintree.Client | null>(null);
  React.useEffect(() => {
    let mounted = true;
    const asyncWrapper = async () => {
      const result = await FirebaseObjects.visitorRequest({ action: 'requestBraintreeToken', data: {} });
      const client = await braintree.client.create({ authorization: result.data.token });

      if (mounted) {
        setClient(client);
      }
    };
    asyncWrapper();

    return () => {
      mounted = false;
    };
  }, []);
  return <PaymentClientContext.Provider value={client}>{props.children}</PaymentClientContext.Provider>;
}

export function usePaymentClient() {
  return React.useContext(PaymentClientContext);
}

export function CreditCardDetailsProvider(props: { inputStyle: CSSStyle; children?: React.ReactNode }) {
  return (
    <ClassNames>
      {(css) => {
        const inputStyleClassName = css.css(props.inputStyle);
        return <CreditCardDetailsProviderInternal inputStyleClassName={inputStyleClassName}>{props.children}</CreditCardDetailsProviderInternal>;
      }}
    </ClassNames>
  );
}

interface BraintreeState {
  fields: braintree.HostedFields | null;
  fetching: boolean;
  serviceErrorMessage?: string;
}

type BraintreeAction =
  | { type: 'initialize'; fields: braintree.HostedFields }
  | { type: 'setFields'; fields: braintree.HostedFields }
  | { type: 'setFetching'; state: boolean }
  | { type: 'serviceError'; message: string };

function BraintreeReducer(state: BraintreeState, action: BraintreeAction): BraintreeState {
  switch (action.type) {
    case 'initialize':
      return { ...state, fields: action.fields };
    case 'setFields':
      return { ...state, fields: action.fields };
    case 'setFetching':
      return { ...state, fetching: action.state };
    case 'serviceError':
      return { ...state, fetching: false };
  }
}

const CreditCardDetailsContext = React.createContext({} as CreditCardDetails);
function CreditCardDetailsProviderInternal(props: { inputStyleClassName: string; children: React.ReactNode }) {
  const paymentClient = usePaymentClient();
  const [state, stateDispatch] = React.useReducer(BraintreeReducer, {
    fields: null,
    fetching: false,
  });

  const inputStyle = props.inputStyleClassName;
  React.useEffect(() => {
    if (!paymentClient) return;
    let mounted = true;
    const asyncWrapper = async () => {
      try {
        const fields = await braintree.hostedFields.create({
          client: paymentClient,
          styles: {
            input: inputStyle,
            '::placeholder': {
              color: t.tint_2,
            },
          },

          fields: {
            number: {
              selector: '#card-number',
              placeholder: 'Card number',
            },
            cvv: {
              selector: '#cvv',
              placeholder: 'CVV',
            },
            expirationDate: {
              selector: '#expiration-date',
              placeholder: 'MM/YY',
            },
          },
        });

        if (!mounted) return;
        const dispatchFields = () => stateDispatch({ type: 'setFields', fields });
        fields.on('blur', dispatchFields);
        fields.on('focus', dispatchFields);
        fields.on('empty', dispatchFields);
        fields.on('notEmpty', dispatchFields);
        fields.on('validityChange', dispatchFields);
        stateDispatch({ type: 'initialize', fields });
      } catch (err) {
        if (!mounted) return;
        stateDispatch({ type: 'serviceError', message: err.message });
      }
    };
    asyncWrapper();
    return () => {
      mounted = false;
    };
  }, [inputStyle, paymentClient]);

  const fields = state.fields;
  const getDetails = React.useCallback(async () => {
    if (!fields) return null;
    if (!paymentClient) return null;

    stateDispatch({ type: 'setFetching', state: true });

    try {
      const tokenizedFields = await fields.tokenize();
      const deviceData = await braintree.dataCollector.create({ client: paymentClient, paypal: false, kount: true });
      const details = { token: tokenizedFields, deviceData: deviceData.deviceData };
      stateDispatch({ type: 'setFetching', state: false });
      return details;
    } catch (err) {
      stateDispatch({ type: 'serviceError', message: err.message });
    }

    return null;
  }, [fields, paymentClient]);

  const noopGetDetails = React.useCallback(async () => {
    return null;
  }, []);

  const cardDetails: CreditCardDetails = {
    fields: state.fields ? state.fields.getState().fields : undefined,
    serviceErrorMessage: state.serviceErrorMessage,
    getDetails: state.fetching ? noopGetDetails : getDetails,
    canGetDetails: !state.fetching,
  };
  return <CreditCardDetailsContext.Provider value={cardDetails}>{props.children}</CreditCardDetailsContext.Provider>;
}

export function useCreditCardDetails() {
  return React.useContext(CreditCardDetailsContext);
}
