import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';

export const MoreDotsIcon = (props: {}) => {
  return (
    <svg width="4" height="20" viewBox="0 0 4 20" fill="none" {...props}>
      <circle cx="2" cy="2" r="1.5" css={[t.fill_current, t.stroke_current]} />
      <circle cx="2" cy="10" r="1.5" css={[t.fill_current, t.stroke_current]} />
      <circle cx="2" cy="18" r="1.5" css={[t.fill_current, t.stroke_current]} />
    </svg>
  );
};
