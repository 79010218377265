import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';
import { motion } from 'framer-motion';

export const CheckboxIcon = (props: { checked?: boolean }) => {
  return (
    <motion.svg width="12" height="9" viewBox="0 0 12 9" initial="unchecked" fill="none" {...props} animate={props.checked ? 'checked' : 'unchecked'}>
      <motion.path
        d="M1 4.64L3.38095 8L11 1"
        css={[t.stroke_current]}
        variants={{ checked: { opacity: 1 }, unchecked: { opacity: 0 } }}
        transition={{ duration: 0.2 }}
      />
    </motion.svg>
  );
};
