import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx, SerializedStyles } from '@emotion/core';
import t from '../theme/newstyles';
import { CheckboxIcon } from '../icons/CheckboxIcon';

export function Checkbox(props: {
  name: string;
  value?: string;
  checked?: boolean;
  focused?: boolean;
  label?: string;
  suppressError?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  children?: React.ReactNode;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  labelStyles?: SerializedStyles[];
}) {
  const [focus, SetFocus] = React.useState(props.focused);
  const inputElement = React.useRef<HTMLInputElement | null>(null);

  const name = props.name;
  const checked = !!props.checked;
  const focused = !!props.focused;
  React.useEffect(() => {
    if (inputElement && inputElement.current && focused) {
      SetFocus(focused);
      inputElement.current.focus();
    }
  }, [focused, inputElement]);

  function HandleFocus(event: React.FocusEvent<HTMLInputElement>) {
    SetFocus(true);
    if (props.onFocus) props.onFocus(event);
  }

  function HandleBlur(event: React.FocusEvent<HTMLInputElement>) {
    SetFocus(false);
    if (props.onBlur) props.onBlur(event);
  }

  return (
    <div css={[t.inline_block, t.relative, props.fullWidth ? t.w_full : null]}>
      <div css={[t.flex]}>
        <label css={[t.flex, t.outline_none, t.cursor_pointer, t.mr_3, props.fullWidth ? t.w_full : null]}>
          <input
            type="checkbox"
            ref={inputElement}
            name={name}
            value={props.value}
            onChange={props.onChange}
            onFocus={HandleFocus}
            onBlur={HandleBlur}
            checked={checked}
            disabled={props.disabled}
            css={[t.hidden_input, t.checkedSelector('+ #checkboxSquare', [t.border_primary_3, t.bg_primary_4])]}
          />
          <span
            id="checkboxSquare"
            css={[
              t.relative,
              t.text_tint_5,
              t.size('20px'),
              t.my('4px'),
              t.flex,
              t.flex_col,
              t.flex_none,
              t.justify_center,
              t.items_center,
              t.border,
              t.border_solid,
              t.border_tint_2,
              t.before([t.focusIndicator, t.pos('-3px'), t.border_primary_4, t.content_none, focus ? t.content_some : null]),
            ]}
          >
            <CheckboxIcon checked={checked} css={[t.size({ width: '12px', height: '9px' })]} />
          </span>
          {props.label ? <span css={[t.ml_3, t.typeStyle_lg6, t.text_dark_1, ...(props.labelStyles || [])]}>{props.label}</span> : null}
        </label>
        {props.children}
      </div>
    </div>
  );
}

export default Checkbox;
