import React, { ReactNode } from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../theme/newstyles';
import { HamburgerIcon } from '../../icons/HamburgerIcon';
import { FullScreenMenu } from '../dialogs/FullScreenMenu';
import { useThemeController } from '../../contexts/ThemeContext';

export function HamburgerMenu(props: { showLabel?: boolean; children?: (onClose: (event: React.MouseEvent | React.KeyboardEvent) => void) => ReactNode }) {
  const { dark } = useThemeController();
  const [isMenuOpen, SetMenuOpen] = React.useState(false);

  function HandleClick() {
    SetMenuOpen(true);
  }

  function HandleClose(event: React.MouseEvent | React.KeyboardEvent) {
    event.stopPropagation();
    SetMenuOpen(false);
  }

  return (
    <div css={[t.flex_auto, t.flex, t.flex_row, t.justify_start, t.items_center, t.md(t.hidden)]}>
      <div css={[t.flex, t.items_center, t.cursor_pointer, dark ? t.text_tint_5 : t.text_dark_1]} onClick={HandleClick}>
        {props.showLabel ? <div css={[t.mr_1, t.typeStyle_6, t.pt('2px'), t.pb('6px')]}>Menu</div> : null}
        <HamburgerIcon />
      </div>
      <FullScreenMenu isOpen={isMenuOpen} onRequestClose={HandleClose} onClose={HandleClose}>
        {props.children ? props.children(HandleClose) : null}
      </FullScreenMenu>
    </div>
  );
}
