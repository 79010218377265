import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { useArtistProfile } from '../../../contexts/ArtistProfileContext';
import { useUserAccount } from '../../../contexts/UserAccountContext';
import { useDiscoveryContent } from '../../../contexts/DiscoveryContentContext';
import { useFeaturedArtistsContent } from '../../../contexts/FeaturedArtistContentContext';

export const AdminArtistDetailsHeader = () => {
  const artistProfile = useArtistProfile();
  const artistAccount = useUserAccount();
  const discoveryContent = useDiscoveryContent();
  const featuredArtistsContent = useFeaturedArtistsContent();

  const profileData = artistProfile.Profile;
  if (!profileData) return null;
  if (!artistAccount) return null;

  let mainFeatureArtist = false;
  let featuredArtist = false;
  let occasionArtist = false;

  if (discoveryContent) {
    mainFeatureArtist = discoveryContent.mainFeature.artist ? discoveryContent.mainFeature.artist.id === artistAccount.id : false;
    discoveryContent.occasions.forEach((occasion) => {
      occasionArtist = occasionArtist || occasion.artists.findIndex((x) => x.id === artistAccount.id) !== -1;
    });
  }
  if (featuredArtistsContent) featuredArtist = featuredArtistsContent.featured.findIndex((x) => x.id === artistAccount.id) !== -1;

  const anyFeaturing = mainFeatureArtist || featuredArtist || occasionArtist;

  let statusElement: React.ReactNode | null = null;
  const sharedStatusStyle = [t.flex, t.flex_row, t.items_center, t.text_tint_5, t.uppercase, t.h_full, t.px_6];
  if (artistAccount.banned) {
    statusElement = <div css={[sharedStatusStyle, t.bg_error_2]}>Banned</div>;
  } else if (profileData.pageDisabled) {
    statusElement = <div css={[sharedStatusStyle, t.bg_tint_2]}>Disabled</div>;
  } else if (profileData.deactivated) {
    statusElement = <div css={[sharedStatusStyle, t.bg_tint_2]}>Inactive</div>;
  }

  let featuredElement: React.ReactNode | null = null;
  if (anyFeaturing) {
    featuredElement = <div css={[sharedStatusStyle, t.bg_primary_4]}>Featured</div>;
  }

  return (
    <div
      css={[
        t.absolute,
        t.inset_x_0,
        t.z_40,
        t.flex,
        t.flex_row,
        t.items_center,
        t.text_sm,
        t.h('55px'),
        t.bg_tint_5,
        t.border_b,
        t.border_solid,
        t.border_tint_3,
      ]}
    >
      {featuredElement}
      {statusElement}
    </div>
  );
};
