import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';

export const PauseIcon = (props: {}) => {
  return (
    <svg role="img" aria-labelledby="pauseIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <title id="pauseIconTitleID">Pause Icon</title>
      <rect x="9" y="4" width="1" height="16" css={[t.fill_current]} />
      <rect x="14" y="4" width="1" height="16" css={[t.fill_current]} />
    </svg>
  );
};

export default PauseIcon;
