import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';

export const VerticalKebab = (props: {}) => {
  return (
    <svg aria-labelledby="kebabMenuIconTitleID" width="4" height="20" viewBox="0 0 4 20" fill="none" {...props}>
      <title id="kebabMenuIconTitleID">Kebab Menu Icon</title>
      <circle cx="2" cy="2" r="2" css={[t.fill_current]} />
      <circle cx="2" cy="10" r="2" css={[t.fill_current]} />
      <circle cx="2" cy="18" r="2" css={[t.fill_current]} />
    </svg>
  );
};
