import React from 'react'; // eslint-disable-line
import { motion } from 'framer-motion';

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../theme/newstyles';
import { MainPage } from '../../components/MainPage';
import { MainContainer } from '../../components/MainContainer';
import { useArtistSearch } from '../../contexts/ArtistSearchContext';
import { ArtistSearchControllerState } from '../../contexts/ArtistSearchContext/types';
import { ArtistPageSearchRecord } from '../../types/shared-types';
import { Button } from '../../components/Button';
import { SelectDropdown, SelectDropdownOptionType } from '../../components/SelectDropdown';
import { ArtistCard } from './ArtistCard';
import { TipProvider } from '../../contexts/TipContext';
import { useMD, useSM, useXL } from '../../contexts/BreakpointContext';
import { useMediaQuery } from 'react-responsive';

import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useSwitches } from '../../contexts/SwitchesContext';
import ArtistSearchControlsV2 from './SearchControlsV2';
import { useBehaviorData } from '../../contexts/BehaviorContext';
import { useSiteWidePromo } from '../../contexts/SiteWidePromoContext';
import Checkbox from '../../components/Checkbox';
import ArtistSearchControls from './ArtistSearchControls';
import ArtistActiveFiltersList from './ArtistActiveFiltersList';

const FEATURED = 'featured';
const POPULARITY = 'popularity';
const ALPHA = 'alphabetical';
const AGE = 'age';
const RATINGS = 'ratings';

const sortValueDisplayStrings: { [key: string]: string } = {
  [FEATURED]: 'Featured',
  [POPULARITY]: 'Popular',
  [ALPHA]: 'A - Z',
  [AGE]: 'Newest',
  [RATINGS]: 'Rating',
};

const sortValueDropdownOptions: SelectDropdownOptionType[] = [
  { value: ALPHA, label: sortValueDisplayStrings[ALPHA] },
  { value: AGE, label: sortValueDisplayStrings[AGE] },
  { value: POPULARITY, label: sortValueDisplayStrings[POPULARITY] },
  { value: FEATURED, label: sortValueDisplayStrings[FEATURED] },
  { value: RATINGS, label: sortValueDisplayStrings[RATINGS] },
];

export function GetTotalArtistsShow(searchController: ArtistSearchControllerState) {
  if (!searchController.state) return 0;
  const discoveryInfo = searchController.state;
  const hits = discoveryInfo.results.hits;

  return hits.length;
}

export function GetTotalArtistFilters(searchController: ArtistSearchControllerState) {
  if (!searchController.state) return 0;
  return searchController.state.genreFilters.length + searchController.state.instrumentFilters.length + searchController.state.packageTagFilters.length;
}

const NoMatches = (props: {
  sortValue: string;
  filteredDropdownOptions: SelectDropdownOptionType[];
  handleSortAction: (sortIndex: string) => void;
  onChange: () => void;
  checkValue: boolean;
}) => {
  const { sortValue, filteredDropdownOptions, handleSortAction, onChange: handleNotedToggling, checkValue } = props;
  const isMD = useMD();

  return (
    <React.Fragment>
      {isMD ? (
        <div css={[t.flex, t.justify_between, t.w_full, t.mt_6, t.mb('300px'), t.md([t.mt_7])]}>
          <div css={[t.gridTypeStyle_5, t.pr_5]}>
            <div css={[t.text_dark_1, t.pb_3]}>{`Sorry, we couldn't find any results for your search.`}</div>
            <div css={[t.text_primary_4]}>{`Confirm the spelling, uncheck the Noted artist box if it's checked, or try a different name.`}</div>
          </div>
          <div css={[t.flex]}>
            <Checkbox name="notedCheckbox" label="Noted artists" labelStyles={[t.pr_4]} onChange={handleNotedToggling} checked={checkValue} />
            <SelectDropdown // will always be disabled
              label={`Sort by ${sortValueDisplayStrings[sortValue]}`}
              currentValue={sortValue}
              options={filteredDropdownOptions}
              onSelect={handleSortAction}
              buttonStyle={[t.h_1, t.pt('3px')]}
              disabled={true}
            />
          </div>
        </div>
      ) : (
        <div css={[t.w_full, t.my_6, t.md([t.my_7])]}>
          <div css={[t.flex, t.justify_between]}>
            <Checkbox name="notedCheckbox" label="Noted artists" labelStyles={[t.pr_4]} onChange={handleNotedToggling} checked={checkValue} />
            <SelectDropdown // will always be disabled
              label={`Sort by ${sortValueDisplayStrings[sortValue]}`}
              currentValue={sortValue}
              options={filteredDropdownOptions}
              onSelect={handleSortAction}
              buttonStyle={[t.h_1, t.pt('3px')]}
              disabled={true}
            />
          </div>
          <div css={[t.gridTypeStyle_5, t.pt_5, t.sm([t.pt_6])]}>
            <div css={[t.text_dark_1, t.pb_3]}>{`Sorry, we couldn't find any results for your search.`}</div>
            <div css={[t.text_primary_4]}>{`Confirm the spelling, uncheck the Noted artist box if it's checked, or try a different name.`}</div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

function ArtistPageHits() {
  const [sortValue, setSortValue] = React.useState<string>(FEATURED);
  const [filteredDropdownOptions, setFilteredDropdownOptions] = React.useState<SelectDropdownOptionType[]>(sortValueDropdownOptions);
  const discoverySearch = useArtistSearch();
  const switches = useSwitches();
  const isSM = useSM();
  const isMD = useMD();
  const location = useLocation();

  React.useEffect(() => {
    if (switches) {
      const switchFilters: string[] = [];
      // in the future if more options get added that would also need to be hidden, we may want to either
      // reconfigure the current switch interface or create a new specific UI switches interface
      // but right now we just need to check for ratings
      if (!switches.ratings) switchFilters.push('ratings');
      setFilteredDropdownOptions(sortValueDropdownOptions.filter((sortOption) => !switchFilters.includes(sortOption.value)));
    }
  }, [switches]);

  if (!discoverySearch.results) return null;

  const sortIndex = discoverySearch.state.sortIndex;

  const totalArtists = GetTotalArtistsShow(discoverySearch);
  const hasActiveFilters = GetTotalArtistFilters(discoverySearch) > 0;
  const discoveryInfo = discoverySearch.state;
  const query = discoveryInfo.query;

  const matchingEnabled = switches && switches.perfectMatch;
  const hasMultipleFilters = GetTotalArtistFilters(discoverySearch) > 1;
  const hasMultiplePackageTags = discoverySearch.state.packageTagFilters.length > 1;
  let filterName = '';
  if (hasActiveFilters) {
    const filter = location.search.split('=')[1].split('&')[0];
    filterName = filter[0].toUpperCase() + filter.substring(1);
  }
  const filterDescription = hasMultipleFilters ? ` in "${filterName}" ${hasMultiplePackageTags ? 'or' : 'and'} "Noted"` : ` in "${filterName}"`;

  const getTotalString = () => {
    if (!discoveryInfo) return '0';

    if (query || hasActiveFilters) {
      if (discoveryInfo.results.hasMore) return '50+';
      else return totalArtists;
    }

    return 'all';
  };

  const handleSortAction = (sortIndex: string) => {
    discoverySearch.setSortBy(sortIndex);
    setSortValue(sortIndex);
  };

  const handleNotedToggling = () => {
    discoverySearch.toggleNotedFilter();
  };

  return (
    <MainContainer>
      {discoverySearch.results.hits.length ? (
        <div css={[t.pt_6, t.sm([t.pt_5]), t.lg([t.pt_7])]}>
          {matchingEnabled ? (
            <React.Fragment>
              {isMD ? (
                <div css={[t.flex, t.justify_between, t.items_center, t.w_full, t.mb_6, t.md([t.mb_7]), t.lg([t.mb_7])]}>
                  <div css={[t.gridTypeStyle_5, t.text_dark_1]}>
                    Showing {getTotalString()} artist{totalArtists > 1 ? 's' : ''}
                    {query !== '' && ` for "${query}"`}
                    {hasActiveFilters && filterDescription}
                  </div>
                  <div css={[t.flex, t.items_center]}>
                    <Checkbox
                      name="notedCheckbox"
                      label="Noted artists"
                      labelStyles={[t.pr_4]}
                      onChange={handleNotedToggling}
                      checked={discoverySearch.state.notedFilter}
                    />
                    <SelectDropdown
                      label={`Sort by ${sortValueDisplayStrings[sortValue]}`}
                      currentValue={sortValue}
                      options={filteredDropdownOptions}
                      onSelect={handleSortAction}
                    />
                  </div>
                </div>
              ) : (
                <div css={[t.w_full, t.mb_4]}>
                  <div css={[t.flex, t.justify_between, t.items_center, t.pb_5, t.sm([t.pb_6])]}>
                    <Checkbox
                      name="notedCheckbox"
                      label="Noted artists"
                      labelStyles={[t.pr_4]}
                      onChange={handleNotedToggling}
                      checked={discoverySearch.state.notedFilter}
                    />
                    <SelectDropdown
                      label={`Sort by ${sortValueDisplayStrings[sortValue]}`}
                      currentValue={sortValue}
                      options={filteredDropdownOptions}
                      onSelect={handleSortAction}
                    />
                  </div>
                  <div css={[t.gridTypeStyle_5, t.text_tint_1]}>
                    Showing {getTotalString()} artist{totalArtists > 1 ? 's' : ''}
                    {query !== '' && ` for "${query}"`}
                  </div>
                </div>
              )}
            </React.Fragment>
          ) : (
            <div css={[t.flex, t.justify_between, t.items_center, t.w_full, t.mb_7]}>
              <div css={[t.gridTypeStyle_5, t.text_dark_1]}>
                Showing {getTotalString()} artist{totalArtists > 1 ? 's' : ''}
                {query !== '' && ` for "${query}"`}
              </div>
              <SelectDropdown
                label={`Sort by ${sortValueDisplayStrings[sortValue]}`}
                currentValue={sortValue}
                options={filteredDropdownOptions}
                onSelect={handleSortAction}
              />
            </div>
          )}
          <React.Suspense fallback={<div>&nbsp;</div>}>
            <ArtistCardGrid searchHits={discoverySearch.results.hits} preferredGenres={discoverySearch.state.genreFilters} sortIndex={sortIndex} />
          </React.Suspense>
          <div css={[t.w_full, t.mt_5, t.mb_7, t.flex, t.justify_center]}>
            <Button
              onClick={discoverySearch.loadMoreResults}
              disabled={!discoverySearch.results.hasMore}
              type="button"
              styleType="secondary"
              label="Load more"
            />
          </div>
          {matchingEnabled && (
            <div
              id="pageCTA"
              css={[
                t.bg_tint_3,
                t.py_6,
                t.px_5,
                t.mb_6,
                t.sm([t.py_7, t.px_6, t.mb_7]),
                t.md([t.px_7, t.mb_6]),
                t.lg([t.px_8, t.mb_8]),
                t.xl([t.breakpointGrid_12]),
              ]}
            >
              <div css={[t.xl([t.col_span_8])]}>
                <h2 css={[t.typeStyle_sm2, t.mb_5, t.md([t.typeStyle_md2]), t.lg([t.typeStyle_lg2, t.mb_6])]}>Find your perfect artist</h2>
                <div css={[t.typeStyle_sm6, t.mb_6, t.sm([t.typeStyle_lg5, t.mb_5]), t.lg([t.mb_6]), t.xl([t.mb_0])]}>
                  Tell us what you want original music for, what style, and how much you want to spend and we&apos;ll match you with a top songwriter.
                </div>
              </div>
              <div css={[t.w_full, t.xl([t.col_span_3, t.col_start_10, t.flex, t.items_center, t.justify_end])]}>
                <Button type="link" to="/match" styleType="secondary" label="Find your match" fullWidth={!isSM ? true : false} secondaryStyle={[t.px_7]} />
              </div>
            </div>
          )}
        </div>
      ) : (
        <React.Fragment>
          {matchingEnabled ? (
            <NoMatches
              sortValue={sortValue}
              filteredDropdownOptions={filteredDropdownOptions}
              handleSortAction={handleSortAction}
              onChange={handleNotedToggling}
              checkValue={discoverySearch.state.notedFilter}
            />
          ) : (
            <div css={[t.pt_7, t.pb_9, t.md(t.pt_8), t.lg(t.pt_9)]}>
              <div css={[t.gridTypeStyle_3, t.mb_3, t.lg(t.mb_4)]}>{`We couldn't find any results for your search`}</div>
              <div css={[t.gridTypeStyle_5]}>Try removing some filters or search for another artist.</div>
            </div>
          )}
        </React.Fragment>
      )}
    </MainContainer>
  );
}

function ArtistCardGrid(props: { searchHits: ArtistPageSearchRecord[]; preferredGenres: string[]; sortIndex: string }) {
  // The real isMD() is set to 704 which is too small for us to use for this purpose. isXL() is right on for the other.
  const isMD = useMediaQuery({ query: 'screen and (min-width: 958px)' });
  const isSM = useSM();
  const isXL = useXL();
  let rowSize = 1;
  if (isXL) {
    rowSize = 4;
  } else if (isMD) {
    rowSize = 3;
  } else if (isSM) {
    rowSize = 2;
  }
  return (
    <motion.div css={[t.w_full, t.flex, t.flex_col, t.sm([t.breakpointGrid_12]), t.overflow_visible]} {...t.motion_rise}>
      <TipProvider>
        {props.searchHits.map((hit, i) => {
          return (
            <div
              key={hit.objectID}
              css={[t.w_full, t.col_span_12, t.mb_6, t.p('2px'), t.sm([t.col_span_6]), t.md([t.mb_7]), t.lg([t.col_span_4]), t.xl([t.col_span_3])]}
            >
              <React.Suspense fallback={<div>&nbsp;</div>}>
                <ArtistCard artistPage={hit} preferredSampleGenres={props.preferredGenres} isEndOfRow={(i + 1) % rowSize === 0} sortIndex={props.sortIndex} />
              </React.Suspense>
            </div>
          );
        })}
      </TipProvider>
    </motion.div>
  );
}

export const ArtistSearch = () => {
  const promo = useSiteWidePromo();
  const { isScrollAtTop } = useBehaviorData();
  const switches = useSwitches();
  const [showFilterMenu, setShowFilterMenu] = React.useState(false);

  const toggleFilterMenu = (forceState?: boolean) => {
    setShowFilterMenu(typeof forceState === 'boolean' ? forceState : !showFilterMenu);
  };

  const matchingEnabled = switches && switches.perfectMatch;

  const isPromoVisible = promo.active && !promo.hasDismissedPromo && !isScrollAtTop;
  const topOfPageMargin = isPromoVisible ? '46px' : '0px';

  return (
    <MainPage mobileScrollLock={showFilterMenu} overrideStyle={[t.bg_tint_4]}>
      <Helmet>
        <title>Find a songwriter | Search for music artists on Downwrite</title>
        <meta
          name="description"
          content="Browse our growing community of talented music artists & search for those whose songwriting style fits yours. Find an artist & follow your creative vision."
        />
      </Helmet>
      <React.Suspense fallback={<div>&nbsp;</div>}>
        {switches && (
          <motion.div initial={{ marginTop: topOfPageMargin }} animate={{ marginTop: topOfPageMargin }} transition={{ ease: 'easeOut', duration: 0.3 }}>
            {matchingEnabled ? (
              <React.Fragment>
                <ArtistSearchControlsV2 filterMenuActive={showFilterMenu} onMenuToggle={toggleFilterMenu} />
                <div css={[t.flex_initial, t.flex, t.flex_row, t.bg_tint_5]}>
                  <MainContainer containerCSS={[t.py_5, t.md([t.py_7]), t.lg([t.pt_9, t.pb_6])]}>
                    <motion.h1 {...t.motion_riseFadeIn} css={[t.flex_auto, t.gridTypeStyle_1, t.text_dark_1]}>
                      Our artists
                    </motion.h1>
                  </MainContainer>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div css={[t.flex_initial, t.flex, t.flex_row, t.bg_tint_5]}>
                  <MainContainer containerCSS={[t.pt_6, t.lg(t.pt_7), t.pb_0]}>
                    <motion.h1 {...t.motion_riseFadeIn} css={[t.flex_auto, t.gridTypeStyle_1, t.text_dark_1]}>
                      Our artists
                    </motion.h1>
                  </MainContainer>
                </div>
                <ArtistSearchControls filterMenuActive={showFilterMenu} onFilterToggle={toggleFilterMenu} />
                <ArtistActiveFiltersList />
              </React.Fragment>
            )}
            <ArtistPageHits />
          </motion.div>
        )}
      </React.Suspense>
    </MainPage>
  );
};

export default ArtistSearch;
