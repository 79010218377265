import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';

export const ProfileIcon = (props: {}) => {
  return (
    <svg role="img" aria-labelledby="profileIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <title id="profileIconTitleID">Profile Icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12ZM12 13C15.3137 13 18 10.3137 18 7C18 3.68629 15.3137 1 12 1C8.68629 1 6 3.68629 6 7C6 10.3137 8.68629 13 12 13Z"
        css={[t.fill_current]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 17.6687V21H21V17.6687C18.2086 16.5912 15.1745 16 12 16C8.82555 16 5.79137 16.5912 3 17.6687ZM22 16.9927V22H2V16.9927C5.07837 15.7089 8.4564 15 12 15C15.5436 15 18.9216 15.7089 22 16.9927Z"
        css={[t.fill_current]}
      />
    </svg>
  );
};
