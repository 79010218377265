import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';
import { motion } from 'framer-motion';

export const SendIcon = (props: unknown) => {
  return (
    <motion.svg role="img" aria-labelledby="sendIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <title id="sendIconTitleID">Send Icon</title>
      <path d="M7.09007 12.6964L7.17394 12.5L7.09007 12.3036L3.97686 5.01424L19.8291 12.5L3.97686 19.9858L7.09007 12.6964Z" css={[t.stroke_current]} />
      <rect x="7" y="12" width="4" height="1" css={[t.fill_current]} />
    </motion.svg>
  );
};
