import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';

export const AdminDatePicker = (props: {
  defaultValue?: string;
  min?: string;
  max?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <input
      type="date"
      defaultValue={props.defaultValue}
      min={props.min}
      max={props.max}
      onChange={props.onChange}
      css={[t.px_3, t.py_2, t.border_solid, t.border, t.border_dark_1, t.text_sm]}
    />
  );
};
