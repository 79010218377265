import React from 'react'; // eslint-disable-line
import ReactDOM from 'react-dom';

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';

const popoverStyle = css`
  min-width: 148px;
`;

export const AdminActionsPopover = (props: { anchor: 'left' | 'right'; positionRect?: DOMRect; children?: React.ReactNode; onClose?: () => void }) => {
  const popoverRoot = document.getElementById('popoverRoot');
  if (!popoverRoot) return null;

  const handleBackgroundClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (props.onClose) props.onClose();
  };

  const top = props.positionRect ? props.positionRect.top : 0;
  const left = props.positionRect ? (props.anchor === 'left' ? props.positionRect.left : props.positionRect.left + props.positionRect.width) : 0;

  const popoverPosition = css`
    top: ${top}px;
    left: ${left}px;
  `;

  const anchorStyle = props.anchor === 'left' ? t.left_0 : t.right_0;

  return ReactDOM.createPortal(
    <React.Fragment>
      <div css={[t.fixed, t.inset_0, t.bg_transparent, t.z_40]} onClick={handleBackgroundClick}></div>
      <div css={[t.fixed, t.top_0, t.left_0, t.z_50, popoverPosition]}>
        <div css={[t.absolute, anchorStyle]}>
          <div css={[popoverStyle, t.flex, t.flex_col, t.bg_tint_5, t.relative, t.shadow_dark, t.py_1]}>{props.children}</div>
        </div>
      </div>
    </React.Fragment>,
    popoverRoot
  );
};
