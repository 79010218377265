import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import dayjs from 'dayjs';
import { MoreDotsIcon } from '../icons/MoreDotsIcons';
import { AdminActionsPopover } from '../components/AdminActionsPopover';
import { AdminActionsButton } from '../components/AdminActionButton';
import { OrderComment } from '../../../contexts/OrderContext';
import { ClientSideOrder as Order } from '../../../types/shared-types';
import { FirebaseObjects } from '../../../contexts/FirebaseContext';
import { useAdminPendingOperation } from './AdminPendingOperation';

export const AdminOrderDetailsComments = (props: { order: Order; comments: OrderComment[] }) => {
  const [actionsPopover, SetActionsPopover] = React.useState(false);
  const [popoverRect, SetPopoverRect] = React.useState<DOMRect | undefined>(undefined);
  const [selectedCommentId, SetSelectedCommentId] = React.useState<string | undefined>(undefined);
  const pendingOperation = useAdminPendingOperation();

  const handleActionsClick = (rect: DOMRect | undefined, event: React.MouseEvent) => {
    SetActionsPopover(true);
    SetPopoverRect(rect);
  };

  const handleActionsClose = () => {
    SetActionsPopover(false);
  };

  const handleRemove = async () => {
    if (!selectedCommentId) return;
    const comment = props.comments.find((x) => x.id === selectedCommentId);
    if (!comment || comment.deleted) return;
    SetActionsPopover(false);
    pendingOperation.show(true);
    await FirebaseObjects.adminRequest({ action: 'deleteComment', data: { orderId: props.order.id, commentId: selectedCommentId } });
    pendingOperation.show(false);
    SetSelectedCommentId(undefined);
  };

  const handleResolve = async () => {
    if (!selectedCommentId) return;
    const issue = props.order.reviewReasons.find((x) => x.type === 'comment' && x.id === selectedCommentId);
    if (!issue) return;
    SetActionsPopover(false);
    pendingOperation.show(true);
    await FirebaseObjects.adminRequest({ action: 'resolveIssue', data: { orderId: props.order.id, issue: issue } });
    pendingOperation.show(false);
    SetSelectedCommentId(undefined);
  };

  let deliveryComment: OrderComment | undefined = undefined;
  if (props.order.deliveryMessage && props.order.deliveryMessage !== '') {
    deliveryComment = {
      id: props.order.id,
      message: props.order.deliveryMessage,
      authorId: props.order.artistId,
      time: props.order.submittedOn,
    };
  }

  return (
    <React.Fragment>
      <div css={[t.flex, t.flex_col, t.mt_4, t.px_6, t.pb_6]}>
        <div css={[t.bg_white, t.text_tint_1, t.shadow]}>
          {deliveryComment && (
            <AdminDialogCommentListItem comment={deliveryComment} isCustomMessage={true} commenter={props.order.artistName} needsReview={false} />
          )}
          {props.comments.map((comment, i) => {
            const needsReview = props.order.reviewReasons.find((x) => x.type === 'comment' && x.id === comment.id) !== undefined;
            return (
              <AdminDialogCommentListItem
                key={i}
                comment={comment}
                commenter={comment.authorId === props.order.customerId ? props.order.customerName : props.order.artistName}
                needsReview={needsReview}
                onClick={(r, e) => {
                  SetSelectedCommentId(comment.id);
                  handleActionsClick(r, e);
                }}
              />
            );
          })}
        </div>
      </div>
      {/* Rendered in the Modal Root */}
      {actionsPopover ? (
        <AdminActionsPopover anchor="right" positionRect={popoverRect} onClose={handleActionsClose}>
          <ul css={[t.py_2, t.flex, t.flex_col]}>
            <li css={[t.inline, t.px_4, t.py_2, t.cursor_pointer, t.text_sm, t.hover([t.bg_tint_3])]} onClick={handleRemove}>
              Remove
            </li>
            <li css={[t.inline, t.px_4, t.py_2, t.cursor_pointer, t.text_sm, t.hover([t.bg_tint_3])]} onClick={handleResolve}>
              Resolve
            </li>
          </ul>
        </AdminActionsPopover>
      ) : null}
    </React.Fragment>
  );
};

const AdminDialogCommentListItem = (props: {
  comment: OrderComment;
  commenter: string;
  needsReview: boolean;
  onClick?: (rect: DOMRect | undefined, event: React.MouseEvent) => void;
  isCustomMessage?: boolean;
}) => {
  const [isExpanded, SetExpanded] = React.useState(false);

  const toggleExpand = () => {
    SetExpanded(!isExpanded);
  };

  const commentString = `${props.commenter}:${props.isCustomMessage ? ' Project Complete: ' : ''} ${props.comment.message}`;

  return (
    <div
      css={[
        t.relative,
        t.px_3,
        t.py_3,
        t.text_xs,
        t.border_b,
        t.border_tint_3,
        t.border_solid,
        t.flex,
        t.flex_row,
        t.hover([t.bg_tint_4]),
        t.hoverSelector(' #actions', [t.visible]),
      ]}
    >
      {props.needsReview ? <div css={[t.absolute, t.left_0, t.inset_y_0, t.w_1, t.bg_error_1]} /> : null}
      <div css={[t.flex_none, t.text_tint_1, t.border_r, t.pr_2, t.mr_2, t.border_solid, t.border_tint_2]}>
        {dayjs(props.comment.time).format('MM/DD/YY hh:mm A')}
      </div>
      {props.comment.deleted ? (
        <div css={[t.flex_auto, t.flex, t.flex_col]}>
          <div css={[t.flex]}>
            <div css={[t.text_error_2]}>{`Comment deleted`}</div>
            <div css={[t.pl_2, t.cursor_pointer, t.underline]} onClick={toggleExpand}>
              {isExpanded ? `Hide` : `View`}
            </div>
          </div>
          {isExpanded && <div css={[t.mt_2, t.text_dark_3]}>{commentString}</div>}
        </div>
      ) : (
        <div css={[t.flex_auto, t.text_dark_3]}>{commentString}</div>
      )}
      {!props.isCustomMessage && (
        <div id="actions" css={[t.invisible, t.flex_none]}>
          <AdminActionsButton buttonCSS={[t.ml_3]} onClick={props.onClick}>
            <MoreDotsIcon css={[t.size('16px')]} />
          </AdminActionsButton>
        </div>
      )}
    </div>
  );
};
