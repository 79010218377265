import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { CSSStyle } from '../../../theme/newstyles';

export const AdminActionsButton = (props: {
  buttonCSS?: CSSStyle;
  children?: React.ReactNode;
  onClick?: (rect: DOMRect | undefined, event: React.MouseEvent) => void;
}) => {
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  function handleClick(event: React.MouseEvent) {
    if (props.onClick) props.onClick(buttonRef.current?.getBoundingClientRect(), event);
  }

  return (
    <button ref={buttonRef} css={[t.p_0, t.text_tint_1, t.cursor_pointer, t.bg_transparent, t.hover(t.text_dark_1), props.buttonCSS]} onClick={handleClick}>
      {props.children}
    </button>
  );
};
