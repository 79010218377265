import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';
import { InputErrorInline } from './InputErrorInline';
import { useFormikContext, getIn } from 'formik';
import { Checkbox } from './Checkbox';

export function FormCheckbox<T>(props: {
  name: keyof T;
  value?: string;
  checked?: boolean;
  focused?: boolean;
  label?: string;
  suppressError?: boolean;
  fullWidth?: boolean;
  children?: React.ReactNode;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}) {
  const { name, checked, suppressError, onBlur, onFocus, ...rest } = props;
  const formikProps = useFormikContext<T>();

  if (!formikProps) {
    console.warn('Our checkboxes only work within Formik for now (and maybe forever)');
    return null;
  }

  const nameString = name as string;
  const invalid = getIn(formikProps.touched, nameString) && Boolean(getIn(formikProps.errors, nameString));
  const errorMessage = getIn(formikProps.errors, nameString);
  const formChecked: boolean = checked !== undefined ? checked : getIn(formikProps.values, nameString);

  function HandleFocus(event: React.FocusEvent<HTMLInputElement>) {
    if (onFocus) onFocus(event);
  }

  function HandleBlur(event: React.FocusEvent<HTMLInputElement>) {
    formikProps.handleBlur(event);
    if (onBlur) onBlur(event);
  }

  return (
    <div css={[t.inline_block, t.relative, props.fullWidth ? t.w_full : null]}>
      <Checkbox name={nameString} checked={formChecked} onFocus={HandleFocus} onBlur={HandleBlur} onChange={formikProps.handleChange} {...rest} />
      <InputErrorInline>{!suppressError && invalid && errorMessage}</InputErrorInline>
    </div>
  );
}
