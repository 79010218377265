import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { Link, useLocation } from 'react-router-dom';

export const AdminLinkTab = (props: { label?: string; to: string }) => {
  const { pathname } = useLocation();
  const active = pathname === props.to;

  const activeStyle = active ? [t.text_primary_4] : [t.text_dark_1];

  return (
    <div css={[t.flex, t.pr_6]}>
      <Link to={props.to} css={[activeStyle, t.no_underline, t.cursor_pointer, t.pt_2, t.pb_3]}>
        {props.label}
      </Link>
    </div>
  );
};
