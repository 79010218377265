import React from 'react'; // eslint-disable-line
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { InstantSearch } from 'react-instantsearch-dom';
import { Configure } from 'react-instantsearch-core';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

import HiddenSearchBox from '../../components/instantSearch/HiddenSearch';
import HiddenFilterList from '../../components/instantSearch/HiddenFilter';
import HiddenSortBy from '../../components/instantSearch/HiddenSort';
import HiddenRange from '../../components/instantSearch/HiddenRange';
import CachedSearchResults from '../../components/instantSearch/CachedSearch';
import createSearchClient from '../../util/CreateSearchClient';

import { PackageSearchControllerState, PackageSearchResult } from './types';
import { DEFAULT_SEARCH_INDEX_NAME, DEFAULT_PACKAGE_SEARCH_CONTEXT_STATE } from './constants';

// All queries will be made against the <env>_ARTISTPACKAGES index or one of its replicas
const searchClient = createSearchClient();

const toggleArrayMember = (arr: string[], member: string): string[] => {
  const newarr = [...arr];
  const index = newarr.indexOf(member);
  if (index >= 0) {
    newarr.splice(index, 1);
  } else {
    newarr.push(member);
  }

  return newarr;
};

export const readPackageSearchQueryParamOverrides = (location: { search: string }): Partial<PackageSearchControllerState> => {
  const queryParmOverrides: Partial<PackageSearchControllerState> = {};
  const { query, genreFilters, instrumentFilters, packageTagFilters, priceMin, priceMax } = queryString.parse(location.search);
  if (query) queryParmOverrides.query = query as string;
  if (priceMin) queryParmOverrides.priceMin = parseInt(priceMin as string);
  if (priceMax) queryParmOverrides.priceMax = parseInt(priceMax as string);
  if (genreFilters) queryParmOverrides.genreFilters = Array.isArray(genreFilters) ? genreFilters : [genreFilters];
  if (instrumentFilters) queryParmOverrides.instrumentFilters = Array.isArray(instrumentFilters) ? instrumentFilters : [instrumentFilters];
  if (packageTagFilters) queryParmOverrides.packageTagFilters = Array.isArray(packageTagFilters) ? packageTagFilters : [packageTagFilters];

  return queryParmOverrides;
};

export const PackageSearchContext = React.createContext<PackageSearchControllerState>({} as PackageSearchControllerState);

export function PackageSearchProvider(props: {
  initialState?: Partial<PackageSearchControllerState>;
  automaticURLUpdates?: boolean;
  children: React.ReactNode;
}) {
  const location = useLocation();
  const history = useHistory();

  // Static State
  const queryParmOverrides = React.useMemo(() => readPackageSearchQueryParamOverrides(location), [location]);

  const hadInitialSearchParams = React.useMemo(() => Object.keys(queryParmOverrides).length > 0 || !!props.initialState, [
    queryParmOverrides,
    props.initialState,
  ]);

  const initialState = React.useMemo(() => Object.assign(DEFAULT_PACKAGE_SEARCH_CONTEXT_STATE, queryParmOverrides, props.initialState || {}), [
    props.initialState,
    queryParmOverrides,
  ]);

  // Dynamic State
  const [results, setResults] = React.useState<PackageSearchResult>({ hits: [], hasMore: false });
  const [sortIndex, setSortBy] = React.useState<string>(initialState.sortIndex);
  const [query, setSearchQuery] = React.useState<string>(initialState.query);
  const [priceMin, setPriceMin] = React.useState<number | undefined>(initialState.priceMin);
  const [priceMax, setPriceMax] = React.useState<number | undefined>(initialState.priceMax);
  const [genreFilters, setGenreFilters] = React.useState<string[]>(initialState.genreFilters);
  const [instrumentFilters, setInstrumentFilters] = React.useState<string[]>(initialState.instrumentFilters);
  const [packageTagFilters, setPackageTagFilters] = React.useState<string[]>(initialState.packageTagFilters);
  const [availableFilters, setAvailableFilters] = React.useState<string[]>(initialState.availableFilters);
  const [hitsPerPage, setHitsPerPage] = React.useState<number>(initialState.hitsPerPage);
  const [pageBump, setPageBump] = React.useState<number>(initialState.pageBump);
  const [searchParamsAreDirty, setSearchParamsAreDirty] = React.useState<boolean>(false);
  // Keep the query string up to date
  const safeQueryValue = React.useMemo(() => (query.length ? query : undefined), [query]);
  // read the previous search value for comparison since the effect hook fires more than we need it to
  const previousQuery = React.useMemo(() => location.search.replace('?', ''), [location.search]);
  // Make a new query by destructuring existing params and adding the ones we care about
  // NOTE: don't assume we're the only producer or consumer of query strings!!!
  const updatedQuery = React.useMemo(
    () =>
      queryString.stringify({
        ...queryString.parse(previousQuery),
        query: safeQueryValue,
        genreFilters,
        instrumentFilters,
        packageTagFilters,
        priceMin,
        priceMax,
      }),
    [previousQuery, safeQueryValue, genreFilters, instrumentFilters, packageTagFilters, priceMin, priceMax]
  );

  //   Actions
  const updatePriceRange = (updates: string[]) => {
    const updateMin = updates[0] === 'undefined' ? undefined : parseInt(updates[0], 10);
    const updateMax = updates[1] === 'undefined' ? undefined : parseInt(updates[1], 10);
    const isDeselect = updateMin === priceMin && updateMax === priceMax;
    setPriceMin(isDeselect ? undefined : updateMin);
    setPriceMax(isDeselect ? undefined : updateMax);
  };

  const toggleGenre = (genre: string) => setGenreFilters(toggleArrayMember(genreFilters, genre));
  const toggleInstrument = (instrument: string) => setInstrumentFilters(toggleArrayMember(instrumentFilters, instrument));
  const togglePackageTag = (packageTag: string) => setPackageTagFilters(toggleArrayMember(packageTagFilters, packageTag));
  const toggleUnavailable = () => setAvailableFilters(availableFilters.includes('false') ? ['true'] : ['true', 'false']);
  const clearAllFilters = () => {
    setGenreFilters([]);
    setInstrumentFilters([]);
    setPackageTagFilters([]);
    setPriceMin(undefined);
    setPriceMax(undefined);
    setAvailableFilters(DEFAULT_PACKAGE_SEARCH_CONTEXT_STATE.availableFilters);
  };
  const loadMoreResults = () => setPageBump(pageBump + 1);
  const updateURLQuerystring = React.useCallback(() => history.replace({ search: updatedQuery }), [history, updatedQuery]);
  const replaceSearchState = (newSearchState: Partial<PackageSearchControllerState>) => {
    if (newSearchState.sortIndex) setSortBy(newSearchState.sortIndex);
    if (newSearchState.query) setSearchQuery(newSearchState.query);
    if (newSearchState.priceMin) setPriceMin(newSearchState.priceMin as number);
    if (newSearchState.priceMax) setPriceMax(newSearchState.priceMax as number);
    if (newSearchState.genreFilters) setGenreFilters(newSearchState.genreFilters);
    if (newSearchState.instrumentFilters) setInstrumentFilters(newSearchState.instrumentFilters);
    if (newSearchState.packageTagFilters) setPackageTagFilters(newSearchState.packageTagFilters);
    if (newSearchState.availableFilters) setAvailableFilters(newSearchState.availableFilters);
    if (newSearchState.hitsPerPage) setHitsPerPage(newSearchState.hitsPerPage);
    // TODO: We do not support inbound pages at this time
    // if (newSearchState.pageBump) setPageBump(newSearchState.pageBump);
  };

  React.useEffect(() => {
    if (!props.automaticURLUpdates) return;
    // only update the query if it changed
    if (updatedQuery !== previousQuery) {
      updateURLQuerystring();
    }
  }, [updateURLQuerystring, props.automaticURLUpdates, updatedQuery, previousQuery]);

  React.useEffect(() => {
    if (searchParamsAreDirty) return;
    if (query !== initialState.query) setSearchParamsAreDirty(true);
    if (packageTagFilters !== initialState.packageTagFilters) setSearchParamsAreDirty(true);
    if (instrumentFilters !== initialState.instrumentFilters) setSearchParamsAreDirty(true);
    if (genreFilters !== initialState.genreFilters) setSearchParamsAreDirty(true);
    if (priceMin !== initialState.priceMin) setSearchParamsAreDirty(true);
    if (priceMax !== initialState.priceMax) setSearchParamsAreDirty(true);
  }, [initialState, searchParamsAreDirty, setSearchParamsAreDirty, query, packageTagFilters, instrumentFilters, genreFilters, priceMin, priceMax]);

  const activeFilterCount = React.useMemo(() => instrumentFilters.concat(packageTagFilters).concat(genreFilters).length + (!!priceMin || !!priceMax ? 1 : 0), [
    instrumentFilters,
    packageTagFilters,
    genreFilters,
    priceMin,
    priceMax,
  ]);

  return (
    <PackageSearchContext.Provider
      value={{
        hitsPerPage,
        query,
        priceMin: `${priceMin}`,
        priceMax: `${priceMax}`,
        sortIndex,
        genreFilters,
        instrumentFilters,
        packageTagFilters,
        availableFilters,
        hasActiveFilters: activeFilterCount > 0,
        activeFilterCount,
        hits: results.hits,
        hasMore: results.hasMore,
        hadInitialSearchParams,
        searchParamsAreDirty,
        setPriceMin,
        setPriceMax,
        setGenreFilters,
        setInstrumentFilters,
        setPackageTagFilters,
        setHitsPerPage,
        loadMoreResults,
        updatePriceRange,
        setSearchQuery,
        toggleGenre,
        toggleInstrument,
        togglePackageTag,
        toggleUnavailable,
        setSortBy,
        clearAllFilters,
        updateURLQuerystring,
        replaceSearchState,
      }}
    >
      <InstantSearch searchClient={searchClient} indexName={DEFAULT_SEARCH_INDEX_NAME}>
        <Configure hitsPerPage={hitsPerPage} />
        <HiddenSortBy items={[]} value={sortIndex} />
        <HiddenSearchBox query={query} defaultRefinement={initialState.query} />
        <HiddenRange attribute="price" min={priceMin} max={priceMax} />
        <HiddenFilterList attribute="artistGenreTags" value={genreFilters} defaultRefinement={initialState.genreFilters} />
        <HiddenFilterList attribute="artistInstrumentTags" value={instrumentFilters} defaultRefinement={initialState.instrumentFilters} />
        <HiddenFilterList attribute="tags" value={packageTagFilters} defaultRefinement={initialState.packageTagFilters} />
        {/* <HiddenFilterList attribute="available" value={availableFilters} /> */}
        <CachedSearchResults pageBump={pageBump} setResults={setResults} />
      </InstantSearch>
      {props.children}
    </PackageSearchContext.Provider>
  );
}

export function usePackageSearch() {
  return React.useContext(PackageSearchContext);
}

export default usePackageSearch;
