import * as React from 'react';
import { AccountSettings } from './AccountContext';
import { FirebaseSDK } from './FirebaseContext';

const UserAccountContext = React.createContext<AccountSettings | null>(null);
export function UserAccountProvider(props: { userId: string; children: React.ReactNode }) {
  const [account, setAccount] = React.useState<AccountSettings | null>(null);
  React.useEffect(() => {
    if (!props.userId) setAccount(null);
    else {
      const firestore = FirebaseSDK.app().firestore();
      const onUpdate = (snapshot: firebase.default.firestore.DocumentSnapshot) => {
        const accountData = snapshot.data() as AccountSettings;
        accountData.id = props.userId;
        setAccount(accountData);
      };

      const onError = (error: Error) => {
        console.error(error);
        setAccount(null);
      };

      const unsubscribe = firestore.doc(`/accounts/${props.userId}`).onSnapshot(onUpdate, onError);
      return () => {
        unsubscribe();
      };
    }
  }, [props.userId]);
  return <UserAccountContext.Provider value={account}>{props.children}</UserAccountContext.Provider>;
}

export function useUserAccount() {
  return React.useContext(UserAccountContext);
}
