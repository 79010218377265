import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { AdminSearchTableCell } from '../components/AdminSearchTableCell';
import { connectHits, HitsProvided } from 'react-instantsearch-core';
import { AdminSearchTableRow, AdminSearchRowProps } from '../components/AdminSearchTableRow';
import { AdminArtistDetailsDialog } from './AdminArtistDetailsDialog';
import { ArtistOrderStats } from '../../../contexts/ArtistProfileContext';
import { AdminSearchTableActionCell } from '../components/AdminSearchTableActionCell';
import { AdminIconButton } from '../components/AdminIconButton';
import { PopupIcon } from '../icons/PopupIcon';

interface ArtistSearchHit {
  name: string;
  artistId: string;
  genreTags: string[];
  instrumentTags: string[];
  orderStats: ArtistOrderStats;
  notedState: string;
  isInstantBook: string;
}

const Hits = (props: HitsProvided<ArtistSearchHit>) => {
  const [detailsActive, SetDetailsActive] = React.useState(false);
  const [objectID, SetObjectID] = React.useState<string | null>(null);

  function HandleClose(event: React.MouseEvent | React.KeyboardEvent) {
    event.stopPropagation();
    SetDetailsActive(false);
    SetObjectID(null);
  }

  return (
    <tbody css={[t.p_0, t.text_sm, t.h_12]}>
      {props.hits.map((hit, i: number) => {
        return (
          <AdminSearchTableRow key={i}>
            {(rowProps: AdminSearchRowProps) => {
              function HandleClick() {
                SetObjectID(hit.objectID);
                SetDetailsActive(true);
              }

              const statusElement: React.ReactNode | null = null;
              // const sharedStatusStyle = [t.absolute, t.inset_y_0, t.left_0, t.border_solid, t.border_l_2];
              // if (artistAccount.banned) {
              //   statusElement = <div css={[sharedStatusStyle, t.border_error_1]}>Banned</div>;
              // } else if (profileData.pageDisabled) {
              //   statusElement = <div css={[sharedStatusStyle, t.border_tint_2]}>Disabled</div>;
              // } else if (profileData.deactivated) {
              //   statusElement = <div css={[sharedStatusStyle, t.border_tint_2]}>Inactive</div>;
              // }

              return (
                <React.Fragment>
                  <AdminSearchTableCell>{statusElement}</AdminSearchTableCell>
                  <AdminSearchTableCell>{hit.name}</AdminSearchTableCell>
                  <AdminSearchTableCell>{hit.artistId}</AdminSearchTableCell>
                  <AdminSearchTableCell>{hit.notedState}</AdminSearchTableCell>
                  <AdminSearchTableCell>{hit.isInstantBook}</AdminSearchTableCell>
                  <AdminSearchTableCell>{hit.orderStats.pendingOrders}</AdminSearchTableCell>
                  <AdminSearchTableCell>{hit.orderStats.activeOrders}</AdminSearchTableCell>
                  <AdminSearchTableCell>{hit.orderStats.completedOrders}</AdminSearchTableCell>
                  <AdminSearchTableCell>{hit.objectID}</AdminSearchTableCell>

                  <AdminSearchTableActionCell>
                    {rowProps.isHovered && (
                      <AdminIconButton id="artistDetailsButton" onClick={HandleClick}>
                        <PopupIcon css={[t.size('16px')]} />
                      </AdminIconButton>
                    )}
                  </AdminSearchTableActionCell>
                </React.Fragment>
              );
            }}
          </AdminSearchTableRow>
        );
      })}
      <AdminArtistDetailsDialog isOpen={detailsActive} userId={objectID} onClose={HandleClose} />
    </tbody>
  );
};

export const AdminArtistsSearchHits = connectHits(Hits);
