import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { connectRefinementList } from 'react-instantsearch-dom';
import { RefinementListProvided } from 'react-instantsearch-core';

export interface AdminFilterDescriptor {
  label: string;
  attribute: string;
  options: string[];
  defaults: string[];
}

export interface AdminFilterProps {
  shown: boolean;
  filters: AdminFilterDescriptor[];
}

export function AdminFilter(props: AdminFilterProps) {
  const showMenu = props.shown ? [t.absolute, t.top_0, t.w('300px'), t.ml('-270px'), t.mt('48px')] : t.hidden;
  return (
    <div css={[showMenu, t.z_30, t.bg_white, t.shadow_md]}>
      <div css={[t.px_5, t.py_3, t.text_sm, t.text_dark_3, t.border_b, t.border_solid, t.border_tint_4]}>Filter by</div>
      <div css={[t.lastType('div', [])]}>
        {props.filters.map((filter) => {
          return (
            <div css={[t.px_5, t.py_5, t.border_b, t.border_solid, t.border_tint_4]} key={filter.attribute}>
              <div css={[t.text_sm, t.text_tint_1]}>{filter.label}</div>
              <AdminFilterSection attribute={filter.attribute} descriptor={filter} defaultRefinement={filter.defaults} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

function AdminFilterSectionComponent(props: { descriptor: AdminFilterDescriptor } & RefinementListProvided) {
  const activeRefinements = props.items.filter((x) => x.isRefined).map((x) => x.label);
  return (
    <ul>
      {props.descriptor.options.map((option) => {
        const item = props.items.find((x) => x.label === option);
        const refined = item ? item.isRefined : false;
        const value = item ? item.value : [...activeRefinements, option];
        return (
          <li key={option}>
            <div>
              <input
                type="checkbox"
                checked={refined}
                onChange={() => {
                  props.refine(value);
                }}
              />
              <span>{option}</span>
            </div>
          </li>
        );
      })}
    </ul>
  );
}

const AdminFilterSection = connectRefinementList(AdminFilterSectionComponent);

function HiddenFilterComponent(props: unknown & RefinementListProvided) {
  return <React.Fragment />;
}

export const HiddenFilter = connectRefinementList(HiddenFilterComponent);
