import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t, { CSSStyle } from '../theme/newstyles';

export const UnderlineButton = (props: { onClick: (event: React.MouseEvent) => void; children: React.ReactNode; linkStyle?: CSSStyle }) => {
  const [focus, SetFocus] = React.useState(false);

  const HandleFocus = React.useCallback(() => {
    SetFocus(true);
  }, []);

  const HandleBlur = React.useCallback(() => {
    SetFocus(false);
  }, []);

  return (
    <div css={[t.relative]}>
      <button
        css={[
          t.typeStyle_anchor,
          t.text_right,
          t.cursor_pointer,
          t.whitespace_no_wrap,
          t.select_none,
          t.underline,
          t.cursor_pointer,
          t.flex,
          t.flex_row,
          t.items_center,
          t.bg_transparent,
          t.before([t.focusIndicator, t.border_primary_4, focus ? t.content_some : t.content_none]),
          props.linkStyle,
        ]}
        onClick={props.onClick}
        onFocus={HandleFocus}
        onBlur={HandleBlur}
      >
        {props.children}
      </button>
    </div>
  );
};
