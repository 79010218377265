import React, { useState } from 'react'; // eslint-disable-line
import { ReactPlayerComponent } from './ReactPlayerComponent';

/** @jsx jsx */
import { jsx } from '@emotion/core';

export const ReactPlayerTest = () => {
  const songURLs = ['http://www.largesound.com/ashborytour/sound/brobob.mp3', 'http://www.largesound.com/ashborytour/sound/brobob.mp3'];
  const songPlayers = songURLs.map((url, i) => {
    return <ReactPlayerComponent url={url} key={i} />;
  });

  return <div>{songPlayers}</div>;
};
