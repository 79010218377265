import React from 'react'; // eslint-disable-line
import { motion } from 'framer-motion';

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import t from '../../theme/newstyles';
import { MainPage } from '../../components/MainPage';
import { MainContainer } from '../../components/MainContainer';
import { Button } from '../../components/Button';
import { Link } from 'react-router-dom';
import { withAdminAccount } from '../../hocs/withAdminAccount';
import { TestArtistCard, TestArtist } from './TestArtistCard';
import { useAnimationTestController, MotionCollectionForm, MotionComponentToken } from './AnimationTestContext';
import { useFormikContext } from 'formik';
import { AnimationTokens } from './AnimationTestData';

const artistCardGrid = [
  t.grid_1,
  t.grid_gap_x8,
  t.sm([t.grid_2, t.grid_gap_y5]),
  t.lg([
    t.grid_3,
    css`
      grid-column-gap: 32px;
    `,
  ]),
  t.xl([
    css`
      grid-column-gap: 40px;
    `,
  ]),
];

function Headliner(props: { imageMotion: MotionComponentToken; detailsMotion: MotionComponentToken }) {
  return (
    <MainContainer>
      <Link to="/admin/animation/artistPage" css={[t.text_dark_1, t.active(t.text_dark_1), t.visited(t.text_dark_1)]}>
        <div css={[t.relative, t.breakpointGrid_12]}>
          <motion.img {...AnimationTokens[props.imageMotion.tokenKey]} css={[t.col_start_3, t.min_h('700px'), t.max_h('700px')]} src={'/HHpromo.jpeg'} alt="" />
          <div css={[t.absolute, t.inset_x_0, t.bottom_0, t.breakpointGrid_12]}>
            <HeadlinerDetails detailsMotion={props.detailsMotion} />
          </div>
        </div>
      </Link>
    </MainContainer>
  );
}

const HeadlinerDetails = (props: { detailsMotion: MotionComponentToken }) => {
  return (
    <div css={[t.col_span_6, t.bg_tint_5, t.pt_6, t.pb_8, t.pr_8]}>
      <motion.div {...AnimationTokens[props.detailsMotion.tokenKey]}>
        <div css={[t.gridTypeStyle_2]}>Hawthorne Heights</div>
        <div css={[t.typeStyle_mono, t.mt_5]}>PUNK / ROCK / ALTERNATIVE / ACOUSTIC</div>
        <div css={[t.gridTypeStyle_6, t.mt_5]}>
          {`Sometimes the past haunts you like a ghost, but we weren't afraid to become the ghosts of what we used to be. There is still some life and hope left
          in these bones, and we will continue to suck the marrow out.`}
        </div>
      </motion.div>
    </div>
  );
};

const featuredArtistList: TestArtist[] = [
  {
    name: 'Shane Henderson',
    to: '/admin/animation/artistPage',
    photoURL: '/6f9e91a0-707c-11ea-903b-51ac86c935a7.jpg',
    genreTags: ['Acoustic', 'Pop', 'Punk Rock'],
  },
  { name: 'Alyssa Beyer', to: '/admin/animation/artistPage', photoURL: '/b9204a00-707f-11ea-ac87-49d2be68a06f.jpg', genreTags: ['Rock', 'Pop', 'Electronic'] },
  { name: 'David Bello', to: '/admin/animation/artistPage', photoURL: '/442e1980-7083-11ea-ab9e-1fd9c03fed17.jpg', genreTags: ['Punk Rock', 'Indie'] },
  {
    name: 'Kristina Cottone',
    to: '/admin/animation/artistPage',
    photoURL: '/265f66e0-7081-11ea-903b-51ac86c935a7.jpg',
    genreTags: ['Blues', 'Soul', 'Acoustic'],
  },
  { name: 'Brian Pretus', to: '/admin/animation/artistPage', photoURL: '/6fcbc6b0-707d-11ea-ac87-49d2be68a06f.jpg', genreTags: ['Punk Rock', 'Rock'] },
  {
    name: 'Tara Jean',
    to: '/admin/animation/artistPage',
    photoURL: '/dbd4bc40-7078-11ea-bd02-754d8c925103.jpeg',
    genreTags: ['RnB', 'Soul', 'Pop', 'Electronic'],
  },
];

function FeaturedArtists(props: { h2Motion: MotionComponentToken; cardMotion: MotionComponentToken }) {
  return (
    <div css={[t.mt_6]}>
      <motion.h2 {...AnimationTokens[props.h2Motion.tokenKey]} css={[t.gridTypeStyle_3, t.mb_5]}>
        Featured Artists
      </motion.h2>
      <motion.div
        {...AnimationTokens[props.cardMotion.tokenKey]}
        custom={t.override(props.cardMotion.transition ? props.cardMotion.transition : {})}
        css={[artistCardGrid]}
      >
        {featuredArtistList.map((artist, i: number) => {
          return (
            <TestArtistCard key={i} name={artist.name} to={artist.to} photoURL={artist.photoURL} genreTags={artist.genreTags} preferredSampleGenres={[]} />
          );
        })}
      </motion.div>
    </div>
  );
}

// type Occassion = {
//   id: string;
//   title: string;
//   description: string;
//   artists: Artist[];
// };

// const occasionList: Occassion[] = [
//   { id: '1', title: '', description: '', artists: [{ name: 'Tara Jean', to: '', photoURL: '', genreTags: ['RnB', 'Soul', 'Pop', 'Electronic'] }] },
// ];

// const occasionCardGrid = [
//   t.grid_1,
//   t.grid_gap_x8,
//   t.sm([t.grid_2, t.grid_gap_y5]),
//   t.lg([
//     t.grid_4,
//     css`
//       grid-column-gap: 32px;
//     `,
//   ]),
//   t.xl([
//     css`
//       grid-column-gap: 40px;
//     `,
//   ]),
// ];

// function Occasions(props: {}) {
//   return (
//     <div css={[t.mt_6, t.bg_tint_3, t.w_screen, t.pt_7, t.pb_7]}>
//       <MainContainer>
//         <h2 css={[t.gridTypeStyle_3, t.mb_5]}>Occasions</h2>
//         {occasionList.map((occasion, i) => {
//           return (
//             <div key={occasion.id} css={[t.mb_6]}>
//               <div css={[occasionCardGrid]}>
//                 <div css={[t.bg_tint_4, t.py_6, t.px_6]}>
//                   <div css={[t.mt_2, t.gridTypeStyle_3]}>{occasion.title}</div>
//                   <div css={[t.mt_2, t.gridTypeStyle_5]}>{occasion.description}</div>
//                 </div>
//                 {occasion.artists.map(artist => {
//                   return (
//                     <ArtistCard
//                       key={artist.name}
//                       name={artist.name}
//                       to={artist.to}
//                       photoURL={artist.photoURL}
//                       genreTags={artist.genreTags}
//                       preferredSampleGenres={[]}
//                     />
//                   );
//                 })}
//               </div>
//             </div>
//           );
//         })}
//       </MainContainer>
//     </div>
//   );
// }

const genreList = ['Rock', 'Pop', 'Hip hop', 'Grindcore', 'Post punk', 'Alternative'];

function PopularGenres(props: { genreMotion: MotionComponentToken }) {
  return (
    <motion.div {...AnimationTokens[props.genreMotion.tokenKey]} css={[t.bg_tint_3, t.w_screen, t.pt_7, t.pb_7]}>
      <MainContainer>
        <div css={[t.flex, t.justify_start]}>
          <h2 css={[t.gridTypeStyle_4, t.text_tint_1, t.mr_5]}>Popular genres</h2>
          {genreList.map((genre) => {
            return (
              <span key={genre} css={[t.gridTypeStyle_4, t.mr_5]}>
                {genre}
              </span>
            );
          })}
        </div>
      </MainContainer>
    </motion.div>
  );
}

const AnimationTestDiscoveryPage = () => {
  const { values } = useFormikContext<MotionCollectionForm>();
  const tokens = values.collections[values.active].discovery;

  return (
    <MainPage>
      <MainContainer>
        <motion.h1 {...AnimationTokens[tokens.h1.tokenKey]} css={[t.mt_7, t.mb_8, t.gridTypeStyle_2]}>
          Discover
        </motion.h1>
      </MainContainer>
      <Headliner imageMotion={tokens.headlinerImage} detailsMotion={tokens.headliner} />
      <PopularGenres genreMotion={tokens.genres} />
      <MainContainer>
        <FeaturedArtists h2Motion={tokens.h2} cardMotion={tokens.card} />
      </MainContainer>
      {/* <Occasions /> */}
      <MainContainer>
        <div css={[t.mt_8, t.mb_9, t.flex, t.justify_center]}>
          <Button type="link" styleType="secondary" label="See all" to={'/admin/animation/search'} />
        </div>
      </MainContainer>
    </MainPage>
  );
};

const AnimationTestDiscoveryComponent = () => {
  const animationController = useAnimationTestController();

  return <AnimationTestDiscoveryPage key={animationController.key} />;
};

export const AnimationTestDiscovery = withAdminAccount(AnimationTestDiscoveryComponent);
