import { css } from '@emotion/core';

export const visibility = {
  visible: css`
    visibility: visible;
  `,
  invisible: css`
    visibility: hidden;
  `,
};
