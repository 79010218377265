import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { useArtistProfile } from '../../../contexts/ArtistProfileContext';
import { useUserAccount } from '../../../contexts/UserAccountContext';

export const AdminArtistDetailsAvailability = () => {
  const artistProfile = useArtistProfile();
  const artistAccount = useUserAccount();

  const profileData = artistProfile.Profile;
  if (!profileData) return null;
  if (!artistAccount || artistAccount.deleted) return null;

  if (profileData.availability.available) return null;

  return (
    <div css={[t.flex, t.flex_row, t.items_center, t.px_6, t.text_sm, t.min_h('55px'), t.bg_tint_3]}>
      <div css={[t.text_dark_1]}>Unavailable</div>
      <div css={[t.ml_3, t.text_tint_1]}>{`"${profileData.availability.message}"`}</div>
    </div>
  );
};
