import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { motion } from 'framer-motion';
import t from '../theme/newstyles';

export const Spinner = (props: { styleType?: 'primary' | 'secondary' | 'secondaryOnDark' | 'critical' | 'overlay'; small?: boolean }) => {
  let spinnerFgStroke = t.primary_3;
  let spinnerBgStroke = t.tint_2;

  switch (props.styleType) {
    case 'primary':
    case 'overlay': {
      spinnerFgStroke = t.tint_5;
      spinnerBgStroke = t.primary_1;
      break;
    }
    case 'secondary': {
      spinnerFgStroke = t.dark_1;
      spinnerBgStroke = t.tint_1;
      break;
    }
    case 'secondaryOnDark': {
      spinnerFgStroke = t.tint_5;
      spinnerBgStroke = t.tint_1;
      break;
    }
    case 'critical': {
      spinnerFgStroke = t.tint_2;
      spinnerBgStroke = t.error_1;
      break;
    }
  }

  const spinner = css`
    box-sizing: border-box;
    border-radius: 50%;
    border: ${props.small ? '1px' : '3px'} solid #000;
    border-color: ${spinnerBgStroke} ${spinnerBgStroke} ${spinnerFgStroke} ${spinnerBgStroke};
    width: ${props.small ? '22px' : '50px'};
    height: ${props.small ? '22px' : '50px'};
  `;

  return <motion.div animate={{ rotate: [0, 360] }} transition={{ repeat: Infinity }} css={[spinner]} />;
};
