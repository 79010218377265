import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { VocalLabels, DurationLabels } from '../../../contexts/OrderContext';
import { SongPackage } from '../../../types/shared-types';
import { ClientSideOrder as Order } from '../../../types/shared-types';
import { SongOrderDetails } from '../../../types/shared-types';
import Dinero from 'dinero.js';

export const AdminOrderDetailsBrief = (props: { order: Order; expanded: boolean }) => {
  if (props.order.order.type !== 'song') return null;

  const details = props.order.order.details;

  return (
    <div css={[t.relative, t.border_b, t.border_solid, t.border_tint_3, t.pt_3, t.pb_6, t.px_6, t.flex, t.flex_col]}>
      <div css={[t.text_sm, t.text_tint_1]}>Brief</div>
      <div css={[t.grid_12, t.mt_2]}>
        <div css={[t.col_span_7, t.flex, t.flex_col, t.pr_8]}>
          <div css={[t.text_sm, t.text_dark_1]}>{details.feelings.join(', ')}</div>
          <div css={[t.text_xs, t.text_tint_1, t.mt_1, !props.expanded && t.truncate]}>{details.description}</div>
        </div>
        <div css={[t.col_span_3, t.col_start_9, t.flex, t.flex_col, t.items_end]}>
          <SongPackageDetails order={props.order} expanded={props.expanded} />
        </div>
      </div>
    </div>
  );
};

const detailsGrid = css`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-items: end;
`;

const SongPackageDetails = (props: { order: Order; expanded: boolean }) => {
  const songPackage = props.order.order.package as SongPackage;
  const songDetails = props.order.order.details as SongOrderDetails;
  const $subtotal = Dinero(props.order.price.subtotal);
  const discountPercent = props.order.discountPercentageApplied || 0;
  const $discountAmount = { ...$subtotal }.divide(1 - discountPercent / 100).subtract($subtotal);

  const $platformFee = { ...$subtotal }.percentage(100 - props.order.artistSplit);
  const packageDescriptors: string[] = [];
  packageDescriptors.push(VocalLabels[songPackage.vocals]);
  packageDescriptors.push(DurationLabels[songPackage.duration]);

  return (
    <div css={[detailsGrid, t.grid_gap_y3, t.grid_gap_x1]}>
      {props.expanded && (
        <React.Fragment>
          <PackageDetail label={`Package: ${songPackage.name}`} value={Dinero(songPackage.price).toFormat('$0')} details={packageDescriptors} />
          {songDetails.rushOrder && <PackageDetail label="Rush order" value={Dinero(songPackage.rushCost).toFormat('$0')} />}
          {songDetails.handWrittenLyrics && <PackageDetail label="Handwritten lyrics" value={Dinero(songPackage.handWrittenLyricsCost).toFormat('$0')} />}
          {songDetails.videoGreeting && <PackageDetail label="Video greeting" value={Dinero(songPackage.videoGreetingCost).toFormat('$0')} />}
          {songDetails.videoPerformance && <PackageDetail label="Video performance" value={Dinero(songPackage.videoPerformanceCost).toFormat('$0')} />}
          {discountPercent > 0 && <PackageDetail label={`Discount (${discountPercent}%)`} value={$discountAmount.toFormat('$0.00')} />}
          <PackageDetail label="Tax" value={Dinero(props.order.price.totalTax).toFormat('$0.00')} />
        </React.Fragment>
      )}
      <div css={[t.col_start_1, t.text_sm, t.text_dark_1, t.pt_2]}>Total</div>
      <div css={[t.col_start_2, t.text_sm, t.text_dark_1, t.pt_2]}>{Dinero(props.order.price.totalAmount).toFormat('$0.00')}</div>
      <div css={[t.col_start_1, t.text_sm, t.text_dark_1, t.pt_2]}>Artist Cut</div>
      <div css={[t.col_start_2, t.text_sm, t.text_dark_1, t.pt_2]}>{{ ...$subtotal }.subtract($platformFee).toFormat('$0.00')}</div>
      <div css={[t.col_start_1, t.text_sm, t.text_dark_1, t.pt_2]}>DW Cut</div>
      <div css={[t.col_start_2, t.text_sm, t.text_dark_1, t.pt_2]}>{$platformFee.toFormat('$0.00')}</div>
    </div>
  );
};

const PackageDetail = (props: { label?: string; details?: string[]; value?: string }) => {
  return (
    <React.Fragment>
      <div css={[t.col_start_1]}>
        <div css={[t.flex_auto, t.text_xs, t.text_dark_1, t.text_right]}>{props.label}</div>
        {props.details && (
          <div css={[t.mt_1, t.flex, t.flex_col, t.items_end, t.text_xs, t.text_tint_1]}>
            {props.details.map((value, i) => {
              return <div key={i}>{value}</div>;
            })}
          </div>
        )}
      </div>
      <div css={[t.col_start_2, t.text_xs, t.text_tint_1]}>{props.value}</div>
    </React.Fragment>
  );
};
