import React, { Props } from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';
import { InputLabel } from './InputLabel';
import { TextInputProps } from './TextField';

export function TextArea<T>(
  props: {
    id: string;
    value?: string | number | string[];
    outlined?: boolean;
    required?: boolean;
    invalid?: boolean;
    rows?: number;
    cols?: number;
    characterCount: number;
    hideCharacterCount?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  } & TextInputProps<T>
) {
  const [focus, SetFocus] = React.useState(props.focused);
  const textAreaElement = React.useRef<HTMLTextAreaElement | null>(null);
  const labelId = `${props.id}_label`;
  const descId = `${props.id}_desc`;

  const initiallyFocused = !!props.focused;
  React.useEffect(() => {
    if (textAreaElement && textAreaElement.current && initiallyFocused) {
      SetFocus(initiallyFocused);
      textAreaElement.current.focus();
    }
  }, [initiallyFocused, textAreaElement]);

  function HandleFocus(event: React.FocusEvent<HTMLTextAreaElement>) {
    SetFocus(true);
    if (props.onFocus) props.onFocus(event);
  }

  function HandleBlur(event: React.FocusEvent<HTMLTextAreaElement>) {
    SetFocus(false);
    if (props.onBlur) props.onBlur(event);
  }

  let borderStyle = [t.border_tint_2, t.hover(t.border_dark_1)];
  if (props.disabled) borderStyle = [t.border_tint_2];
  if (props.invalid) borderStyle = [t.border_error_1];

  return (
    <div css={[t.min_w('200px'), t.flex, t.flex_col, props.containerStyle]}>
      {props.label && (
        <div css={[t.flex, t.flex_row, !props.description && t.mb_2]}>
          <div css={[t.flex_auto, t.flex, t.flex_row, t.items_center]}>
            <InputLabel label={props.label} for={props.id} id={labelId} labelOffscreen={props.labelOffscreen} />
            {props.tooltipRender && props.tooltipRender()}
          </div>
          {props.optional && <div css={[t.flex_initial, t.typeStyle_lg7, t.text_tint_1, t.font_normal, t.mt_1]}>*Optional</div>}
        </div>
      )}
      {props.description && <div css={[t.gridTypeStyle_7, t.pb_3]}>{props.description}</div>}
      <div css={[props.inputContainerStyle]}>
        <textarea
          ref={textAreaElement}
          id={props.id}
          name={props.name as string}
          value={props.value as string}
          placeholder={props.placeholder ? props.placeholder : ''}
          disabled={props.disabled}
          required={props.required}
          aria-required={props.required}
          aria-disabled={props.disabled}
          aria-labelledby={labelId}
          aria-describedby={descId}
          aria-invalid={props.invalid}
          onChange={props.onChange}
          onFocus={HandleFocus}
          onBlur={HandleBlur}
          wrap={'soft'}
          maxLength={props.maxLength}
          rows={props.rows}
          cols={props.cols}
          css={[
            t.bg_transparent,
            t.border_solid,
            t.border_1,
            borderStyle,
            t.resize_y,
            t.w_full,
            t.p_4,
            t.typeStyle_lg6,
            t.text_dark_1,
            t.placeholder(t.text_tint_1),
            t.disabled(t.bg_tint_4),
            focus ? [t.border_primary_4, t.outline_none] : null,
            props.inputStyle,
          ]}
        />
      </div>
      {!props.hideCharacterCount && (
        <div id={descId} css={[t.text_1, t.mt_2, props.characterCountStyle]}>{`${props.characterCount}/${props.maxLength} characters used`}</div>
      )}
    </div>
  );
}
