import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';

export const AspectPortraitIcon = (props: {}) => {
  return (
    <svg width="30" height="40" viewBox="0 0 30 40" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 12L30 1L30 0L29 -4.37132e-08L18 -5.24537e-07L18 0.999999L29 1L29 12L30 12ZM30 39L30 28L29 28L29 39L18 39L18 40L29 40L30 40L30 39ZM1 0.999999L0.999999 12L-5.24537e-07 12L-4.37114e-08 0.999999L0 -1.31134e-06L1 -1.26763e-06L12 -7.86807e-07L12 0.999999L1 0.999999ZM0.999998 39L0.999999 28L-1.22392e-06 28L-1.70474e-06 39L-1.74846e-06 40L0.999998 40L12 40L12 39L0.999998 39Z"
        css={t.fill_current}
      />
    </svg>
  );
};
