import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import Dinero from 'dinero.js';
import { AddArtistPaymentSplit, EditArtistPaymentSplit, RemoveArtistPaymentSplit, useArtistProfile } from '../../../contexts/ArtistProfileContext';
import { useUserAccount } from '../../../contexts/UserAccountContext';
import { AdminDetailsDialogInfo } from '../components/Dialogs/AdminDetailsDialogInfo';
import { usePaymentSplitContent } from '../../../contexts/PaymentSplitsContentContext';
import { AdminButton } from '../components/AdminButton';
import { AdminSplit } from '../components/AdminSplit';

export const AdminArtistDetailsFinancials = () => {
  const artistProfile = useArtistProfile();
  const artistAccount = useUserAccount();
  const profileData = artistProfile.Profile;

  if (!profileData) return null;
  if (!artistAccount || artistAccount.deleted) return null;

  const timeStats = profileData.dashboardStats.timePeriodStats;

  return (
    <div css={[t.pt_3, t.pb_6, t.px_6, t.flex, t.flex_auto]}>
      <div css={[t.flex0, t.pr_8]}>
        <div css={[t.text_sm, t.text_tint_1]}>Earnings</div>
        <div css={[t.flex, t.flex_wrap, t.mt_4]}>
          <AdminDetailsDialogInfo label="This Month" text={Dinero(timeStats.thisMonth.earnings).toFormat('$0.00')} containerStyle={[t.mr_6]} />
          <AdminDetailsDialogInfo label="Last Month" text={Dinero(timeStats.lastMonth.earnings).toFormat('$0.00')} containerStyle={[t.mr_6]} />
          <AdminDetailsDialogInfo label="Last 6 Months" text={Dinero(timeStats.sixMonths.earnings).toFormat('$0.00')} containerStyle={[t.mr_6]} />
          <AdminDetailsDialogInfo label="Last 12 Months" text={Dinero(timeStats.oneYear.earnings).toFormat('$0.00')} />
        </div>
      </div>
      <div css={[t.flex0]}>
        <div css={[t.text_sm, t.text_tint_1]}>Splits</div>
        {profileData.paymentSplit ? <CustomSplit artistId={artistAccount.id} /> : <DefaultSplit artistId={artistAccount.id} />}
      </div>
    </div>
  );
};

const DefaultSplit = (props: { artistId: string }) => {
  const { artistId } = props;
  const artistProfile = useArtistProfile();
  const defaultSplit = usePaymentSplitContent();
  const profileData = artistProfile.Profile;

  const handleCreateCustom = React.useCallback(() => {
    AddArtistPaymentSplit(artistId);
  }, [artistId]);

  if (!profileData) return null;

  return (
    <div css={[t.flex, t.flex_col, t.mt_4]}>
      <AdminDetailsDialogInfo label="Default Commission Split">
        <div css={[t.flex, t.mt_1]}>
          {defaultSplit ? (
            <div css={[t.text_xs, t.text_dark_4, t.mr_6]}>{`${defaultSplit.percentage}%`}</div>
          ) : (
            <div css={[t.text_sm, t.text_tint_2, t.pb_4]}>No splits configured</div>
          )}
        </div>
      </AdminDetailsDialogInfo>
      <div css={[t.mt_3]}>
        <AdminButton type="button" onClick={handleCreateCustom} label="Customize split" />
      </div>
    </div>
  );
};

const CustomSplit = (props: { artistId: string }) => {
  const { artistId } = props;
  const artistProfile = useArtistProfile();
  const splitContent = usePaymentSplitContent();
  const profileData = artistProfile.Profile;

  const handleCustomize = React.useCallback(async () => {
    await AddArtistPaymentSplit(artistId);
  }, [artistId]);

  const handleReset = React.useCallback(async () => {
    await RemoveArtistPaymentSplit(artistId);
  }, [artistId]);

  const handleEdit = React.useCallback(
    async (percentage: number) => {
      await EditArtistPaymentSplit(artistId, percentage);
    },
    [artistId]
  );

  if (!splitContent) return null;
  if (!profileData) return null;

  const isCustom = !!profileData.paymentSplit;
  return (
    <div css={[t.flex, t.flex_col, t.mt_4]}>
      <AdminDetailsDialogInfo label={isCustom ? 'Custom Commission Split' : 'Default Commission Split'}>
        <div css={[t.flex, t.mt_1]}>
          {profileData.paymentSplit ? (
            <AdminSplit split={profileData.paymentSplit} onEdit={handleEdit} />
          ) : (
            <div css={[t.text_xs, t.text_dark_4, t.mr_6]}>{`${splitContent.percentage}%`}</div>
          )}
        </div>
      </AdminDetailsDialogInfo>
      {isCustom ? (
        <div css={[t.mt_3]}>
          <AdminButton type="button" styleType="critical" onClick={handleReset} label="Reset" />
        </div>
      ) : (
        <div css={[t.mt_3]}>
          <AdminButton type="button" onClick={handleCustomize} label="Customize split" />
        </div>
      )}
    </div>
  );
};
