import React from 'react'; // eslint-disable-line
import { motion } from 'framer-motion';

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import t, { CSSStyle } from '../../theme/newstyles';
import { MainContainer } from '../../components/MainContainer';
import { Button } from '../../components/Button';
import { useArtistSearch } from '../../contexts/ArtistSearchContext';
import { useMD } from '../../contexts/BreakpointContext';
import { FullScreenDialog } from '../../components/dialogs/FullScreenDialog';
import { CloseIcon } from '../../icons/CloseIcon';
import { InputText } from '../../components/InputText';

import { SearchIcon } from '../../icons/SearchIcon';

const dropShadow = css`
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
`;

export const ArtistSearchControlsV2 = (props: {
  searchButtonDisabled?: boolean;
  filterMenuActive: boolean;
  outerContainerCSS?: CSSStyle;
  onMenuToggle: (update?: boolean) => void;
}) => {
  const isMD = useMD();
  const $input = React.useRef<HTMLInputElement | null>(null);
  const artistSearchContext = useArtistSearch();
  const [currentSearchVal, setCurrentSearchVal] = React.useState('');
  const [hasInteracted, setHasInteracted] = React.useState(false);
  const [areControlsActive, setAreControlsActive] = React.useState(false);
  const [isHovering, setIsHovering] = React.useState(false);
  const hasSearchValue = !!currentSearchVal;
  const borderColor = areControlsActive || isHovering ? t.border_primary_4 : t.border_transparent;

  React.useEffect(() => {
    // Keep the search value up to date as the context boots up but stop
    // watching once the user interacts
    if (
      !hasInteracted &&
      artistSearchContext &&
      artistSearchContext.state &&
      artistSearchContext.state.query &&
      artistSearchContext.state.query !== currentSearchVal
    )
      setCurrentSearchVal(artistSearchContext.state.query);
  }, [artistSearchContext, currentSearchVal, hasInteracted]);

  const HandleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      artistSearchContext.clearAllFilters();
      artistSearchContext.setSearchQuery(event.currentTarget.value);
      if (props.filterMenuActive) props.onMenuToggle(false);
    }
  };

  const HandleButtonClick = (event: React.MouseEvent) => {
    artistSearchContext.clearAllFilters();
    artistSearchContext.setSearchQuery(currentSearchVal || '');
    if (props.filterMenuActive) props.onMenuToggle(false);
  };

  const HandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentSearchVal(event.target.value);
  };

  const HandleInputFocus = () => {
    setHasInteracted(true);
    setAreControlsActive(true);
  };

  return isMD ? (
    <MainContainer containerCSS={[t.pt_5, t.bg_tint_5, props.outerContainerCSS]}>
      <MainContainer containerCSS={[t.pt_6, t.bg_tint_5, t.z_50, t.p('0 !important'), dropShadow]} centerContainerCSS={[t.px_0]}>
        <motion.div {...t.motion_fadeIn} css={[t.w_full, t.flex, t.items_stretch, t.justify_between, t.h('128px')]}>
          <div
            css={[t.w_full, t.p_6, t.border_1, t.border_solid, borderColor]}
            onMouseEnter={() => {
              if (!areControlsActive) {
                setIsHovering(true);
              }
            }}
            onMouseLeave={() => setIsHovering(false)}
          >
            <label htmlFor="search">
              <motion.p css={[hasSearchValue ? t.typeStyle_lg7 : t.typeStyle_lg5, t.text_dark_1, t.mb_1]}>Artist</motion.p>
              <input
                ref={$input}
                css={[hasSearchValue ? t.typeStyle_lg5 : t.typeStyle_lg6, t.w_full, t.bg_transparent, t.placeholder(t.text_tint_1)]}
                id="search"
                type="text"
                name="search"
                value={currentSearchVal}
                placeholder="Enter name of musician or act"
                onChange={HandleChange}
                onFocus={HandleInputFocus}
                onBlur={() => setAreControlsActive(false)}
                onKeyPress={HandleKeyPress}
              />
            </label>
          </div>
          <Button label="Search" onClick={HandleButtonClick} type="button" innerStyle={[t.h_full, t.max_h_full]} disabled={props.searchButtonDisabled} />
        </motion.div>
      </MainContainer>
    </MainContainer>
  ) : (
    <React.Fragment>
      <div
        css={[
          t.flex,
          t.justify_between,
          t.block,
          t.w_full,
          t.h_full,
          t.text_left,
          t.px('24px'),
          t.lg([t.px('28px')]),
          t.xl([t.px('32px')]),
          t.border_t,
          t.border_b,
          t.border_solid,
          t.border_tint_3,
          t.bg_tint_5,
        ]}
      >
        <button css={[t.bg_tint_5, t.text_left, t.py_5, t.w_full, t.cursor_pointer, t.hover(t.underline)]} onClick={() => props.onMenuToggle(true)}>
          <div css={[t.flex, t.items_center, t.typeStyle_lg6, t.text_dark_1]}>
            <SearchIcon css={[t.inline_block, t.mr_3]} />
            <span css={[t.text_tint_1, t.typeStyle_lg6]}>{hasSearchValue ? currentSearchVal : 'Search for an artist'}</span>
          </div>
        </button>
      </div>
      <FullScreenDialog isOpen={props.filterMenuActive} onRequestClose={() => props.onMenuToggle(false)}>
        <div css={[t.fixed, t.inset_0, t.content_between, t.flex, t.flex_col, t.justify_between]}>
          <div>
            <MainContainer>
              <div css={t.block}>
                <div css={[t.flex, t.flex_row, t.justify_between, t.items_center, t.my_5]}>
                  <div css={[t.typeStyle_lg4]}>Search for an artist</div>
                  <div css={[t.cursor_pointer]} onClick={() => props.onMenuToggle(false)}>
                    <CloseIcon css={[t.w_5, t.h_5, t.fill_current]} />
                  </div>
                </div>
              </div>
            </MainContainer>

            <div
              css={[
                t.w_full,
                t.text_left,
                t.px('24px'),
                t.lg([t.px('28px')]),
                t.xl([t.px('32px')]),
                t.border_t,
                t.border_b,
                t.border_solid,
                t.border_tint_3,
                t.py('10px'),
              ]}
            >
              <InputText
                id="search"
                name="search"
                type="text"
                icon={!hasInteracted ? <SearchIcon css={[t.text_tint_1]} /> : undefined}
                placeholder="Search for an artist"
                label="search"
                labelOffscreen="search"
                onKeyPress={(event) => HandleKeyPress(event)}
                value={currentSearchVal}
                onChange={HandleChange}
                onFocus={() => setHasInteracted(true)}
                focused
                inputStyle={[t.pr('54px'), t.border_none, hasInteracted && t.pl_0, t.text_dark_1]}
              />
            </div>
          </div>

          <div css={[t.w_full, t.flex, t.justify_center, t.pt_5, t.pb_5, t.border_t, t.border_solid, t.border_tint_3, t.shadow_header]}>
            <MainContainer>
              <Button type="button" label="Search" fullWidth={true} onClick={HandleButtonClick} />
            </MainContainer>
          </div>
        </div>
      </FullScreenDialog>
    </React.Fragment>
  );
};

export default ArtistSearchControlsV2;
