import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';

export const PlayPauseIcon = (props: {}) => {
  return (
    <svg role="img" aria-labelledby="playPauseIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <title id="playPauseIconTitleID">Play/Pause Icon</title>
      <rect x="16" y="4" width="1" height="16" css={[t.fill_current]} />
      <rect x="20" y="4" width="1" height="16" css={[t.fill_current]} />
      <path fillRule="evenodd" clipRule="evenodd" d="M2 19L14 12L2 5V19ZM3 6.74104V17.259L12.0154 12L3 6.74104Z" css={[t.fill_current]} />
    </svg>
  );
};
