import React from 'react'; // eslint-disable-line
import { withAdminAccount } from '../../hocs/withAdminAccount';

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../theme/newstyles';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { AdminPage } from '../admin/components/AdminPage';
import { AdminLinkTab } from '../admin/components/AdminLinkTab';
import { TypeTest } from './TypeTest';
import { ButtonTest } from './ButtonTest';
import { TextInputTest } from './TextInputTest';
import { CheckboxTest } from './CheckboxTest';
import { ReactPlayerTest } from './ReactPlayerTest';
import { PaymentTest } from './PaymentTest';
import { NotFound } from '../errors/NotFound';
import { SelectTest } from './SelectTest';
import { IconTest } from './IconTest';

const ComponentTestComponent = () => {
  const { path } = useRouteMatch();

  return (
    <AdminPage
      pageHeading="Components"
      tabChildren={
        <div css={[t.flex, t.flex_row]}>
          <AdminLinkTab label="Types" to={`${path}/types`} />
          <AdminLinkTab label="Buttons" to={`${path}/buttons`} />
          <AdminLinkTab label="Text Input" to={`${path}/textInput`} />
          <AdminLinkTab label="Checkboxes" to={`${path}/checkboxes`} />
          <AdminLinkTab label="React Player" to={`${path}/reactPlayer`} />
          <AdminLinkTab label="Payment" to={`${path}/payment`} />
          <AdminLinkTab label="Icons" to={`${path}/icons`} />
          <AdminLinkTab label="Select" to={`${path}/select`} />
        </div>
      }
    >
      <Switch>
        <Route path={`${path}/types`} component={TypeTest} />
        <Route path={`${path}/buttons`} component={ButtonTest} />
        <Route path={`${path}/textInput`} component={TextInputTest} />
        <Route path={`${path}/checkboxes`} component={CheckboxTest} />
        <Route path={`${path}/reactPlayer`} component={ReactPlayerTest} />
        <Route path={`${path}/payment`} component={PaymentTest} />
        <Route path={`${path}/icons`} component={IconTest} />
        <Route path={`${path}/select`} component={SelectTest} />
        <Route component={NotFound} />
      </Switch>
    </AdminPage>
  );
};

export const ComponentTest = withAdminAccount(ComponentTestComponent);
