import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';
import { InputLabel } from './InputLabel';
import { TextInputProps } from './TextField';

export function InputText<T>(
  props: {
    id: string;
    value?: string | number | string[];
    icon?: JSX.Element;
    startAdornment?: string;
    required?: boolean;
    invalid?: boolean;
    focused?: boolean;
    hosted?: boolean;
    readOnly?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  } & TextInputProps<T>
) {
  const [focus, SetFocus] = React.useState(props.focused);
  const inputElement = React.useRef<HTMLInputElement | null>(null);
  const labelId = `${props.id}_label`;

  const initiallyFocused = !!props.focused;
  React.useEffect(() => {
    if (inputElement && inputElement.current && initiallyFocused) {
      SetFocus(initiallyFocused);
      inputElement.current.focus();
    }
  }, [initiallyFocused, inputElement]);

  let container = [t.border_tint_2, t.hover(t.border_dark_1)];
  if (props.disabled) container = [t.border_tint_2];
  if (props.invalid) {
    container = [t.border_error_1];
  }
  if (focus) {
    container = [t.border_primary_4];
  }

  const disabledContainer = props.disabled ? t.bg_tint_4 : t.bg_transparent;

  function HandleClick(event: React.MouseEvent) {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }

  function HandleFocus(event: React.FocusEvent<HTMLInputElement>) {
    SetFocus(true);
    if (props.onFocus) props.onFocus(event);
  }

  function HandleBlur(event: React.FocusEvent<HTMLInputElement>) {
    SetFocus(false);
    if (props.onBlur) props.onBlur(event);
  }

  return (
    <div css={[t.flex, t.flex_col, t.min_w('200px'), props.containerStyle]}>
      {props.label && (
        <div css={[t.flex, t.flex_row, !props.description && !props.labelOffscreen && props.labelOffscreen !== '' && t.mb_2]}>
          <div css={[t.flex_auto, t.flex, t.flex_row, t.items_center]}>
            <InputLabel label={props.label} id={labelId} for={props.id} labelOffscreen={props.labelOffscreen} />
            {props.tooltipRender && props.tooltipRender()}
          </div>
          {props.optional && <div css={[t.flex_initial, t.typeStyle_lg7, t.text_tint_1, t.font_normal, t.mt_1]}>*Optional</div>}
        </div>
      )}
      {props.description && <div css={[t.gridTypeStyle_7, t.pb_3]}>{props.description}</div>}
      <div css={[props.inputContainerStyle]}>
        <div
          id={props.id}
          css={[
            t.relative,
            t.w_full,
            t.h('56px'),
            t.inline_flex,
            t.items_center,
            t.border_solid,
            t.border_1,
            container,
            t.typeStyle_lg6,
            t.text_dark_1,
            t.py('14px'),
            t.px_4,
            props.description && [t.pr_5],
            disabledContainer,
            t.focus_within([t.border_primary_4]),
            props.inputStyle,
          ]}
          onClick={HandleClick}
        >
          {!props.hosted && (
            <React.Fragment>
              <InputIcon icon={props.icon} invalid={props.invalid} focus={focus} />
              <InputStartAdornment adornment={props.startAdornment} />
              <input
                ref={inputElement}
                css={[t.typeStyle_lg6, t.w_full, t.bg_transparent, t.placeholder(t.text_tint_1)]}
                id={props.id}
                type={props.type ? props.type : 'text'}
                name={props.name as string}
                value={props.value}
                placeholder={props.placeholder ? props.placeholder : ''}
                disabled={props.disabled}
                required={props.required}
                aria-required={props.required}
                aria-disabled={props.disabled}
                aria-labelledby={props.id}
                aria-invalid={props.invalid}
                onChange={props.onChange}
                onBlur={HandleBlur}
                onFocus={HandleFocus}
                onKeyPress={props.onKeyPress}
                maxLength={props.maxLength}
                readOnly={props.readOnly}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

const InputIcon = (props: { icon?: JSX.Element; invalid?: boolean; focus?: boolean }) => {
  if (!props.icon) return null;

  let iconColor = [t.text_tint_2];

  if (props.invalid) iconColor = [t.text_error_1];
  if (props.focus) iconColor = [t.text_primary_4];

  return <div css={[t.flex, t.items_center, t.justify_center, t.mr_3, t.size('24px'), iconColor]}>{props.icon}</div>;
};

const InputStartAdornment = (props: { adornment?: string }) => {
  if (!props.adornment) return null;
  return <div css={[t.flex, t.items_center, t.justify_center, t.mr_2, t.typeStyle_lg6, t.text_tint_2]}>{props.adornment}</div>;
};
