import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/newstyles';
import { Dialog } from '../../../components/dialogs/Dialog';
import { DialogTitle } from '../../../components/dialogs/DialogTitle';
import { DialogContent } from '../../../components/dialogs/DialogContent';
import { DialogActions } from '../../../components/dialogs/DialogActions';
import { Button } from '../../../components/Button';
import { Checkbox } from '../../../components/Checkbox';

export const AdminResolveOrderDialog = (props: { isOpen: boolean; onClose?: () => void; handleResolve: () => void }) => {
  const [confirmedUnderstanding, setConfirmedUnderstanding] = React.useState(false);

  const handleInputChange = () => {
    setConfirmedUnderstanding(!confirmedUnderstanding);
  };

  return (
    <Dialog isOpen={props.isOpen} aria={{ labelledby: 'submitProject_dialog_heading' }} onRequestClose={props.onClose}>
      <DialogTitle id="submitProject_dialog_heading">Attention Admin</DialogTitle>
      <DialogContent>
        <div css={[t.pt_2, t.pb_5]}>
          <p css={[t.gridTypeStyle_6, t.text_dark_1]}>
            Clicking confirm will automatically generate a follow-up email and notification to the commissioner, and update the commission status. These actions
            cannot be undone. Are you ready to proceed?
          </p>
        </div>
        <Checkbox
          name={'Admin Checkbox'}
          label={'By checking this box, I acknowledge that the issue is sufficiently resolved and that I’m aware of the consequences of taking this action.'}
          onChange={handleInputChange}
          checked={confirmedUnderstanding}
        />
      </DialogContent>
      <DialogActions>
        <Button type="button" styleType="secondary" label="Cancel" onClick={props.onClose} outerStyle={[t.w_full]} innerStyle={[t.w_full]} />
        <Button
          type="button"
          buttonType="button"
          styleType="primary"
          label="Confirm"
          onClick={props.handleResolve}
          outerStyle={[t.w_full]}
          innerStyle={[t.w_full]}
          disabled={!confirmedUnderstanding}
        />
      </DialogActions>
    </Dialog>
  );
};
