import { css } from '@emotion/core';
import { text } from './text';
import { fontWeight } from './fontWeight';
import { fontFamily } from './fontFamily';
import { breakpoints } from './breakpoints';

export const typeStyle = {
  typeStyle_sm1: [text.text_12, fontWeight.font_medium],
  typeStyle_sm2: [text.text_10, fontWeight.font_normal],
  typeStyle_sm3: [text.text_8, fontWeight.font_normal],
  typeStyle_sm4: [text.text_6, fontWeight.font_normal],
  typeStyle_sm5: [text.text_5, fontWeight.font_normal],
  typeStyle_sm6: [text.text_2, fontWeight.font_normal],
  typeStyle_sm7: [text.text_0, fontWeight.font_normal],

  typeStyle_md1: [text.text_16, fontWeight.font_medium],
  typeStyle_md2: [text.text_13, fontWeight.font_normal],
  typeStyle_md3: [text.text_11, fontWeight.font_normal],
  typeStyle_md4: [text.text_7, fontWeight.font_normal],
  typeStyle_md5: [text.text_6, fontWeight.font_normal],
  typeStyle_md6: [text.text_3, fontWeight.font_normal],
  typeStyle_md7: [text.text_0, fontWeight.font_normal],
  typeStyle_md8: [text.text_3, fontWeight.font_medium],

  typeStyle_lg1: [text.text_18, fontWeight.font_medium],
  typeStyle_lg2: [text.text_15, fontWeight.font_normal],
  typeStyle_lg3: [text.text_12, fontWeight.font_normal],
  typeStyle_lg4: [text.text_9, fontWeight.font_normal],
  typeStyle_lg5: [text.text_6, fontWeight.font_normal],
  typeStyle_lg6: [text.text_3, fontWeight.font_normal],
  typeStyle_lg7: [text.text_0, fontWeight.font_normal],
  typeStyle_lg8: [text.text_3, fontWeight.font_medium],

  typeStyle_mono: [
    fontFamily.font_mono,
    css`
      font-size: 14px;
      line-height: 16px;
    `,
  ],
  typeStyle_anchor: [
    text.text_3,
    fontWeight.font_normal,
    css`
      text-decoration-line: underline;
    `,
  ],
};

export const breakpointTypeStyle = {
  typeStyle_1: [typeStyle.typeStyle_sm1, breakpoints.sm(typeStyle.typeStyle_md1), breakpoints.lg(typeStyle.typeStyle_lg1)],
  typeStyle_2: [typeStyle.typeStyle_sm2, breakpoints.sm(typeStyle.typeStyle_md2), breakpoints.lg(typeStyle.typeStyle_lg2)],
  typeStyle_3: [typeStyle.typeStyle_sm3, breakpoints.sm(typeStyle.typeStyle_md3), breakpoints.lg(typeStyle.typeStyle_lg3)],
  typeStyle_4: [typeStyle.typeStyle_sm4, breakpoints.sm(typeStyle.typeStyle_md4), breakpoints.lg(typeStyle.typeStyle_lg4)],
  typeStyle_5: [typeStyle.typeStyle_sm5, breakpoints.sm(typeStyle.typeStyle_md5), breakpoints.lg(typeStyle.typeStyle_lg5)],
  typeStyle_6: [typeStyle.typeStyle_sm6, breakpoints.sm(typeStyle.typeStyle_md6), breakpoints.lg(typeStyle.typeStyle_lg6)],
  typeStyle_7: [typeStyle.typeStyle_sm7, breakpoints.sm(typeStyle.typeStyle_md7), breakpoints.lg(typeStyle.typeStyle_lg7)],
};

export const gridTypeStyle = {
  gridTypeStyle_1: [
    breakpointTypeStyle.typeStyle_1,
    css`
      padding-top: 9px;
      padding-bottom: 11px;
    `,
    breakpoints.sm(
      css`
        padding-top: 4px;
        padding-bottom: 8px;
      `
    ),
    breakpoints.lg(
      css`
        padding-top: 9px;
        padding-bottom: 15px;
      `
    ),
  ],
  gridTypeStyle_2: [
    breakpointTypeStyle.typeStyle_2,
    css`
      padding-top: 8px;
      padding-bottom: 12px;
    `,
    breakpoints.sm(
      css`
        padding-top: 4px;
        padding-bottom: 8px;
      `
    ),
    breakpoints.lg(
      css`
        padding-top: 9px;
        padding-bottom: 15px;
      `
    ),
  ],
  gridTypeStyle_3: [
    breakpointTypeStyle.typeStyle_3,
    css`
      padding-top: 6px;
      padding-bottom: 6px;
    `,
    breakpoints.sm(
      css`
        padding-top: 3px;
        padding-bottom: 9px;
      `
    ),
    breakpoints.lg(
      css`
        padding-top: 5px;
        padding-bottom: 11px;
      `
    ),
  ],
  gridTypeStyle_4: [
    breakpointTypeStyle.typeStyle_4,
    breakpoints.sm(css`
      padding-top: 5px;
      padding-bottom: 7px;
    `),
    breakpoints.lg(css`
      padding-top: 5px;
      padding-bottom: 11px;
    `),
  ],
  gridTypeStyle_5: [
    breakpointTypeStyle.typeStyle_5,
    css`
      padding-top: 3px;
      padding-bottom: 5px;
    `,
    breakpoints.sm(css`
      padding-top: 2px;
      padding-bottom: 6px;
    `),
  ],
  gridTypeStyle_6: [
    breakpointTypeStyle.typeStyle_6,
    css`
      padding-top: 2px;
      padding-bottom: 6px;
    `,
    breakpoints.sm(css`
      padding-top: 0;
      padding-bottom: 0;
    `),
  ],
  gridTypeStyle_7: [breakpointTypeStyle.typeStyle_7, breakpoints.md(typeStyle.typeStyle_md7)],
};
