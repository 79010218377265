import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';

export const BandcampIcon = (props: {}) => {
  return (
    <svg role="img" aria-labelledby="bandcampIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <title id="bandcampIconTitleID">Bandcamp Icon</title>
      <path d="M10.3333 9L7 15H13.6667L17 9H10.3333Z" css={[t.stroke_current]} />
      <circle cx="12" cy="12" r="10.5" css={[t.stroke_current]} />
    </svg>
  );
};
