import React from 'react'; // eslint-disable-line
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { RefinementListProvided, connectRefinementList } from 'react-instantsearch-core';

import { TagsMatch } from '../../util/CompareTags';

// This allows you to add filter queries
function HiddenFilter(props: RefinementListProvided & { value: string[] }) {
  const value = props.value;
  const refinement = props.currentRefinement;
  const refine = props.refine;
  React.useEffect(() => {
    if (!TagsMatch(value, refinement)) refine(value);
  }, [value, refinement, refine]);
  return <React.Fragment></React.Fragment>;
}

export default connectRefinementList(HiddenFilter);
