import { css } from '@emotion/core';

export const dropShadow = {
  drop_shadow_1: css`
    filter: drop-shadow(0px 2px 24px rgba(0, 0, 0, 0.99));
  `,
  drop_shadow_2: css`
    filter: drop-shadow(0px 2px 18px rgba(0, 0, 0, 0.1));
  `,
  drop_shadow_3: css`
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
  `,
  drop_shadow_4: css`
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.5));
  `,
};
