import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_A11Y) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const axe = require('react-axe');
  axe(React, ReactDOM, 100);
}

ReactModal.setAppElement('#root');
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
