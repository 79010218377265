import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';

export const FilterIcon = (props: {}) => {
  return (
    <svg role="img" aria-labelledby="filterIconTitleID" width="19" height="20" viewBox="0 0 19 20" fill="none" {...props}>
      <title id="filterIconTitleID">Filter</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0H3V3.05001C4.14112 3.28164 5 4.29052 5 5.5C5 6.70948 4.14112 7.71836 3 7.94999V20H2V7.94999C0.85888 7.71836 0 6.70948 0 5.5C0 4.29052 0.85888 3.28164 2 3.05001V0ZM2.5 7C3.32843 7 4 6.32843 4 5.5C4 4.67157 3.32843 4 2.5 4C1.67157 4 1 4.67157 1 5.5C1 6.32843 1.67157 7 2.5 7Z"
        css={[t.fill_current]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0H10V11.05C11.1411 11.2816 12 12.2905 12 13.5C12 14.7095 11.1411 15.7184 10 15.95V20H9V15.95C7.85888 15.7184 7 14.7095 7 13.5C7 12.2905 7.85888 11.2816 9 11.05V0ZM9.5 15C10.3284 15 11 14.3284 11 13.5C11 12.6716 10.3284 12 9.5 12C8.67157 12 8 12.6716 8 13.5C8 14.3284 8.67157 15 9.5 15Z"
        css={[t.fill_current]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0H17V5.05001C18.1411 5.28164 19 6.29052 19 7.5C19 8.70948 18.1411 9.71836 17 9.94999V20H16V9.94999C14.8589 9.71836 14 8.70948 14 7.5C14 6.29052 14.8589 5.28164 16 5.05001V0ZM16.5 9C17.3284 9 18 8.32843 18 7.5C18 6.67157 17.3284 6 16.5 6C15.6716 6 15 6.67157 15 7.5C15 8.32843 15.6716 9 16.5 9Z"
        css={[t.fill_current]}
      />
    </svg>
  );
};

export default FilterIcon;
