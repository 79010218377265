/** @jsx jsx */
import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/core';
import { CreditCardDetailsProvider, useCreditCardDetails, PaymentClientProvider } from '../../contexts/CreditCardDetailsContext';
import { FirebaseObjects } from '../../contexts/FirebaseContext';
import t from '../../theme/newstyles';
import { InputText } from '../../components/InputText';
import { Button } from '../../components/Button';

export function TestCreditCardForm() {
  const [processing, setProcessing] = React.useState(false);
  const cardDetails = useCreditCardDetails();

  const handleSubmit = async () => {
    setProcessing(true);
    const details = await cardDetails.getDetails();
    if (details) {
      const result = await FirebaseObjects.adminRequest({
        action: 'testBraintreeTransaction',
        data: { amount: '1.00', nonce: details.token.nonce, deviceData: details.deviceData },
      });
      console.log(result);
    }
    setProcessing(false);
  };

  return (
    <div css={[!cardDetails.fields ? t.hidden : undefined]}>
      <div css={[t.mb_7]}>
        <InputText id="card-number" name="cardNumber" label="Card number" hosted focused={cardDetails.fields?.number.isFocused} />
      </div>
      <div css={[t.mb_7]}>
        <InputText id="cvv" name="cvv" label="CVV" hosted focused={cardDetails.fields?.cvv.isFocused} />
      </div>
      <div css={[t.mb_7]}>
        <InputText id="expiration-date" name="expirationDate" label="Expiration date" hosted focused={cardDetails.fields?.expirationDate.isFocused} />
      </div>
      <Button type="button" buttonType="submit" label="Pay" disabled={!cardDetails.getDetails || processing} onClick={handleSubmit} />
    </div>
  );
}

export const PaymentTest = () => {
  return (
    <PaymentClientProvider>
      <CreditCardDetailsProvider inputStyle={[]}>
        <TestCreditCardForm />
      </CreditCardDetailsProvider>
    </PaymentClientProvider>
  );
};
