import * as React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';

import { publicDownloadUrlBase, FirebaseSDK, FirebaseObjects } from '../contexts/FirebaseContext';
import { UploadImage } from './ArtistProfileContext';
import deepEqual from 'deep-equal';

export interface AccountNotificationSettings {
  notifyProjects: boolean;
  notifyComments: boolean;
  notifyNews: boolean;
}

export interface AccountClaims {
  isArtist: boolean;
  isAdmin: boolean;
  isBanned: boolean;
}

export type AccountNotification =
  | {
      type: 'projectRequest';
      time: string;
      read: boolean;
      from: string;
      customerName: string;
      occasion: string;
      projectId: string;
    }
  | {
      type: 'projectExpiringSoon';
      time: string;
      read: boolean;
      from: string;
      customerName: string;
      occasion: string;
      projectId: string;
    }
  | {
      type: 'projectExpired';
      time: string;
      read: boolean;
      from: string;
      customerName: string;
      occasion: string;
      projectId: string;
    }
  | {
      type: 'projectComment';
      time: string;
      read: boolean;
      from: string;
      customerName: string;
      occasion: string;
      msg: string;
      projectId: string;
    }
  | {
      type: 'projectDue';
      time: string;
      read: boolean;
      from: string;
      occasion: string;
      daysLeft: number;
      projectId: string;
    }
  | {
      type: 'projectChanges';
      time: string;
      read: boolean;
      from: string;
      customerName: string;
      occasion: string;
      msg: string;
      projectId: string;
    }
  | {
      type: 'orderAccepted';
      time: string;
      read: boolean;
      from: string;
      artistName: string;
      occasion: string;
      projectId: string;
    }
  | {
      type: 'orderCanceled';
      time: string;
      read: boolean;
      from: string;
      artistName: string;
      occasion: string;
      projectId: string;
    }
  | {
      type: 'orderComplete';
      time: string;
      read: boolean;
      from: string;
      occasion: string;
      projectId: string;
    }
  | {
      type: 'orderComment';
      time: string;
      read: boolean;
      from: string;
      artistName: string;
      occasion: string;
      msg: string;
      projectId: string;
    }
  | {
      type: 'updateRating';
      time: string;
      read: boolean;
      from: string; // we may not even need to include this currently, but if we want to add a customer service specific icon/placeholder in the future we may need it. However it would require reconfiguring the CreateProfileImageLink() function OR the ProfileImage.tsx component
      artistName: string;
      projectId: string;
      customerId: string;
      occasion?: string;
    };

export interface AccountSettings {
  id: string;
  deleted: boolean;
  banned: boolean;
  email: string;
  signupDate: string;
  firstname: string;
  lastname: string;
  notificationSettings: AccountNotificationSettings;
  profileImage: string;
  ordersPlaced: number;

  city?: string;
  postalCode?: string;
  countryCode?: string;

  lastNotificationSeenTime: string;
  notifications: AccountNotification[];
}

export interface Account {
  User: firebase.default.User;
  Claims: AccountClaims;
  Settings: AccountSettings;
}

const AccountContext = React.createContext<Account | null | undefined>(undefined);
export function AccountProvider(props: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<firebase.default.User | null | undefined>(undefined);
  const [claims, setClaims] = React.useState<AccountClaims | null | undefined>(undefined);
  const [settings, setSettings] = React.useState<AccountSettings | null | undefined>(undefined);

  React.useEffect(() => {
    const auth = FirebaseSDK.auth();
    const onUpdate = (updatedUser: firebase.default.User | null) => {
      setUser(updatedUser);
    };

    const onError = (error: firebase.default.auth.Error) => {
      console.error(error);
      setUser(null);
    };

    return auth.onAuthStateChanged(onUpdate, onError);
  }, []);

  React.useEffect(() => {
    const auth = FirebaseSDK.auth();
    let oldClaims: Partial<AccountClaims> & { emailVerified?: boolean } = {};

    const onUpdate = async (updatedUser: firebase.default.User | null) => {
      if (updatedUser) {
        const token = await updatedUser.getIdTokenResult(true);
        const newClaims: AccountClaims & { emailVerified: boolean } = {
          isArtist: token.claims.isArtist,
          isAdmin: token.claims.isAdmin,
          isBanned: token.claims.isBanned,
          emailVerified: updatedUser.emailVerified,
        };

        if (!deepEqual(newClaims, oldClaims)) {
          oldClaims = newClaims;
          setClaims(newClaims);
        }
      } else {
        oldClaims = {};
        setClaims(null);
      }
    };

    const onError = (error: firebase.default.auth.Error) => {
      console.error(error);
      oldClaims = {};
      setClaims(null);
    };

    return auth.onIdTokenChanged(onUpdate, onError);
  }, []);

  React.useEffect(() => {
    setSettings(undefined);
    if (user) {
      const firestore = FirebaseSDK.app().firestore();
      const settingsDoc = firestore.doc(`/accounts/${user.uid}`);
      const onUpdate = (data: firebase.default.firestore.DocumentSnapshot) => {
        const settingsData = data.data() as AccountSettings;
        if (settingsData && settingsData.notifications) {
          settingsData.id = user.uid;
          settingsData.notifications.sort((a, b) => {
            if (a.time < b.time) return 1;
            if (a.time > b.time) return -1;
            return 0;
          });
        }
        setSettings(settingsData);
      };

      const onError = (error: Error) => {
        console.error(error);
        setSettings(null);
      };

      return settingsDoc.onSnapshot(onUpdate, onError);
    } else if (user === null) setSettings(null);
  }, [user]);

  // Segment tracking
  const analytics = window.analytics;
  React.useEffect(() => {
    if (user && analytics) {
      try {
        analytics.identify(user.uid, { email: user.email });
      } catch (e) {
        console.log(e);
      }
    }
  }, [user, analytics]);

  let state: Account | null | undefined = null;
  if (user === undefined || claims === undefined || settings === undefined) state = undefined;
  else if (user && claims && settings) state = { User: user, Claims: claims, Settings: settings };
  return <AccountContext.Provider value={state}>{props.children}</AccountContext.Provider>;
}

export function useAccount() {
  return React.useContext(AccountContext);
}

export async function EditGeneralSettings(
  firstname: string,
  lastname: string,
  profileImage?: string,
  city?: string,
  postalCode?: string,
  countryCode?: string
) {
  const payload: { firstname?: string; lastname?: string; profileImage?: string; city?: string; postalCode?: string; countryCode?: string } = {
    firstname,
    lastname,
  };
  if (profileImage) payload.profileImage = await UploadImage(profileImage);
  if (city) payload.city = city;
  if (countryCode) payload.countryCode = countryCode;
  if (postalCode) payload.postalCode = postalCode;

  try {
    await FirebaseObjects.userRequest({
      action: 'editUserSettings',
      data: payload,
    });
  } catch (error) {
    console.log(error);
    return false;
  }
  return true;
}

export async function EditNotificationSettings(notificationSettings: AccountNotificationSettings) {
  try {
    await FirebaseObjects.userRequest({
      action: 'editUserSettings',
      data: { notificationSettings },
    });
  } catch (error) {
    console.error(error);
    return false;
  }
  return true;
}

export function HasUnseenNotifications(account: Account) {
  const lastSeen = account.Settings.lastNotificationSeenTime || '';
  const notifications = account.Settings.notifications;
  if (!lastSeen || !notifications) return false;
  if (notifications.length > 0) return notifications[0].time > lastSeen;
  return false;
}

export async function UpdateLastSeenTime() {
  await FirebaseObjects.userRequest({ action: 'editUserNotifications', data: { updateSeenTime: true } });
}

export function LastSeenUpdater(props: unknown) {
  const account = useAccount();
  React.useEffect(() => {
    if (!account) return;
    const asyncWrapper = async () => {
      if (HasUnseenNotifications(account)) {
        await UpdateLastSeenTime();
      }
    };
    asyncWrapper();
  }, [account]);

  return null;
}

export function HasUnreadNotifications(account: Account, projectId: string) {
  const notifications = account.Settings.notifications;
  if (!notifications) return false;

  for (const notification of notifications) {
    if (notification.projectId === projectId && !notification.read) return true;
  }
  return false;
}

export async function MarkProjectNotificationsRead(projectId: string) {
  await FirebaseObjects.userRequest({ action: 'editUserNotifications', data: { projectReadId: projectId } });
}

export async function MarkSingleNotificationRead(notificationTimestamp: string) {
  await FirebaseObjects.userRequest({ action: 'editUserNotifications', data: { matchingTime: notificationTimestamp } });
}

export function ProjectReadUpdater(props: { projectId: string }) {
  const account = useAccount();
  const projectId = props.projectId;
  React.useEffect(() => {
    if (!account) return;
    const asyncWrapper = async () => {
      if (HasUnreadNotifications(account, projectId)) {
        await MarkProjectNotificationsRead(projectId);
      }
    };
    asyncWrapper();
  }, [account, projectId]);

  return <React.Fragment />;
}

export function CreateNotificationLink(notification: AccountNotification) {
  switch (notification.type) {
    case 'projectComment':
    case 'projectChanges':
    case 'projectDue':
    case 'projectRequest':
    case 'projectExpired':
    case 'projectExpiringSoon':
      return `/projects/${notification.projectId}`;
    case 'orderAccepted':
    case 'orderComment':
      return `/commissions/${notification.projectId}`;
    case 'orderComplete':
      return `/share/s/${notification.projectId}`;
    case 'orderCanceled':
      return '/artists';
    case 'updateRating':
      return `/update-rating/${notification.projectId}/${notification.artistName}`;
    default:
      return '';
  }
}

export function CreateProfileImageLink(accountId: string) {
  if (!accountId) return '';
  return `${publicDownloadUrlBase}/${accountId}/profile_56x75`;
}

export async function SignUpForMailingList(email: string) {
  try {
    await FirebaseObjects.userRequest({
      action: 'mailingListSignup',
      data: { email },
    });
  } catch (error) {
    console.error(error);
  }
}

export async function UnsubscribeFromMailingList(email: string) {
  try {
    await FirebaseObjects.userRequest({
      action: 'mailingListUnsubscribe',
      data: { email },
    });
  } catch (error) {
    console.error(error);
  }
}
