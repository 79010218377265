import React from 'react'; // eslint-disable-line
import { connectSearchBox } from 'react-instantsearch-dom';

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { AdminSearchIcon } from '../icons/AdminSearchIcon';
import { SearchBoxProvided } from 'react-instantsearch-core';

interface SearchBoxProps extends SearchBoxProvided {
  placeholder?: string;
}

const SearchBox = (props: SearchBoxProps) => {
  const HandleSearchBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    props.refine(event.target.value);
  };

  const HandleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') props.refine(event.currentTarget.value);
  };

  return (
    <div css={[t.flex, t.flex_row, t.items_center, t.flex_auto]}>
      <div css={[t.mr_4, t.ml_2, t.text_tint_1]}>
        <AdminSearchIcon css={[t.size('16px')]} />
      </div>
      <div css={[t.w_full, t.flex, t.flex_col]}>
        <input
          css={[t.h('56px'), t.min_h('56px'), t.lineHeight('56px'), t.bg_transparent, t.flex_auto, t.text_sm, t.text_dark_2]}
          type="text"
          placeholder={props.placeholder ? props.placeholder : 'Search by email or user ID'}
          onKeyPress={(event) => HandleKeyPress(event)}
          onBlur={(event) => HandleSearchBlur(event)}
          defaultValue={props.currentRefinement}
        />
      </div>
    </div>
  );
};

export const AdminCustomSearchBox = connectSearchBox<SearchBoxProps>(SearchBox);
