import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { useOrder, AdminUseVideo } from '../../../contexts/OrderContext';
import { SongOrder } from '../../../types/shared-types';
import { AdminDetailsLinkButton } from '../components/Dialogs/AdminDetailsLinkButton';
import { AdminButton } from '../components/AdminButton';
import { SongProvider, useSong } from '../../../contexts/SongContext';

export const AdminOrderDetailsSongs = () => {
  const order = useOrder();
  if (!order) return null;
  if (order.order.type !== 'song') return null;

  return (
    <div css={[t.flex_initial, t.flex, t.flex_col, t.p_6]}>
      <div css={[t.text_sm, t.text_tint_1]}>Assets</div>
      <SongProvider id={order.order.deliverable.songId}>
        <AdminOrderSong />
      </SongProvider>
    </div>
  );
};

const AdminOrderSong = () => {
  const order = useOrder();
  const song = useSong();
  //const [isVideoOpen, SetVideoOpen] = React.useState(false);

  const downloadVideoGreeting = React.useCallback(async () => {
    if (!order) return null;
    await AdminUseVideo(order.id, 'greeting', 'download');
  }, [order]);

  const downloadVideoPerformance = React.useCallback(async () => {
    if (!order) return null;
    await AdminUseVideo(order.id, 'performance', 'download');
  }, [order]);

  // function HandleClose(event: React.MouseEvent | React.KeyboardEvent) {
  //   event.stopPropagation();
  //   SetVideoOpen(false);
  // }

  // function HandleClick() {
  //   SetVideoOpen(true);
  // }

  if (!order) return null;
  if (order.order.type !== 'song') return null;

  if (!song) return null;
  if (song.state === 'requested') return null;

  const songOrder: SongOrder = order.order;

  return (
    <div css={[t.mt_1]}>
      <div css={[t.flex, t.my_2]}>
        <div css={[t.text_sm, t.text_tint_1, t.mr_2]}>{`Song #1:`}</div>
        <div>
          <AdminDetailsLinkButton to={`/admin/songs/${songOrder.deliverable.songId}/[]`} containerCSS={[t.mt_0]}>
            {songOrder.deliverable.songId}
          </AdminDetailsLinkButton>
        </div>
      </div>
      {songOrder.deliverable.videoGreetingId && (
        <div css={[t.flex, t.items_center]}>
          <div css={[t.text_sm, t.text_tint_1, t.mr_2]}>{`Video Greeting:`}</div>
          {/* <AdminButton type="button" label="View" styleType="secondary" onClick={HandleClick} /> */}
          <AdminButton type="button" label="Download" styleType="secondary" onClick={downloadVideoGreeting} />
        </div>
      )}
      {songOrder.deliverable.videoPerformanceId && (
        <div css={[t.flex, t.items_center]}>
          <div css={[t.text_sm, t.text_tint_1, t.mr_2]}>{`Video Performance:`}</div>
          {/* <AdminButton type="button" label="View" styleType="secondary" onClick={HandleClick} /> */}
          <AdminButton type="button" label="Download" styleType="secondary" onClick={downloadVideoPerformance} />
        </div>
      )}
      {/* <AdminVideoGreetingDialog isOpen={isVideoOpen} onClose={HandleClose} /> */}
    </div>
  );
};

// const AdminVideoGreetingDialog = (props: { isOpen: boolean; onClose: (event: React.MouseEvent | React.KeyboardEvent) => void }) => {
//   const order = useOrder();
//   if (!order) return null;

//   return <AdminDetailsDialog isOpen={props.isOpen} onRequestClose={props.onClose}></AdminDetailsDialog>;
// };
