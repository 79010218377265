import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { ReorderIcon } from '../../../icons/ReorderIcon';
import { CSSStyle } from '../../../theme/newstyles';

export type ShuffleDirection = 'up' | 'down';

export function AdminShuffleCard(props: {
  disabled?: boolean;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  onClick?: (event: React.MouseEvent) => void;
  children?: React.ReactNode;
  onShuffle: (direction: ShuffleDirection) => void;
  first?: boolean;
  last?: boolean;
  containerCSS?: CSSStyle;
}) {
  const interactiveStyle = props.onClick ? [t.cursor_pointer] : undefined;

  function HandleShuffleUp(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    props.onShuffle('up');
    event.stopPropagation();
  }

  function HandleShuffleDown(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    props.onShuffle('down');
    event.stopPropagation();
  }

  const upStyle = props.first ? t.invisible : null;
  const downStyle = props.last ? t.invisible : null;

  return (
    <div
      css={[
        t.relative,
        t.bg_white,
        t.border_b,
        t.border_solid,
        t.border_tint_2,
        t.px_2,
        t.py_2,
        interactiveStyle,
        t.flex,
        t.flex_row,
        t.hoverSelector(' #shuffleButtons', [t.visible]),
        props.containerCSS,
      ]}
    >
      <div id="shuffleButtons" css={[t.invisible, t.flex_none, t.text_tint_2, t.ml_1, t.mr_3, t.flex, t.flex_col, t.items_center, t.justify_center]}>
        <div css={[t.pb_2, t.cursor_pointer, t.hover(t.text_dark_1), upStyle]} onClick={HandleShuffleUp}>
          <ReorderIcon direction="up" />
        </div>
        <div css={[t.cursor_pointer, t.hover(t.text_dark_1), downStyle]} onClick={HandleShuffleDown}>
          <ReorderIcon direction="down" />
        </div>
      </div>

      <div css={[t.flex_auto, t.flex, t.flex_col]}>{props.children}</div>
    </div>
  );
}
