import React from 'react'; // eslint-disable-line
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { InfiniteHitsProvided, connectInfiniteHits } from 'react-instantsearch-core';

function CachedSearch(props: InfiniteHitsProvided & { pageBump: number; setResults: (results: { hits: any[]; hasMore: boolean }) => void }) {
  const [pageBump, setPageBump] = React.useState(props.pageBump);
  const refineNext = props.refineNext;
  React.useEffect(() => {
    if (pageBump !== props.pageBump) {
      refineNext();
      setPageBump(props.pageBump);
    }
  }, [props.pageBump, pageBump, refineNext]);

  const setResults = props.setResults;
  const hits = props.hits;
  const hasMore = props.hasMore;
  React.useEffect(() => {
    if (setResults) setResults({ hits, hasMore: hasMore });
  }, [hits, hasMore, setResults]);
  return <React.Fragment></React.Fragment>;
}

export default connectInfiniteHits(CachedSearch);
