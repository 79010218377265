import React from 'react'; // eslint-disable-line
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { InstantSearch } from 'react-instantsearch-dom';
import { Configure } from 'react-instantsearch-core';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

import ArtistSearchReducer from './reducer';
import { ArtistSearchControllerState, ArtistSearchState, ArtistPageSearchResult } from './types';
import HiddenSearchBox from '../../components/instantSearch/HiddenSearch';
import HiddenFilterList from '../../components/instantSearch/HiddenFilter';
import HiddenSortBy from '../../components/instantSearch/HiddenSort';
import CachedSearchResults from '../../components/instantSearch/CachedSearch';
import { getSortIndex } from './utils';
import createSearchClient from '../../util/CreateSearchClient';
import { defaultIndexName } from './constants';

// All queries will be made against the <env>_ARTISTPAGES index or one of its replicas
const searchClient = createSearchClient();

export const ArtistSearchContext = React.createContext<ArtistSearchControllerState>({} as ArtistSearchControllerState);

export function ArtistSearchProvider(props: { initialState?: Partial<ArtistSearchState>; withoutURLUpdates?: boolean; children: React.ReactNode }) {
  const location = useLocation<
    { packageTagFilters: string[]; genreFilters: string[]; instrumentFilters: string[]; searchText: string; notedFilter?: boolean } | undefined
  >();
  const history = useHistory(); // used to push location updates

  let defaultQuery = '';
  let defaultNotedFilter = false;
  let defaultGenreFilters: string[] = [];
  let defaultInstrumentFilters: string[] = [];
  let defaultPackageTagFilters: string[] = [];

  if (location.state) {
    // NOTE: it is unclear if location.state is ever defined... it has not been during local testing
    if (location.state.searchText) defaultQuery = location.state.searchText;
    if (location.state.notedFilter) defaultNotedFilter = location.state.notedFilter;
    if (location.state.genreFilters) defaultGenreFilters = location.state.genreFilters;
    if (location.state.instrumentFilters) defaultInstrumentFilters = location.state.instrumentFilters;
    if (location.state.packageTagFilters) defaultPackageTagFilters = location.state.packageTagFilters;
  } else {
    // If these values are set in query params use them, but first defer to the previously implemented location.state source
    if (!props.withoutURLUpdates) {
      const { query, genreFilters, instrumentFilters, packageTagFilters, notedFilter } = queryString.parse(location.search);
      if (query) defaultQuery = query as string;
      if (notedFilter) defaultNotedFilter = notedFilter === 'true';
      if (genreFilters) defaultGenreFilters = Array.isArray(genreFilters) ? genreFilters : [genreFilters];
      if (instrumentFilters) defaultInstrumentFilters = Array.isArray(instrumentFilters) ? instrumentFilters : [instrumentFilters];
      if (packageTagFilters) defaultPackageTagFilters = Array.isArray(packageTagFilters) ? packageTagFilters : [packageTagFilters];
    }
  }

  const [searchState, searchDispatch] = React.useReducer(
    ArtistSearchReducer,
    Object.assign(
      {
        hitsPerPage: 48,
        query: defaultQuery,
        pageBump: 0,
        genreFilters: defaultGenreFilters,
        instrumentFilters: defaultInstrumentFilters,
        packageTagFilters: defaultPackageTagFilters,
        availableFilters: ['true', 'false'],
        results: { hits: [], hasMore: false },
        sortIndex: 'featured',
        notedFilter: defaultNotedFilter,
      },
      props.initialState || {}
    )
  );

  const [state, setState] = React.useState<ArtistSearchControllerState>({} as ArtistSearchControllerState);

  const setHitsPerPage = React.useCallback((count: number) => searchDispatch({ type: 'setHitsPerPage', count }), []);
  const setSearchQuery = React.useCallback((query: string) => searchDispatch({ type: 'setSearchQuery', query }), []);
  const loadMoreResults = React.useCallback(() => searchDispatch({ type: 'loadMoreResults' }), []);
  const setResults = React.useCallback((results: ArtistPageSearchResult) => searchDispatch({ type: 'setResults', results }), []);
  const toggleGenre = React.useCallback((genre: string) => searchDispatch({ type: 'toggleGenre', genre }), []);
  const genreActive = React.useCallback((genre: string) => searchState.genreFilters.indexOf(genre) >= 0, [searchState.genreFilters]);
  const toggleInstrument = React.useCallback((instrument: string) => searchDispatch({ type: 'toggleInstrument', instrument }), []);
  const instrumentActive = React.useCallback((instrument: string) => searchState.instrumentFilters.indexOf(instrument) >= 0, [searchState.instrumentFilters]);
  const togglePackageTag = React.useCallback((packageTag: string) => searchDispatch({ type: 'togglePackageTag', packageTag }), []);
  const packageTagActive = React.useCallback((packageTag: string) => searchState.packageTagFilters.indexOf(packageTag) >= 0, [searchState.packageTagFilters]);
  const toggleNotedFilter = React.useCallback((forceState) => searchDispatch({ type: 'toggleNotedFilter', forceState }), []);
  const toggleUnavailable = React.useCallback(() => searchDispatch({ type: 'toggleUnavailable' }), []);
  const unavailableActive = React.useCallback(() => searchState.availableFilters.includes('false'), [searchState.availableFilters]);
  const setSortBy = React.useCallback((index: string) => searchDispatch({ type: 'setSortBy', index }), []);
  const clearAllFilters = React.useCallback(() => searchDispatch({ type: 'clearFilters' }), []);

  React.useEffect(() => {
    setState({
      state: searchState,
      results: searchState.results,
      setHitsPerPage,
      setSearchQuery,
      loadMoreResults,
      setResults,
      toggleGenre,
      genreActive,
      toggleInstrument,
      instrumentActive,
      togglePackageTag,
      packageTagActive,
      toggleNotedFilter,
      toggleUnavailable,
      unavailableActive,
      setSortBy,
      clearAllFilters,
    });
  }, [
    searchState,
    setHitsPerPage,
    setSearchQuery,
    loadMoreResults,
    setResults,
    toggleGenre,
    genreActive,
    toggleInstrument,
    instrumentActive,
    togglePackageTag,
    packageTagActive,
    toggleNotedFilter,
    toggleUnavailable,
    unavailableActive,
    setSortBy,
    clearAllFilters,
  ]);

  // Keep the query string up to date
  React.useEffect(() => {
    if (props.withoutURLUpdates) return;

    // read relevant values from the reducer
    const { query, genreFilters, instrumentFilters, packageTagFilters, notedFilter } = searchState;
    // Make a safe query value since its default is an empty string and results in "query="
    const safeQueryValue = query.length ? query : undefined;
    const safeNotedFilterValue = notedFilter ? notedFilter : undefined;
    // read the previous search value for comparison since the effect hook fires more than we need it to
    const previousQuery = location.search.replace('?', '');
    // Make a new query by destructuring existing params and adding the ones we care about
    // NOTE: don't assume we're the only producer or consumer of query strings!!!
    const updatedQuery = queryString.stringify({
      ...queryString.parse(previousQuery),
      query: safeQueryValue,
      genreFilters,
      instrumentFilters,
      packageTagFilters,
      notedFilter: safeNotedFilterValue,
    });

    if (updatedQuery !== previousQuery) {
      // only update the query if it changed
      history.replace({ search: updatedQuery });
    }
  }, [searchState, history, location.search, props.withoutURLUpdates]);

  const sortIndex = getSortIndex(searchState);

  return (
    <ArtistSearchContext.Provider value={state}>
      <InstantSearch searchClient={searchClient} indexName={defaultIndexName}>
        <Configure hitsPerPage={searchState.hitsPerPage} />
        <HiddenSortBy items={[]} value={sortIndex} />
        <HiddenSearchBox query={searchState.query} />
        <HiddenFilterList attribute="isNoted" value={searchState.notedFilter ? ['true'] : ['true', 'false']} />
        <HiddenFilterList attribute="genreTags" value={searchState.genreFilters} />
        <HiddenFilterList attribute="instrumentTags" value={searchState.instrumentFilters} />
        <HiddenFilterList attribute="packageTags" value={searchState.packageTagFilters} />
        <HiddenFilterList attribute="available" value={searchState.availableFilters} />
        <CachedSearchResults pageBump={searchState.pageBump} setResults={state.setResults} />
      </InstantSearch>
      {props.children}
    </ArtistSearchContext.Provider>
  );
}

export function useArtistSearch() {
  return React.useContext(ArtistSearchContext);
}

export default useArtistSearch;
