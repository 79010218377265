import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { CSSStyle } from '../../../theme/newstyles';

export const AdminTab = (props: {
  label: string;
  value?: number;
  selected?: boolean;
  orientation?: 'vertical' | 'horizontal';
  onChange?: (event: React.ChangeEvent<unknown>, newValue: number) => void;
  overrideStyle?: CSSStyle;
}) => {
  const HandleClick = (event: React.ChangeEvent<unknown>) => {
    if (!props.selected && props.onChange && props.value !== undefined) {
      props.onChange(event, props.value);
    }
  };

  return (
    <button
      css={[
        t.pl_4,
        t.pr_6,
        t.py_2,
        t.text_base,
        t.text_left,
        t.cursor_pointer,
        t.bg_transparent,
        t.border_0,
        props.selected
          ? [t.text_dark_1, props.orientation === 'vertical' ? t.border_r_2 : t.border_b_2, t.border_primary_4, t.border_solid]
          : [t.text_tint_2, t.border_0],
        t.hover(t.bg_tint_3),
        props.overrideStyle,
      ]}
      onClick={HandleClick}
    >
      {props.label}
    </button>
  );
};
