import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';
import { Button } from './Button';
import { SearchIcon } from '../icons/SearchIcon';
import { InputText } from './InputText';
import { ArtistSearchControllerState } from '../contexts/ArtistSearchContext/types';
import { CloseIcon } from '../icons/CloseIcon';

export const ExpandingSearchBox = (props: { discoverySearch: ArtistSearchControllerState; toggleSearchModal?: () => void }) => {
  const [currentSearchVal, setCurrentSearchVal] = React.useState(props.discoverySearch && props.discoverySearch.state ? props.discoverySearch.state.query : '');
  const [expanded, setExpanded] = React.useState(!!currentSearchVal);
  const [showButton, setShowButton] = React.useState(false);

  const HandleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      props.discoverySearch.setSearchQuery(event.currentTarget.value);
      if (props.toggleSearchModal) props.toggleSearchModal();
    }
  };

  const HandleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCurrentSearchVal('');
    props.discoverySearch.setSearchQuery('');
    setShowButton(false);
  };

  const HandleSearchBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    props.discoverySearch.setSearchQuery(event.target.value);
    if (!currentSearchVal) {
      setExpanded(false);
    }
  };

  const HandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentSearchVal(event.target.value);
    if (currentSearchVal) {
      setShowButton(true);
    }
  };

  return (
    <div css={[t.w_full, t.max_w('320px')]}>
      <div css={[t.hidden, t.md([!expanded ? t.block : t.hidden])]}>
        <Button type="button" onClick={() => setExpanded(!expanded)} icon={<SearchIcon />} styleType="secondary" />
      </div>
      <div css={[t.md([expanded ? t.block : t.hidden]), t.relative]}>
        <InputText
          id="search"
          name="search"
          type="text"
          icon={<SearchIcon css={[t.text_tint_1]} />}
          placeholder="Search artist"
          label="search"
          labelOffscreen="search"
          onKeyPress={(event) => HandleKeyPress(event)}
          onBlur={(event) => HandleSearchBlur(event)}
          value={currentSearchVal}
          onChange={HandleChange}
          inputStyle={[t.pr('54px')]}
        />
        <div css={[t.hidden, t.md([showButton ? t.block : t.hidden, t.absolute, t.inset_y_0, t.right_0, t.flex, t.justify_center, t.items_center])]}>
          <button
            css={[
              t.cursor_pointer,
              t.z_10,
              t.text_tint_5,
              t.hover([t.bg_primary_3]),
              t.active([t.bg_primary_3]),
              t.disabled([t.text_tint_2, t.cursor_not_allowed]),
              t.bg_tint_2,
              t.mr_4,
              t.w_5,
              t.h_5,
              t.flex,
              t.justify_center,
              t.items_center,
            ]}
            onClick={HandleButtonClick}
          >
            <CloseIcon css={[t.size('18px')]} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExpandingSearchBox;
