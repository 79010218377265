import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { ShuffleDirection } from './AdminShuffleCard';
import { ReorderIcon } from '../../../icons/ReorderIcon';
import { AdminActionsButton } from './AdminActionButton';
import { MoreDotsIcon } from '../icons/MoreDotsIcons';
import { AdminActionsPopover } from './AdminActionsPopover';

const headerIconSize = css`
  width: 16px;
  height: 16px;
`;

export const AdminCardHeader = (props: {
  first?: boolean;
  last?: boolean;
  onShuffle?: (direction: ShuffleDirection) => void;
  actionsChildren?: React.ReactNode;
  children?: React.ReactNode;
}) => {
  const [actionsPopover, SetActionsPopover] = React.useState(false);
  const [popoverRect, SetPopoverRect] = React.useState<DOMRect | undefined>(undefined);

  function HandleShuffleUp(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (props.onShuffle) props.onShuffle('up');
    event.stopPropagation();
  }

  function HandleShuffleDown(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (props.onShuffle) props.onShuffle('down');
    event.stopPropagation();
  }

  const handleActionsClick = (rect: DOMRect | undefined, event: React.MouseEvent) => {
    SetActionsPopover(true);
    SetPopoverRect(rect);
  };

  const handleActionsClose = () => {
    SetActionsPopover(false);
  };

  const upStyle = props.first ? t.invisible : null;
  const downStyle = props.last ? t.invisible : null;

  return (
    <div css={[t.flex, t.flex_row, t.border_b, t.border_solid, t.border_tint_2, t.pt_3, t.pb_4]}>
      {props.onShuffle ? (
        <div id="shuffleButtons" css={[t.visible, t.flex_none, t.text_tint_2, t.ml_3, t.flex, t.flex_col, t.items_center, t.justify_center]}>
          <div css={[t.pb_2, t.cursor_pointer, t.hover(t.text_dark_1), upStyle]} onClick={HandleShuffleUp}>
            <ReorderIcon direction="up" />
          </div>
          <div css={[t.cursor_pointer, t.hover(t.text_dark_1), downStyle]} onClick={HandleShuffleDown}>
            <ReorderIcon direction="down" />
          </div>
        </div>
      ) : null}
      {props.children}

      {props.actionsChildren ? (
        <div css={[t.mr_4, t.flex, t.justify_center]}>
          <AdminActionsButton onClick={handleActionsClick}>
            <div css={[t.flex, t.flex_row, t.items_center]}>
              <MoreDotsIcon css={[headerIconSize]} />
            </div>
          </AdminActionsButton>
        </div>
      ) : null}
      {actionsPopover ? (
        <AdminActionsPopover anchor="right" positionRect={popoverRect} onClose={handleActionsClose}>
          {props.actionsChildren}
        </AdminActionsPopover>
      ) : null}
    </div>
  );
};
