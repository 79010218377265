import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';

export const AdminPreviousTrackIcon = (props: unknown) => {
  return (
    <svg role="img" aria-labelledby="previousTrackIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <title id="previousTrackIconTitleID">Previous Track</title>
      <rect x="4" y="4" width="1" height="16" css={[t.fill_current]} />
      <path fillRule="evenodd" clipRule="evenodd" d="M20 5L8 12L20 19L20 5ZM19 17.259L19 6.74104L9.98463 12L19 17.259Z" css={[t.fill_current]} />
    </svg>
  );
};
