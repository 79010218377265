import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { AdminTextFieldLabel } from './AdminTextFieldLabel';
import { AdminTextInputProps } from './AdminTextField';

export const AdminTextArea = (
  props: {
    id: string;
    name: string;
    value?: string | number | string[];
    defaultValue?: string | number | string[];
    outlined?: boolean;
    required?: boolean;
    invalid?: boolean;
    maxLength?: number;
    rows?: number;
    cols?: number;
    characterCount?: number;
    optional?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onBlur: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  } & AdminTextInputProps
) => {
  let borderStyle = [t.border_tint_2, t.hover(t.border_dark_1)];
  if (props.disabled) borderStyle = [t.border_tint_2];
  if (props.invalid) borderStyle = [t.border_error_1];

  return (
    <div css={[t.max_w('300px'), t.flex, t.flex_col]}>
      {props.label && (
        <AdminTextFieldLabel
          label={props.label}
          description={props.description}
          id={props.id}
          optional={props.optional}
          labelOffscreen={props.labelOffscreen}
        />
      )}
      <textarea
        css={[
          t.bg_transparent,
          t.border_solid,
          t.border_1,
          borderStyle,
          t.resize_y,
          t.p_4,
          t.text_sm,
          t.text_dark_1,
          t.placeholder(t.text_tint_2),
          t.disabled(t.bg_tint_4),
          t.focus([t.border_primary_4, t.outline_none]),
        ]}
        id={props.id}
        name={props.name}
        value={props.value}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder ? props.placeholder : ''}
        disabled={props.disabled}
        required={props.required}
        aria-required={props.required}
        aria-disabled={props.disabled}
        aria-describedby={props.id}
        aria-invalid={props.invalid}
        onChange={props.onChange}
        onBlur={props.onBlur}
        wrap={'soft'}
        maxLength={props.maxLength}
        rows={props.rows}
        cols={props.cols}
      />
      {props.maxLength ? <div css={[t.text_xs, t.mt_2]}>{`${props.characterCount}/${props.maxLength} characters used`}</div> : null}
    </div>
  );
};
