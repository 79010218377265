import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import t from '../../theme/newstyles';
import { Toast } from './Toast';
import { ToastController, useToastController } from '../../contexts/ToastContext';

const headerLocation = css`
  top: 56px;
`;

const headerHeight = css`
  height: 56px;
`;

export const ToastHeader = (props: unknown) => {
  const toastController: ToastController = useToastController();
  if (toastController.toasts.length === 0) return null;

  const shownToast = toastController.toasts[toastController.toasts.length - 1];
  return (
    <div
      css={[
        t.bg_transparent,
        t.fixed,
        headerLocation,
        headerHeight,
        t.left_0,
        t.right_0,
        t.z_30,
        t.w_full,
        css`
          z-index: 90;
        `,
      ]}
    >
      <div css={[t.flex, t.justify_center, t.items_end, t.pt_3]}>
        <Toast toastType={shownToast.type} toastMessage={shownToast.message} />
      </div>
    </div>
  );
};
