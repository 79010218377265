import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';

export const SearchIcon = (props: unknown) => {
  return (
    <svg aria-labelledby="searchIcon" width="21" height="20" viewBox="0 0 21 20" fill="none" {...props}>
      <title id="searchIcon">Search Icon</title>
      <circle cx="8" cy="8" r="7.5" css={[t.stroke_current]} />
      <rect css={[t.fill_current]} width="0.996311" height="9.14285" transform="matrix(0.707131 -0.707083 0.707131 0.707083 12.832 13.5354)" />
    </svg>
  );
};

export default SearchIcon;
