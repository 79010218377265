import { css } from '@emotion/core';

export const lineHeight = {
    leading_none: css`
        line-height: 1;
    `,
    leading_tight: css`
        line-height: 1.25;
    `,
    leading_snug: css`
        line-height: 1.375;
    `,
    leading_normal: css`
        line-height: 1.5;
    `,
    leading_relaxed: css`
        line-height: 1.625;
    `,
    leading_loose: css`
        line-height: 2;
    `,
};
