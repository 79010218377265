import { css } from '@emotion/core';

export const fontFamily = {
  font_sans: css`
    font-family: 'Soehne', 'Helvetica Neue', 'Arial', sans-serif;
  `,
  font_mono: css`
    font-family: 'Pitch', 'Courier New', monospace;
  `,
};
