import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';

export const AdminSearchTableCell = (props: { center?: boolean; children?: React.ReactNode }) => {
  return (
    <td css={[t.relative, t.border_b, t.border_tint_3, t.border_solid]}>
      <div css={[t.truncate, props.center ? [t.flex, t.justify_center, t.px_3] : [t.pl_6, t.pr_0], t.py_3, t.text_tint_1]}>{props.children}</div>
    </td>
  );
};
