import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';
import { motion } from 'framer-motion';

export const AddIcon = (props: unknown) => {
  return (
    <motion.svg aria-labelledby="addIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <title id="addIconTitleID">Add Icon</title>
      <rect x="12" y="4" width="1" height="15" css={[t.fill_current]} />
      <rect x="5" y="11" width="15" height="1" css={[t.fill_current]} />
    </motion.svg>
  );
};
