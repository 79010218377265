import React from 'react'; // eslint-disable-line
import { motion } from 'framer-motion';

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import t, { CSSStyle } from '../theme/newstyles';
import NotedBadgePNG54 from '../icons/noted_badge_54.png';
import NotedBadgePNG64 from '../icons/noted_badge_64.png';
import { Tip, TipProps } from './tips/Tip';
import { CloseIcon } from '../icons/CloseIcon';

function Tooltip(props: {
  visible?: boolean;
  direction?: string;
  children?: React.ReactNode;
  onClose?: (event: React.MouseEvent) => void;
  containerStyle?: CSSStyle;
}) {
  if (!props.visible) return null;
  // default is centered
  let outerPosition: CSSStyle = [t.inset_x_0, t.top_0];
  let innerPosition: CSSStyle = [t.bottom_0, t.right('-124px'), t.mb_3];

  switch (props.direction) {
    case 'left': {
      outerPosition = [t.inset_x_0, t.top_0];
      innerPosition = [t.bottom_0, t.right_0, t.mb_3];
      break;
    }
    case 'right': {
      outerPosition = [t.inset_x_0, t.top_0];
      innerPosition = [t.bottom_0, t.left_0, t.mb_3];
      break;
    }
  }

  return (
    <div css={[t.absolute, outerPosition, t.z_80]}>
      <motion.div
        initial={{ y: 60, clipPath: 'inset(25% 0% 75% 0%)' }}
        animate={{ y: 0, clipPath: 'inset(0% 0% 0% 0%)' }}
        transition={{ duration: 0.6, ease: [0.13, 1.0, 0.34, 1.0] }}
        css={[t.absolute, t.w('294px'), t.flex, t.justify_center, t.max_w('620px'), t.mx_auto, innerPosition, props.containerStyle]}
      >
        <div css={[t.relative, t.bg_color('#7738DF'), t.text_tint_5]}>
          <div css={[t.px_5, t.pt_4, t.pb_5]}>{props.children}</div>
          <div css={[t.absolute, t.top_0, t.right_0, t.mt_4, t.mr_5]}>
            <button css={[t.bg_transparent, t.outline_none, t.cursor_pointer, t.text_tint_5]} onClick={props.onClose}>
              <CloseIcon />
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

const ICON_SIZES: { [key: string]: number[] } = {
  xs: [35, 35],
  sm: [37, 37],
  md: [54, 54],
  lg: [64, 64],
};

function TooltipTriggerButton(props: {
  visible?: boolean;
  iconSize: string;
  onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void;
  disabled?: boolean;
}) {
  // const [focus, setFocus] = React.useState(false);
  const iconSize = props.iconSize || 'md';
  const iconSource = iconSize === 'lg' ? NotedBadgePNG64 : NotedBadgePNG54;

  const [iconWidth, iconHeight] = ICON_SIZES[iconSize] as number[];

  return (
    <button
      type="button"
      css={[
        t.flex,
        t.items_center,
        t.justify_center,
        t.size('40px'),
        t.textSize('9px', '16px'),
        t.font_medium,
        t.rounded_full,
        props.disabled ? t.cursor_default : t.cursor_pointer,
        t.bg_transparent,
        t.hover([t.border_primary_4, props.visible ? t.text_tint_5 : t.text_primary_4]),
        // focus ? (props.visible ? [t.bg_primary_3, t.border_primary_3, t.text_tint_5] : [t.border_primary_4, t.text_primary_4]) : null,
      ]}
      onClick={props.onClick}
      disabled={props.disabled}
      // onFocus={() => setFocus(true)}
      // onBlur={() => setFocus(false)}
    >
      <img src={iconSource} width={iconWidth} height={iconHeight} alt="noted artist" />
    </button>
  );
}

export function TooltipCaret(props: { visible?: boolean; direction?: 'Up' | 'Down' | 'Left' | 'Right' }) {
  if (!props.visible) return null;

  const rotate = css`
    transform: rotateZ(45deg);
  `;

  const innerY = -10;

  const outerPosition = [t.inset_x_0, t.top_0];
  const innerPosition = [t.bottom_0, t.mb('9px')];
  return (
    <div css={[t.absolute, t.flex, t.justify_center, outerPosition]}>
      {/* <div css={[t.absolute, innerPosition]}>
        <div css={[t.bg_color('#7738DF'), t.w_5, t.h_5, rotate]} />
      </div> */}
      <motion.div css={[t.absolute, innerPosition]}>
        <motion.div
          initial={{ scale: 0.1, y: innerY, opacity: 0 }}
          animate={{ scale: 1, y: 0, opacity: 1 }}
          transition={{ duration: 0.55, delay: 0.1, ease: [0.13, 1.0, 0.34, 1.0] }}
        >
          <motion.div css={[t.bg_color('#7738DF'), t.w_5, t.h_5, rotate]} />
        </motion.div>
      </motion.div>
    </div>
  );
}

export function NotedBadgeAndTooltip(props: {
  label: string;
  description: string;
  iconSize: string;
  tooltipDirection?: string;
  closeOnScroll?: boolean;
  children?: React.ReactNode;
  suppressTooltips?: boolean;
}) {
  return (
    <Tip closeOnScroll={props.closeOnScroll}>
      {(tipProps: TipProps) => (
        <React.Fragment>
          <div css={[t.relative]}>
            <TooltipTriggerButton visible={tipProps.isOpen} onClick={tipProps.toggleOpen} iconSize={props.iconSize} disabled={props.suppressTooltips} />
            <TooltipCaret visible={tipProps.isOpen} />
            <Tooltip direction={props.tooltipDirection} visible={tipProps.isOpen} onClose={tipProps.toggleOpen}>
              <div css={[t.typeStyle_md8, t.my_1]}>{props.label}</div>
              <span css={[t.typeStyle_md7]}>{props.description}</span>
              {props.children}
            </Tooltip>
          </div>
        </React.Fragment>
      )}
    </Tip>
  );
}

export default NotedBadgeAndTooltip;
