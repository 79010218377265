import React from 'react'; // eslint-disable-line
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { connectSortBy } from 'react-instantsearch-core';
// This allows you to change the sort index being queried. Indicies are defined in the Algolia dashboard.
// NOTE: If you add a new replica index to sort by be sure to also grant public access to it
// by updating the relevant public API keys in the Algolia dashboard.
function HiddenSort(props: { defaultRefinement: string; value: string; currentRefinement: string; refine: (refinement: string) => void }) {
  const value = props.value;
  const refinement = props.currentRefinement;
  const refine = props.refine;
  React.useEffect(() => {
    if (value !== refinement) refine(value);
  }, [value, refinement, refine]);
  return <React.Fragment></React.Fragment>;
}

export default connectSortBy(HiddenSort);
