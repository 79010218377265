import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import { motion } from 'framer-motion';
import t, { CSSStyle } from '../../theme/newstyles';
import { withAdminAccount } from '../../hocs/withAdminAccount';
import { InstrumentTagDictionary } from '../../data/ProfileData';
import { MainContainer } from '../../components/MainContainer';
import { Collage } from '../../components/Collage';
import { TestArtist } from './TestArtistCard';
import { useFormikContext } from 'formik';
import { MotionCollectionForm } from './AnimationTestContext';
import { AnimationTokens } from './AnimationTestData';
import { DisplayTag } from '../../components/DisplayTag';
import { CollageImage, CollageImageProps } from '../../components/CollageImage';

const AnimationTestArtistPageInfoComponent = (props: { artist: TestArtist }) => {
  const { values } = useFormikContext<MotionCollectionForm>();
  const tokens = values.collections[values.active].artistPage;

  const genreElements: JSX.Element[] = [];
  for (let i = 0; i < props.artist.genreTags.length; i++) {
    const genre = props.artist.genreTags[i];
    genreElements.push(
      <motion.div
        {...AnimationTokens[tokens.genres.tokenKey]}
        custom={t.override({ delay: 0.4 + i * 0.14 })}
        css={[t.mr_3, t.mb_3, t.lg([t.mr_0, t.ml_3])]}
        key={i}
      >
        <DisplayTag label={genre} />
      </motion.div>
    );
  }

  let instruments: string[] = [];
  if (props.artist.instrumentTags) {
    instruments = props.artist.instrumentTags.map((value) => {
      return InstrumentTagDictionary[value];
    });
  }

  let profilePhotos = [props.artist.photoURL];
  if (props.artist.additionalImages) profilePhotos = profilePhotos.concat(props.artist.additionalImages);

  const images: React.ReactElement<CollageImageProps>[] = [];
  for (let i = 0; i < profilePhotos.length; i++) {
    images.push(
      <CollageImage
        key={`profileImage${i}`}
        imageCount={profilePhotos.length}
        src={profilePhotos[i]}
        alt={i === 0 ? 'Artist Portrait' : 'Secondary Artist Portrait'}
      />
    );
  }

  return (
    <MainContainer containerCSS={[t.mt_6, t.mb_7, t.sm([t.mb_9]), t.lg([t.mt_7])]}>
      <div css={[t.breakpointGrid_12]}>
        <motion.h1 {...AnimationTokens[tokens.h1.tokenKey]} css={[t.col_span_12, t.gridTypeStyle_1, t.text_dark_1, t.lg(t.col_span_8)]}>
          {props.artist.name}
        </motion.h1>
        <div css={[t.col_span_12, t.mt_5, t.lg([t.mt_3, t.col_span_4])]}>
          <div css={[t.flex, t.flex_row, t.flex_wrap, t.lastType('div', [t.mr_0]), t.lg([t.firstType('div', [t.ml_0]), t.justify_end])]}>{genreElements}</div>
        </div>
      </div>
      <div css={[t.breakpointGrid_12, t.mt_6, t.sm(t.mt_7), t.md(t.mt_6)]}>
        <div css={[t.sm([t.col_span_12]), t.md(t.col_span_10), t.lg([t.col_span_6, t.col_start_7]), t.xl([t.col_span_5, t.col_start_8])]}>
          <Collage images={profilePhotos}>
            {(imageProps: CollageImageProps) => {
              return (
                <CollageImage
                  src={imageProps.src}
                  index={imageProps.index}
                  focusIndex={imageProps.focusIndex}
                  imageCount={imageProps.imageCount}
                  alt={imageProps.index === 0 ? 'Artist Portrait' : 'Secondary Artist Portrait'}
                  motionToken={AnimationTokens[tokens.collage.tokenKey]}
                />
              );
            }}
          </Collage>
        </div>
        <motion.div
          css={[
            t.flex,
            t.flex_col,
            t.mt_6,
            t.sm([t.col_span_10, t.col_start_2, t.mt_7]),
            t.md([t.col_span_12, t.breakpointGrid_12]),
            t.lg([t.mt_0, t.col_span_5, t.flex]),
          ]}
          {...AnimationTokens[tokens.info.tokenKey]}
          custom={t.override(tokens.info.transition ? tokens.info.transition : {})}
        >
          <div css={[t.flex_none, t.md([t.col_span_5, t.col_start_8]), t.lg(t.breakpointGrid_5)]}>
            <div css={[t.col_span_4, t.firstType('div', t.mt_0)]}>
              {props.artist.relatedProjects && props.artist.relatedProjects.length !== 0 ? (
                <InfoSection label="RELATED PROJECTS">{props.artist.relatedProjects.join(', ')}</InfoSection>
              ) : null}
              {instruments.length !== 0 ? (
                <InfoSection label="INSTRUMENTS" containerCSS={[t.mt_6]}>
                  {instruments.join(', ')}
                </InfoSection>
              ) : null}
            </div>
          </div>

          <div css={[t.flex_auto, t.flex, t.flex_col, t.md([t.row_start_1, t.col_span_6, t.col_start_1]), t.lg(t.justify_end)]}>
            <div css={[t.xl(t.breakpointGrid_5)]}>
              <div css={[t.xl(t.col_span_4)]}>
                <InfoSection
                  label="ARTIST BIO"
                  containerCSS={[t.mt_6, t.col_span_5, t.justify_start, t.md(t.mt_0), t.lg([t.mt_6, t.justify_end]), t.xl(t.col_span_4)]}
                >
                  {props.artist.shortBio}
                </InfoSection>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </MainContainer>
  );
};

const InfoSection = (props: { label: string; children?: React.ReactNode; containerCSS?: CSSStyle }) => {
  return (
    <div css={[t.flex, t.flex_col, props.containerCSS]}>
      <h2 css={[t.typeStyle_mono, t.mb_2, t.md(t.mb_3), t.text_dark_2]}>{props.label}</h2>
      <div css={[t.gridTypeStyle_6, t.text_dark_1]}>{props.children}</div>
    </div>
  );
};

export const AnimationTestArtistPageInfo = withAdminAccount(AnimationTestArtistPageInfoComponent);
