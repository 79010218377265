import * as React from 'react';
import { useRouteMatch, Redirect } from 'react-router-dom';

import { useAccount } from '../contexts/AccountContext';
// import { VerifyPage } from '../pages/account/verify';
import { NotFound } from '../pages/errors/NotFound';

export function withAdminAccount<T>(WrappedComponent: (props: T) => JSX.Element | null) {
  const WrappingComponent = (props: T) => {
    const account = useAccount();
    const { path } = useRouteMatch();
    if (account === undefined) return null;
    if (!account) return <Redirect to={{ pathname: '/admin/signin', state: { returnURL: path } }} />;
    if (!account.Claims.isAdmin) return <NotFound />;
    // if (!account.User.emailVerified) return <VerifyPage />;
    return <WrappedComponent {...props} />;
  };

  return WrappingComponent;
}
