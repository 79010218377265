import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';

export const AdminEventList = (props: { children?: React.ReactNode }) => {
  return (
    <div css={[t.flex_auto, t.relative]}>
      <div css={[t.flex, t.flex_col]}>
        <div css={[t.flex, t.flex_col, t.mt_4, t.px_6, t.pb_6, t.overflow_y_auto]}>
          <div css={[t.bg_white, t.text_tint_1, t.shadow]}>{props.children}</div>
        </div>
      </div>
    </div>
  );
};
