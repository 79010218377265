import { css } from '@emotion/core';
import { fontSize, lineHeight, textSize } from './utilities';

export const text = {
  fontSize: fontSize,
  lineHeight: lineHeight,
  textSize: textSize,

  text_0: css`
    font-size: 14px;
    line-height: 20px;
  `,
  text_1: css`
    font-size: 14px;
    line-height: 24px;
  `,
  text_2: css`
    font-size: 16px;
    line-height: 24px;
  `,
  text_3: css`
    font-size: 16px;
    line-height: 28px;
  `,
  text_4: css`
    font-size: 17px;
    line-height: 28px;
  `,
  text_5: css`
    font-size: 18px;
    line-height: 28px;
  `,
  text_6: css`
    font-size: 20px;
    line-height: 28px;
  `,
  text_7: css`
    font-size: 22px;
    line-height: 28px;
  `,
  text_8: css`
    font-size: 24px;
    line-height: 32px;
  `,
  text_9: css`
    font-size: 27px;
    line-height: 32px;
  `,
  text_10: css`
    font-size: 30px;
    line-height: 32px;
  `,
  text_11: css`
    font-size: 33px;
    line-height: 40px;
  `,
  text_12: css`
    font-size: 36px;
    line-height: 40px;
  `,
  text_13: css`
    font-size: 40px;
    line-height: 48px;
  `,
  text_14: css`
    font-size: 44px;
    line-height: 48px;
  `,
  text_15: css`
    font-size: 48px;
    line-height: 56px;
  `,
  text_16: css`
    font-size: 54px;
    line-height: 56px;
  `,
  text_17: css`
    font-size: 60px;
    line-height: 64px;
  `,
  text_18: css`
    font-size: 66px;
    line-height: 72px;
  `,
  text_19: css`
    font-size: 72px;
    line-height: 72px;
  `,
  text_20: css`
    font-size: 80px;
    line-height: 80px;
  `,
  text_21: css`
    font-size: 88px;
    line-height: 88px;
  `,
  text_22: css`
    font-size: 96px;
    line-height: 96px;
  `,
};
