import React from 'react'; // eslint-disable-line
import { motion } from 'framer-motion';

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t, { CSSStyle } from '../../theme/newstyles';
import { LogoLink } from '../LogoLink';

export const WizardHeader = (props: { label?: string; progress?: number; logoLockup?: boolean; children?: React.ReactNode; headerStyle?: CSSStyle }) => {
  return (
    <header
      css={[
        t.relative,
        t.flex,
        t.flex_row,
        t.w_full,
        t.fixed,
        t.top_0,
        t.z_20,
        t.px_5,
        t.min_h('56px'),
        t.bg_tint_5,
        t.pt_4,
        t.pb_3,
        t.md([t.px('6vw')]),
        props.headerStyle,
      ]}
    >
      <div css={[t.max_w_page, t.mx_auto, t.w_full, t.flex]}>
        <div css={[t.flex, t.flex_row, t.w_full]}>
          <div css={[t.flex, t.flex_row, t.items_stretch, t.flex_none, t.justify_center, t.mr_3, t.lg([t.justify_start])]}>
            <LogoLink to="/" lockup={props.logoLockup} />
          </div>
          {props.label && <div css={[t.flex_none, t.pl_3, t.border_l, t.border_solid, t.border_tint_2, t.typeStyle_lg6]}>{props.label}</div>}
          <div css={[t.flex, t.flex_row, t.flex_auto]}>{props.children}</div>
          <div css={[t.h_px, t.absolute, t.inset_x_0, t.bottom_0, t.bg_tint_3, t.mb('-1px')]} />
          {props.progress && props.progress > 0 ? (
            <motion.div
              css={[t.h_px, t.absolute, t.inset_x_0, t.bottom_0, t.bg_primary_4, t.mb('-1px')]}
              initial={{ x: '-100%' }}
              animate={{ x: `${props.progress - 100}%` }}
              transition={{ duration: 0.34 }}
            />
          ) : null}
        </div>
      </div>
    </header>
  );
};
