import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';

export const SuccessCheck = (props: {}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M10.2898 16.41L6.58984 12.34L7.32984 11.66L10.3298 14.92L16.5898 8L17.3298 8.67L10.2898 16.41Z" css={[t.stroke_current]} />
      <circle cx="12" cy="12" r="11.5" css={[t.stroke_current]} />
    </svg>
  );
};
