import React, { FocusEvent } from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';

export function StaticStarIcon<T>(props: { shouldFill: boolean; starSize: string; shouldBeOpaque?: boolean }) {
  const { shouldFill, starSize, shouldBeOpaque } = props;

  switch (starSize) {
    case 'sm':
      return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.2315 0L10.7087 5.27205L16.0601 6.21885L12.2398 10.424L13.0699 16.2812L8.2315 13.608L3.39315 16.2812L4.22324 10.424L0.402878 6.21885L5.75426 5.27205L8.2315 0Z"
            fill={shouldFill ? '#1163FF' : '#B8B2AC'}
          />
        </svg>
      );
    case 'md':
      return (
        <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.5 0L17.5628 7.90807L26.3393 9.32827L20.0737 15.6359L21.4351 24.4217L13.5 20.412L5.5649 24.4217L6.9263 15.6359L0.660737 9.32827L9.43723 7.90807L13.5 0Z"
            fill={shouldFill ? '#1163FF' : `${shouldBeOpaque ? '#1163FF' : '#B8B2AC'}`}
            opacity={shouldBeOpaque ? '0.2' : '1'}
          />
        </svg>
      );
    case 'md_half':
      return (
        <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient id="md_half">
              <stop offset="50%" stopColor="#1163FF" />
              <stop offset="50%" stopColor="#1163FF" stopOpacity="0.2" />
            </linearGradient>
          </defs>
          <path
            d="M13.5 0L17.5628 7.90807L26.3393 9.32827L20.0737 15.6359L21.4351 24.4217L13.5 20.412L5.5649 24.4217L6.9263 15.6359L0.660737 9.32827L9.43723 7.90807L13.5 0Z"
            fill="url(#md_half)"
          />
        </svg>
      );
    case 'lg':
      return (
        <svg width="38" height="33" viewBox="0 0 38 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19 0L24.718 10.5441L37.0701 12.4377L28.2519 20.8479L30.1679 32.5623L19 27.216L7.83208 32.5623L9.74812 20.8479L0.929926 12.4377L13.282 10.5441L19 0Z"
            fill={shouldFill ? '#1163FF' : `${shouldBeOpaque ? '#1163FF' : '#B8B2AC'}`}
            opacity={shouldBeOpaque ? '0.2' : '1'}
          />
        </svg>
      );
    case 'lg_half':
      return (
        <svg width="38" height="33" viewBox="0 0 38 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient id="lg_half">
              <stop offset="50%" stopColor="#1163FF" />
              <stop offset="50%" stopColor="#1163FF" stopOpacity="0.2" />
            </linearGradient>
          </defs>
          <path
            d="M19 0L24.718 10.5441L37.0701 12.4377L28.2519 20.8479L30.1679 32.5623L19 27.216L7.83208 32.5623L9.74812 20.8479L0.929926 12.4377L13.282 10.5441L19 0Z"
            fill="url(#lg_half)"
          />
        </svg>
      );
    default:
      return (
        <svg width="38" height="33" viewBox="0 0 38 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19 0L24.718 10.5441L37.0701 12.4377L28.2519 20.8479L30.1679 32.5623L19 27.216L7.83208 32.5623L9.74812 20.8479L0.929926 12.4377L13.282 10.5441L19 0Z"
            fill={shouldFill ? '#1163FF' : '#B8B2AC'}
          />
        </svg>
      );
  }
}
