import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';

export const AdminFilterIcon = (props: unknown) => {
  return (
    <svg width="56.13" height="37.81" viewBox="0 0 56.13 37.81" fill="none" {...props}>
      <rect css={[t.fill_current]} width="56.13" height="5.82" />
      <rect css={[t.fill_current]} x="11" y="16" width="34.14" height="5.82" />
      <rect css={[t.fill_current]} x="20.93" y="33.47" width="14.27" height="5.82" />
    </svg>
  );
};
