import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';
import { motion } from 'framer-motion';

export const ArrowIcon = (props: { direction?: 'down' | 'left' | 'right' | 'up' }) => {
  const { direction, ...rest } = props;
  const dir = direction ? direction : 'up';
  switch (dir) {
    case 'down':
      return (
        <motion.svg aria-labelledby="downArrow" width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
          <title id="downArrow">Down Arrow Icon</title>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.0001 21.2652L18.1337 14.6598L18.8665 15.3402L12.0001 22.7348L5.13366 15.3402L5.86645 14.6598L12.0001 21.2652Z"
            css={[t.fill_current]}
          />
          <rect x="12.5" y="2" width="20" height="1" transform="rotate(90 12.5 2)" css={[t.fill_current]} />
        </motion.svg>
      );
    case 'left':
      return (
        <motion.svg aria-labelledby="leftArrow" width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
          <title id="leftArrow">Left Arrow Icon</title>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.73491 11.5001L9.34033 17.6337L8.65988 18.3665L1.2653 11.5001L8.65988 4.63366L9.34033 5.36645L2.73491 11.5001Z"
            css={[t.fill_current]}
          />
          <rect x="22" y="12" width="20" height="1" transform="rotate(180 22 12)" css={[t.fill_current]} />
        </motion.svg>
      );
    case 'right':
      return (
        <motion.svg aria-labelledby="rightArrow" width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
          <title id="rightArrow">Right Arrow Icon</title>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.2651 11.4999L14.6597 5.36634L15.3401 4.63354L22.7347 11.4999L15.3401 18.3663L14.6597 17.6335L21.2651 11.4999Z"
            css={[t.fill_current]}
          />
          <rect x="2" y="11" width="20" height="1" css={[t.fill_current]} />
        </motion.svg>
      );
    default:
      return (
        <motion.svg aria-labelledby="upArrow" width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
          <title id="upArrow">Up Arrow Icon</title>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9999 2.73479L5.86634 9.34021L5.13354 8.65976L11.9999 1.26518L18.8663 8.65976L18.1335 9.34021L11.9999 2.73479Z"
            css={[t.fill_current]}
          />
          <rect x="11.5" y="22" width="20" height="1" transform="rotate(-90 11.5 22)" css={[t.fill_current]} />
        </motion.svg>
      );
  }
};
