import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { FormCheckbox } from '../../../components/FormCheckbox';
import { TextField } from '../../../components/TextField';
// import { AdminCheckbox } from '../components/AdminCheckbox';
import { Form, Formik, FormikProps } from 'formik';
import { AdminButton } from '../components/AdminButton';
import { FirebaseObjects } from '../../../contexts/FirebaseContext';
import { ArtistNotedData, useArtistProfile } from '../../../contexts/ArtistProfileContext';

export const AdminArtistDetailsNoted = (props: { id: string }) => {
  const [isRequestInFlight, setIsRequestInFlight] = React.useState(false);

  const artist = useArtistProfile();
  const notedData = artist.Profile?.notedData;

  if (!notedData) return null;

  const handleSubmit = async (formData: Partial<ArtistNotedData>) => {
    setIsRequestInFlight(true);
    try {
      await FirebaseObjects.adminRequest({
        action: 'editArtistNotedData',
        data: { artistNotedData: formData, id: props.id },
      });
      setIsRequestInFlight(false);
    } catch (error) {
      setIsRequestInFlight(false);
      console.log('ERROR', error);
    }
  };

  return (
    <div css={[t.border_b, t.border_solid, t.border_tint_3, t.pt_5, t.pb_6, t.pl_6, t.flex, t.flex_col]}>
      <Formik initialValues={notedData} onSubmit={handleSubmit} enableReinitialize={true}>
        {(formikProps: FormikProps<ArtistNotedData>) => {
          return (
            <Form>
              <div css={[t.mb_5]}>
                <FormCheckbox name="isEligible" label="Eligible for Noted program" />
              </div>
              <div css={[t.mb_5, t.max_w('500px')]}>
                <FormCheckbox name="isNoted" label="In Noted program" />
              </div>
              <div css={[t.mb_5, t.max_w('500px')]}>
                <FormCheckbox name="isHomePage" label="Home Page Noted Artist" />
              </div>
              <div css={[t.mb_5, t.max_w('500px')]}>
                <FormCheckbox name="isNotedFeatured" label="Featured Noted Artist" />
              </div>
              <div css={[t.mb_5, t.max_w('500px')]}>
                <TextField name="notedBlurb" placeholder="Noted artist blurb" maxLength={250} multiline rows={4} />
              </div>
              <AdminButton type="button" buttonType="submit" label="Update Artist Noted Data" disabled={isRequestInFlight} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
