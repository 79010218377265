import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx, ClassNames } from '@emotion/core';
import t, { CSSStyle } from '../../theme/newstyles';
import ReactModal from 'react-modal';
import { useThemeController } from '../../contexts/ThemeContext';

export function FullScreenDialog(props: {
  isOpen: boolean;
  onRequestClose?: (event: React.MouseEvent | React.KeyboardEvent) => void;
  aria?: ReactModal.Aria;
  children?: React.ReactNode;
  overlayStyle?: CSSStyle;
  dialogStyle?: CSSStyle;
}) {
  const { dark } = useThemeController();
  const overlayStyle: CSSStyle = [t.fixed, t.inset_0, t.z_80, t.flex, t.flex_col, t.items_center, props.overlayStyle];
  const dialogSyle: CSSStyle = [t.outline_none, t.w_full, t.h_full, dark ? t.bg_dark_1 : t.bg_tint_5, t.flex, t.flex_col, props.dialogStyle];

  return (
    <ClassNames>
      {(css) => {
        const overlayClassName = css.css(overlayStyle);
        const dialogClassName = css.css(dialogSyle);
        const bodyClassName = css.css([t.overflow_y_hidden]);
        const htmlClassName = css.css([t.overflow_y_hidden]);
        return (
          <ReactModal
            isOpen={props.isOpen}
            htmlOpenClassName={htmlClassName}
            bodyOpenClassName={bodyClassName}
            overlayClassName={overlayClassName}
            className={dialogClassName}
            onRequestClose={props.onRequestClose}
            aria={props.aria}
          >
            {props.children}
          </ReactModal>
        );
      }}
    </ClassNames>
  );
}

export default FullScreenDialog;
