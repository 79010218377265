import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';

export const AdminTabs = (props: {
  orientation?: 'vertical' | 'horizontal';
  value: number;
  onChange?: (event: React.ChangeEvent<unknown>, newValue: number) => void;
  children?: React.ReactNode;
}) => {
  const children = React.Children.map(props.children, (child, i) => {
    if (!React.isValidElement(child)) {
      return null;
    }

    const selected = i === props.value;

    return React.cloneElement(child, { selected: selected, value: i, orientation: props.orientation, onChange: props.onChange });
  });

  return <div css={[t.flex, props.orientation === 'vertical' ? t.flex_col : t.flex_row]}>{children}</div>;
};
