import * as React from 'react';
import { FirebaseSDK } from './FirebaseContext';
import { PartnerRequests } from './PartnerRequestContext';

const PartnerRequestListContext = React.createContext<PartnerRequests[] | null>(null);
export function PartnerRequestsListProvider(props: { children: React.ReactNode }) {
  const [state, setState] = React.useState<PartnerRequests[] | null>(null);
  React.useEffect(() => {
    const requestCollection = FirebaseSDK.firestore().collection('/partnerRequests');
    const filteredQuery = requestCollection.where('Active.ApprovalState', '==', 'pending');

    const observer = (snapshot: firebase.default.firestore.QuerySnapshot) => {
      const requests: PartnerRequests[] = snapshot.docs.map((request) => {
        const requestData = request.data() as PartnerRequests;
        requestData.id = request.id;
        return requestData;
      });

      requests.sort((a, b) => {
        if (!a.Active && !b.Active) return 0;
        if (!a.Active) return -1;
        if (!b.Active) return 1;
        return a.Active.RequestDate < b.Active.RequestDate ? -1 : 1;
      });
      setState(requests);
    };

    const error = (error: Error) => {
      setState(null);
    };

    return filteredQuery.onSnapshot(observer, error);
  }, []);

  return <PartnerRequestListContext.Provider value={state}>{props.children}</PartnerRequestListContext.Provider>;
}

export function useParterRequestList() {
  return React.useContext(PartnerRequestListContext);
}
