import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { AdminHandlePayout } from '../../../contexts/OrderContext';
import { ClientSideOrder as Order } from '../../../types/shared-types';
import { Button } from '../../../components/Button';
import { useAdminPendingOperation } from './AdminPendingOperation';

export const AdminOrderDetailsPayments = (props: { order: Order }) => {
  const { order } = props;

  const pendingOperation = useAdminPendingOperation();

  const handlePayout = async () => {
    pendingOperation.show(true);
    await AdminHandlePayout(order.id);
    pendingOperation.show(false);
  };

  return (
    <div css={[t.m_auto, t.text_center]}>
      {order.paymentState === 'authorized' && <div>Braintree is in the process of settling the transaction.</div>}
      {order.paymentState === 'settled' && (
        <React.Fragment>
          <Button type="button" styleType="primary" label="Finalize payout process" outerStyle={[t.mb_2]} onClick={handlePayout} />
          <div css={[t.text_sm]}>This will indicate that the payout process has been completed and the artist has recieved their share.</div>
          <div css={[t.text_sm]}>Please ensure this is the case.</div>
        </React.Fragment>
      )}
      {order.paymentState === 'distributed' && <div>Funds have been distributed and artist has been paid.</div>}
    </div>
  );
};
