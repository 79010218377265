/** @jsx jsx */
import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/core'; // eslint-disable-line
import { FirebaseSDK } from '../contexts/FirebaseContext';
import { SiteWidePromo } from '../types/shared-types';

const DEFAULT_PROMO = {
  active: false,
  percentage: 0,
  name: '',
  description: '',
  homepageTitle: '',
  homepageBody: '',
};

const DEFAULT_PROMO_CONTEXT_STATE = {
  ...DEFAULT_PROMO,
  hasDismissedPromo: true,
  dismissPromo: () => false,
};

type AdditionalPromoContext = { hasDismissedPromo: boolean; dismissPromo: () => void };
type ContextState = SiteWidePromo & AdditionalPromoContext;

const SiteWidePromoContext = React.createContext<ContextState>(DEFAULT_PROMO_CONTEXT_STATE);

function CheckDismissedPromo() {
  const dismissedValue = window.sessionStorage.getItem('hasDismissedPromo');
  return !!dismissedValue && dismissedValue === 'true';
}

export function SiteWidePromoProvider(props: { children?: React.ReactNode }) {
  const [currentPromo, setCurrentPromo] = React.useState<SiteWidePromo>(DEFAULT_PROMO);
  const [hasDismissedPromo, setHasDismissedPromo] = React.useState<boolean>(CheckDismissedPromo());

  React.useEffect(() => {
    const observer = (snapshot: firebase.default.firestore.DocumentSnapshot) => {
      const content = snapshot.data() as SiteWidePromo;
      setCurrentPromo(content);
    };

    const error = (error: Error) => {
      console.error(error);
    };

    const promoDoc = FirebaseSDK.firestore().doc(`/content/siteWidePromotion`);
    return promoDoc.onSnapshot(observer, error);
  }, []);

  const dismissPromo = () => {
    setHasDismissedPromo(true);
    window.sessionStorage.setItem('hasDismissedPromo', 'true');
  };

  return <SiteWidePromoContext.Provider value={{ ...currentPromo, hasDismissedPromo, dismissPromo }}>{props.children}</SiteWidePromoContext.Provider>;
}

export function useSiteWidePromo() {
  return React.useContext(SiteWidePromoContext);
}
