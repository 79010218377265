import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';
import { TabPanel } from './TabPanel';
import { Tab } from './Tab';

export const TabSection = (props: {
  tabLabels: string[];
  children?: React.ReactNode;
  onTabChange?: (tabValue: number) => void;
  selectedFiltersSection?: JSX.Element;
  disableScroll?: boolean;
}) => {
  const { onTabChange, selectedFiltersSection, disableScroll } = props;
  const [value, SetValue] = React.useState(0);

  return (
    <div css={[t.flex_auto, t.flex, t.flex_col, t.bg_tint_5]}>
      <div
        css={[t.flex_none, t.flex, t.flex_row, t.border_b, t.border_solid, t.border_tint_3, t.px_5, t.firstType('div', [t.pl_0]), t.lastType('div', [t.pr_0])]}
      >
        {props.tabLabels.map((label, i) => {
          return (
            <div key={`${label}${i}`} css={[t.px_2]}>
              <Tab
                label={label}
                selected={value === i ? true : false}
                index={i}
                value={value}
                onChange={() => {
                  SetValue(i);
                  if (onTabChange) {
                    onTabChange(i);
                  }
                }}
                onClick={() => {
                  SetValue(i);
                  if (onTabChange) {
                    onTabChange(i);
                  }
                }}
              />
            </div>
          );
        })}
      </div>
      {selectedFiltersSection && selectedFiltersSection}
      <div css={[t.flex_auto, !disableScroll && t.overflow_y_auto]}>
        {React.Children.map(props.children, (child, i) => {
          return (
            <TabPanel value={value} index={i}>
              {child}
            </TabPanel>
          );
        })}
      </div>
    </div>
  );
};
