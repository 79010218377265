import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import dayjs from 'dayjs';
import { useOrder } from '../../../contexts/OrderContext';

export const AdminOrderDetailsHeader = () => {
  const order = useOrder();
  if (!order) return null;

  let statusColor = t.bg_dark_3;
  switch (order.state.type) {
    case 'pending': {
      statusColor = t.bg_tint_2;
      break;
    }
    case 'completed': {
      statusColor = t.bg_primary_4;
      break;
    }
    case 'canceled': {
      statusColor = t.bg_error_2;
      break;
    }
  }

  const isOverdue = order.state.type === 'active' && order.state.overdue;
  let statusDate: React.ReactNode | null = null;
  switch (order.state.type) {
    case 'pending': {
      statusDate = <div css={[t.text_tint_2]}>{`Respond by: ${dayjs(order.createdOn).add(2, 'day').format('MMM DD, YYYY')}`}</div>;
      break;
    }
    case 'active': {
      statusDate = (
        <div css={[isOverdue && t.text_error_2]}>
          {dayjs().isBefore(order.startOn) ? `Starts: ${dayjs(order.startOn).format('MMM DD, YYYY')}` : `Due: ${dayjs(order.dueOn).format('MMM DD, YYYY')}`}
        </div>
      );
      break;
    }
    case 'submitted': {
      statusDate = <div>{`${dayjs(order.submittedOn).format('MMM DD, YYYY')}`}</div>;
      break;
    }
    case 'revision': {
      statusDate = <div css={[isOverdue && t.text_error_2]}>{`Due: ${dayjs(order.dueOn).format('MMM DD, YYYY')}`}</div>;
      break;
    }
    case 'completed': {
      statusDate = <div css={[t.text_primary_4]}>{`${dayjs(order.submittedOn).format('MMM DD, YYYY')}`}</div>;
      break;
    }
    case 'review_requested':
    case 'in_review': {
      statusDate = (
        <div css={[t.text_error_2]}>{`${dayjs(order.history.find((h) => h.type === 'issueRaised' && order.state.type === 'review_requested')?.time).format(
          'MMM DD, YYYY'
        )}`}</div>
      );
    }
  }

  return (
    <div css={[t.absolute, t.inset_x_0, t.z_40, t.flex, t.flex_row, t.items_center, t.text_sm, t.h('55px'), t.border_b, t.border_solid, t.border_tint_3]}>
      <div css={[t.flex, t.flex_row, t.items_center, statusColor, t.text_tint_5, t.uppercase, t.h_full, t.px_6]}>
        {order.braintreeTransactionRefunded ? 'Refunded' : order.state.type}
      </div>
      <div css={[t.ml_3, t.text_dark_3]}>{statusDate}</div>
    </div>
  );
};
