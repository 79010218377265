import templates from './packageTemplates.json';
import tags from './packageTags.json';

export interface PackageTemplate {
  TemplateName: string;
  Title: string;
  SongLength?: 'short' | 'standard' | 'long';
  Description: string;
  Price: number;
  PriceFloor: number;
  DefaultTags?: string[];
}

const templateBuckets = templates as any;
for (const templateBucket in templateBuckets) {
  const templateBucketObject: any = templateBuckets[templateBucket];
  for (const templateKey in templateBucketObject) {
    templateBucketObject[templateKey].TemplateName = templateKey;
  }
}

export const PackageTemplates: { [key: string]: { [key: string]: PackageTemplate } } = templateBuckets;
export const PackageTags: Record<string, string> = tags;
