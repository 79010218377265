import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../theme/newstyles';
import { useArtistSearch } from '../../contexts/ArtistSearchContext';
import { Checkbox } from '../../components/Checkbox';
import NotedBadgePNG54 from '../../icons/noted_badge_54.png';

export function ArtistFilterList(props: { attribute: 'genreTags' | 'instrumentTags' | 'packageTags'; options: { [key: string]: string } }) {
  const discoverySearch = useArtistSearch();

  let refine = discoverySearch.toggleGenre;
  let active = discoverySearch.genreActive;
  let filterList = discoverySearch.state.genreFilters;
  switch (props.attribute) {
    case 'genreTags':
      break;
    case 'instrumentTags':
      refine = discoverySearch.toggleInstrument;
      active = discoverySearch.instrumentActive;
      filterList = discoverySearch.state.instrumentFilters;
      break;

    case 'packageTags':
      refine = discoverySearch.togglePackageTag;
      active = discoverySearch.packageTagActive;
      filterList = discoverySearch.state.packageTagFilters;
      break;
  }

  if (!active) return null;
  return (
    <ul css={[t.pb_4]}>
      {Object.keys(props.options).map((option, i) => {
        const checked = filterList.includes(option);

        return (
          <SelectCheckbox key={option}>
            {option === 'noted' ? (
              <span css={[t.flex]}>
                <Checkbox
                  name="genreTags"
                  value={option}
                  checked={checked}
                  label={props.options[option]}
                  focused={false}
                  onChange={() => refine(option)}
                  labelStyles={[t.text_color('#6f3fd7')]}
                />
                <img width={24} height={24} style={{ marginLeft: '-10px' }} src={NotedBadgePNG54} alt="noted artist" />
              </span>
            ) : (
              <Checkbox
                name="genreTags"
                value={option}
                checked={checked}
                label={props.options[option]}
                focused={false}
                onChange={() => refine(option)}
                fullWidth={true}
              />
            )}
          </SelectCheckbox>
        );
      })}
    </ul>
  );
}

const SelectCheckbox = (props: { focus?: boolean; children?: React.ReactNode }) => {
  const focus = !!props.focus;
  return <li css={[t.px_5, t.py_3, focus ? t.bg_tint_4 : null, t.hover([t.bg_tint_4])]}>{props.children}</li>;
};
