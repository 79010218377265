import { css } from '@emotion/core';
import { spacing } from './spacing';
import { h, min_h, max_h } from './utilities';

export const height = {
  h: h,
  min_h: min_h,
  max_h: max_h,

  h_header: css`
    height: 56px;
  `,

  h_0: css`
    height: ${spacing.s0};
  `,
  h_1: css`
    height: ${spacing.s1};
  `,
  h_2: css`
    height: ${spacing.s2};
  `,
  h_3: css`
    height: ${spacing.s3};
  `,
  h_4: css`
    height: ${spacing.s4};
  `,
  h_5: css`
    height: ${spacing.s5};
  `,
  h_6: css`
    height: ${spacing.s6};
  `,
  h_7: css`
    height: ${spacing.s7};
  `,
  h_8: css`
    height: ${spacing.s8};
  `,
  h_9: css`
    height: ${spacing.s9};
  `,

  h_auto: css`
    height: auto;
  `,
  h_px: css`
    height: 1px;
  `,
  h_full: css`
    height: 100%;
  `,
  h_screen: css`
    height: 100vh;
  `,

  min_h_0: css`
    min-height: 0;
  `,
  min_h_full: css`
    min-height: 100%;
  `,
  min_h_screen: css`
    min-height: 100vh;
  `,
  max_h_full: css`
    max-height: 100%;
  `,
  max_h_screen: css`
    max-height: 100vh;
  `,
};
