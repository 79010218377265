import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';
import { InputErrorInline } from './InputErrorInline';
import { useFormikContext, getIn } from 'formik';

export type CheckboxProps<T> = {
  checked: boolean;
  value: keyof T;
  label?: string;
  disabled?: boolean;
  focused?: boolean;
};

export function CheckboxGroup<T, U extends { [key: string]: string }>(props: {
  name: keyof T;
  tagRender: (checkboxProps: CheckboxProps<U>) => React.ReactNode;
  options: U;
  ariaLabel?: string;
  showMax?: boolean;
  maxSelected?: number;
  focused?: boolean;
  invalid?: boolean;
  error?: string;
}) {
  const formikProps = useFormikContext<T>();

  if (!formikProps) {
    console.warn('Our checkboxes only work within Formik for now (and maybe forever)');
    return null;
  }
  if (!props.options) return null;

  const elements: React.ReactNode[] = [];

  const name = props.name as string;
  const values = getIn(formikProps.values, name);
  const invalid = getIn(formikProps.touched, name) && Boolean(getIn(formikProps.errors, name));
  const errorMessage = getIn(formikProps.errors, name);

  let index = 0;
  for (const option in props.options) {
    const checked = values.includes(option);
    const disabled = !checked && values && props.maxSelected ? values.length >= props.maxSelected : false;

    elements.push(
      <div key={option}>
        {props.tagRender({
          label: props.options[option],
          value: option,
          checked: checked,
          disabled: disabled,
          focused: index === 0 && props.focused ? true : false,
        })}
      </div>
    );
    index++;
  }

  return (
    <div css={[t.relative]}>
      <fieldset role="group" aria-label={props.ariaLabel} css={[t.flex, t.flex_col, t.flex_wrap, t.m_0, t.p_0]}>
        {elements}
      </fieldset>
      {props.showMax && (
        <div css={[t.typeStyle_lg7, t.text_dark_2]}>
          {values ? values.length : 0}/{props.maxSelected} selected
        </div>
      )}
      <InputErrorInline>{invalid && errorMessage}</InputErrorInline>
    </div>
  );
}
