import { css } from '@emotion/core';
import { palette } from './palette';

export const stroke = {
  stroke_current: css`
    stroke: currentColor;
  `,

  stroke_tint_5: css`
    stroke: ${palette.tint_5};
  `,
};
