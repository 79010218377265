/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react'; // eslint-disable-line
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useAccount } from '../../contexts/AccountContext';
import { AdminSidebar } from './AdminSidebar';
import { AdminUsers } from './users/AdminUsers';
import { AdminOverview } from './AdminOverview';
import { AdminArtists } from './artists/AdminArtists';
import { AdminOrders } from './orders/AdminOrders';
import { AdminSongs } from './songs/AdminSongs';
import { AdminDiscovery } from './AdminDiscovery';
import { AdminDevelopers } from './AdminDevelopers';
import t from '../../theme/admin/adminStyles';
import { ComponentTest } from '../testing/ComponentTest';
//import { Redirect } from 'react-router-dom';
import { NotFound } from '../errors/NotFound';
import { SignInPage } from '../signin';
import { AdminOccasions } from './AdminOccasions';
import { DiscoveryContentProvider } from '../../contexts/DiscoveryContentContext';
import { FeaturedArtistsContentProvider } from '../../contexts/FeaturedArtistContentContext';
import { AdminAccountProvider } from '../../contexts/AdminAccountContext';
import { AnimationTest } from '../testing/AnimationTest';
import { AnimationTestDiscovery } from '../testing/AnimationTestDiscovery';
import { AnimationTestArtistPage } from '../testing/AnimationTestArtistPage';
import { AnimationTestSearch } from '../testing/AnimationTestSearch';
import { AnimationTestHeader } from '../testing/AnimationTestHeader';
import { AnimationTestProvider, MotionCollectionForm, useAnimationTestController } from '../testing/AnimationTestContext';
import { Formik, FormikProps, Form } from 'formik';
import { InitialAnimationTestValues } from '../testing/AnimationTestData';
import { AdminFeaturedArtist } from './AdminFeaturedArtists';
import { AdminPendingOperationProvider } from './orders/AdminPendingOperation';
import { AdminSiteSettings } from './AdminSiteSettings';
import { PaymentSplitContentProvider } from '../../contexts/PaymentSplitsContentContext';
import { SiteWidePromoProvider } from '../../contexts/SiteWidePromoContext';
import { AdminSiteSwitches } from './AdminSiteSwitches';
import { AdminPermissions } from './AdminPermissions';

const AdminPageRouter = () => {
  //const account = useAccount();
  const { path } = useRouteMatch();

  // if (account.LoggedOut()) return <Redirect to={{ pathname: '/admin/signin', state: { returnURL: path } }} />;

  return (
    <AdminPendingOperationProvider>
      <DiscoveryContentProvider>
        <FeaturedArtistsContentProvider>
          <PaymentSplitContentProvider>
            <SiteWidePromoProvider>
              <div css={[t.flex, t.flex_row, t.bg_tint_4]}>
                <AdminSidebar />
                <div css={[t.flex, t.flex_col, t.flex1, t.overflow_x_hidden, t.min_h_screen, t.max_h_screen, t.overflow_y_auto]}>
                  <Switch>
                    <Route exact path={`${path}/users`} component={AdminUsers} />
                    <Route exact path={`${path}/users/:filters?`} component={AdminUsers} />
                    <Route exact path={`${path}/users/:userId/:filters?`} component={AdminUsers} />
                    <Route path={`${path}/artists`} component={AdminArtists} />
                    <Route exact path={`${path}/orders`} component={AdminOrders} />
                    <Route exact path={`${path}/orders/:filters?`} component={AdminOrders} />
                    <Route exact path={`${path}/orders/:userId/:filters?`} component={AdminOrders} />
                    <Route exact path={`${path}/songs`} component={AdminSongs} />
                    <Route exact path={`${path}/songs/:filters?`} component={AdminSongs} />
                    <Route exact path={`${path}/songs/:userId/:filters?`} component={AdminSongs} />
                    <Route exact path={`${path}/discovery`} component={AdminDiscovery} />
                    <Route exact path={`${path}/featured`} component={AdminFeaturedArtist} />
                    <Route exact path={`${path}/occasions`} component={AdminOccasions} />
                    <Route exact path={`${path}/overview`} component={AdminOverview} />
                    <Route exact path={`${path}/sitesettings`} component={AdminSiteSettings} />
                    <Route exact path={`${path}/siteswitches`} component={AdminSiteSwitches} />
                    <Route exact path={`${path}/adminPermissions`} component={AdminPermissions} />
                    <Route path={`${path}/components`} component={ComponentTest} />
                    <Route path={`${path}/animationTests`} component={AnimationTest} />
                    <Route exact path={`${path}/dev`} component={AdminDevelopers} />
                    <Route exact path={path} component={AdminOverview} />
                    <Route component={NotFound} />
                  </Switch>
                </div>
                <div id="modalRoot"></div>
                <div id="popoverRoot" css={[t.z_80]}></div>
              </div>
            </SiteWidePromoProvider>
          </PaymentSplitContentProvider>
        </FeaturedArtistsContentProvider>
      </DiscoveryContentProvider>
    </AdminPendingOperationProvider>
  );
};

const AdminAnimationRouter = () => {
  return (
    <AnimationTestProvider>
      <AdminAnimationForm />
    </AnimationTestProvider>
  );
};

const AdminAnimationForm = () => {
  const { path } = useRouteMatch();
  const animationController = useAnimationTestController();

  function handleSubmit() {
    console.log('submit');
  }

  function onFormChange() {
    animationController.refreshKey();
  }

  return (
    <Formik initialValues={InitialAnimationTestValues} onSubmit={handleSubmit}>
      {(formikProps: FormikProps<MotionCollectionForm>) => {
        return (
          <Form onChange={onFormChange}>
            <AnimationTestHeader />
            <Switch>
              <Route exact path={`${path}/discovery`} component={AnimationTestDiscovery} />
              <Route exact path={`${path}/artistPage`} component={AnimationTestArtistPage} />
              <Route exact path={`${path}/search`} component={AnimationTestSearch} />
              <Route component={NotFound} />
            </Switch>
          </Form>
        );
      }}
    </Formik>
  );
};

export const AdminRouter = (props: { [key: string]: string }) => {
  const account = useAccount();
  const { path } = useRouteMatch();

  if (account && !account.Claims.isAdmin) return <div>Unauthorized</div>;

  return (
    <AdminAccountProvider userId={account ? account.User.uid : ''}>
      <Switch>
        <Route exact path={`${path}/signin`} component={SignInPage} />
        <Route path={`${path}/animation`} component={AdminAnimationRouter} />
        <Route path={`${path}`} component={AdminPageRouter} />
      </Switch>
    </AdminAccountProvider>
  );
};

export default AdminRouter;
