import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t, { CSSStyle } from '../theme/newstyles';

export function InputLabel(props: { label: string; id: string; for: string; labelOffscreen?: string; labelStyle?: CSSStyle }) {
  return (
    <label
      id={props.id}
      htmlFor={props.for}
      css={[t.typeStyle_lg8, t.text_dark_1, props.labelOffscreen && props.labelOffscreen !== '' && t.offscreen, props.labelStyle]}
    >
      {props.label}
    </label>
  );
}
