import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import dayjs from 'dayjs';
import { MoreDotsIcon } from '../icons/MoreDotsIcons';
import { AdminActionsPopover } from '../components/AdminActionsPopover';
import { AdminActionsButton } from '../components/AdminActionButton';
import { useOrder, CanCancelOrder, CanRefundOrder, AssetsAreReady } from '../../../contexts/OrderContext';

import { FirebaseObjects } from '../../../contexts/FirebaseContext';
import { useAdminPendingOperation } from './AdminPendingOperation';
import { AdminListButton } from '../components/AdminListButton';
import { AdminDetailsLinkButton } from '../components/Dialogs/AdminDetailsLinkButton';
import { AdminButton } from '../components/AdminButton';

export const AdminOrderDetailsInfo = (props: unknown) => {
  const [actionsPopover, SetActionsPopover] = React.useState(false);
  const [popoverRect, SetPopoverRect] = React.useState<DOMRect | undefined>(undefined);

  const order = useOrder();
  const pendingOperation = useAdminPendingOperation();

  const handleCancelClick = React.useCallback(async () => {
    if (!order) return;
    if (!CanCancelOrder(order)) return;
    SetActionsPopover(false);
    pendingOperation.show(true);
    await FirebaseObjects.adminRequest({ action: 'cancelOrder', data: { orderId: order.id } });
    pendingOperation.show(false);
  }, [order, pendingOperation]);

  const handleRefundClick = React.useCallback(async () => {
    if (!order) return;
    if (!CanRefundOrder(order)) return;
    SetActionsPopover(false);
    pendingOperation.show(true);
    await FirebaseObjects.adminRequest({ action: 'refundOrder', data: { orderId: order.id } });
    pendingOperation.show(false);
  }, [order, pendingOperation]);

  const handleToggleSharing = React.useCallback(async () => {
    if (!order) return;
    SetActionsPopover(false);
    pendingOperation.show(true);
    await FirebaseObjects.adminRequest({ action: 'modifyOrder', data: { orderId: order.id, modifySharing: { public: !order.publicSharePage } } });
    pendingOperation.show(false);
  }, [order, pendingOperation]);

  if (!order) return null;
  if (order.order.type !== 'song') return null;

  const handleActionsClick = (rect: DOMRect | undefined, event: React.MouseEvent) => {
    SetActionsPopover(true);
    SetPopoverRect(rect);
  };

  const handleActionsClose = () => {
    SetActionsPopover(false);
  };

  const details = order.order.details;

  return (
    <div css={[t.border_b, t.border_solid, t.border_tint_3, t.pt_5, t.pb_8, t.pl_6, t.flex, t.flex_col]}>
      <div css={[t.flex, t.flex_row]}>
        <div css={[t.flex_auto]}>
          <div css={[t.flex, t.flex_row]}>
            <div css={[t.text_2xl, t.text_dark_1, t.font_medium, t.mb_2]}>{details.occasion}</div>
            <div css={[t.flex_auto, t.flex, t.flex_row, t.items_center, t.justify_end, t.pr_4]}>
              <AdminActionsButton buttonCSS={[t.ml_3]} onClick={handleActionsClick}>
                <MoreDotsIcon css={[t.size('16px')]} />
              </AdminActionsButton>
            </div>
          </div>
        </div>
      </div>
      <div css={[t.flex, t.mb_1]}>
        <div css={[t.text_sm, t.text_dark_4]}>Artist:</div>
        <AdminDetailsLinkButton to={`/admin/artists/${order.artistId}`} containerCSS={[t.mt_0, t.ml_1]}>
          {order.artistName}
        </AdminDetailsLinkButton>
      </div>
      <div css={[t.flex, t.mb_1]}>
        <div css={[t.text_sm, t.text_dark_4]}>Commissioner:</div>
        <AdminDetailsLinkButton to={`/admin/users/${order.customerId}/["user","deleted","artist"]`} containerCSS={[t.mt_0, t.ml_1]}>
          {order.customerName}
        </AdminDetailsLinkButton>
      </div>
      <div css={[t.text_sm, t.text_dark_4, t.mb_2]}>Requested: {dayjs(order.createdOn).format('MMM DD, YYYY')}</div>
      <div css={[t.text_sm, t.text_dark_4, t.mb_2]}>
        Payment State: <span css={[t.capitalize]}>{order.paymentState}</span>
      </div>
      <div css={[t.text_xs, t.text_tint_1, t.mb_1]}>Order id: {order.id}</div>
      {order.braintreeTransactionId && <div css={[t.text_xs, t.text_tint_1, t.mb_1]}>Braintree Transaction id: {order.braintreeTransactionId}</div>}
      {order.paypalBatchId && <div css={[t.text_xs, t.text_tint_1, t.mb_1]}>Paypal Batch id: {order.paypalBatchId}</div>}
      {order.paypalTransactionId && <div css={[t.text_xs, t.text_tint_1, t.mb_1]}>Paypal Transaction id: {order.paypalTransactionId}</div>}
      {AssetsAreReady(order) && (
        <div css={[t.mt_2]}>
          <div css={[t.text_xs, t.text_tint_1, t.mb_1]}>Sharing {order.publicSharePage ? 'Enabled' : 'Disabled'}</div>
          <AdminButton type="link" styleType="secondary" label="Share" to={`/share/s/${order.id}`} />
        </div>
      )}
      {/* Rendered in the Modal Root */}
      {actionsPopover ? (
        <AdminActionsPopover anchor="right" positionRect={popoverRect} onClose={handleActionsClose}>
          <AdminListButton
            label={order.publicSharePage ? 'Disable Sharing' : 'Enable Sharing'}
            onClick={handleToggleSharing}
            disabled={!AssetsAreReady(order)}
          />
          <AdminListButton label="Cancel Order" onClick={handleCancelClick} disabled={!CanCancelOrder(order)} />
          <AdminListButton label="Refund Order" onClick={handleRefundClick} disabled={!CanRefundOrder(order)} />
        </AdminActionsPopover>
      ) : null}
    </div>
  );
};
