import React from 'react'; // eslint-disable-line
import { motion, Variants } from 'framer-motion';

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../theme/newstyles';
import { MainContainer } from '../../components/MainContainer';
import { useArtistSearch } from '../../contexts/ArtistSearchContext';
import { GenreTagDictionary, InstrumentTagDictionary } from '../../data/ProfileData';
import { FilterTag } from '../../components/FilterTag';
import { GetTotalArtistFilters } from './ArtistSearch';
import { UnderlineButton } from '../../components/UnderlineButton';
import { PackageTags } from '../../data/PackageData';
import { useSwitches } from '../../contexts/SwitchesContext';

enum SelectedFiltersAnimationStates {
  none = 'none',
  active = 'active',
}

const clearButtonVariants: Variants = {};
clearButtonVariants[SelectedFiltersAnimationStates.none] = {
  height: 0,
  opacity: 0,
  transitionEnd: { display: 'none' },
  transition: { ease: 'easeInOut', duration: 0.3 },
};
clearButtonVariants[SelectedFiltersAnimationStates.active] = {
  height: 'auto',
  opacity: 1,
  transition: { ease: 'easeInOut', duration: 0.3 },
};

function SelectedArtistFilters(props: { clearFilters: () => void }) {
  const discoverySearch = useArtistSearch();
  const switches = useSwitches();
  if (!discoverySearch.state) return null;

  const PackageTagsWithNoted: { [key: string]: string } = switches && switches?.noted ? { noted: 'Noted Artist', ...PackageTags } : { ...PackageTags };

  return (
    <motion.div>
      <div css={[t.flex, t.flex_wrap, t.min_h('34px')]}>
        {discoverySearch.state.genreFilters.map((option) => {
          return (
            <div css={[t.mr_3, t.mb_3]} key={option}>
              <FilterTag
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => discoverySearch.toggleGenre(option)}
                label={GenreTagDictionary[option]}
                value={option}
                checked={true}
                removable={true}
              />
            </div>
          );
        })}
        {discoverySearch.state.instrumentFilters.map((option) => {
          return (
            <div css={[t.mr_3, t.mb_3]} key={option}>
              <FilterTag
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => discoverySearch.toggleInstrument(option)}
                label={InstrumentTagDictionary[option]}
                value={option}
                checked={true}
                removable={true}
              />
            </div>
          );
        })}
        {discoverySearch.state.packageTagFilters.map((option) => {
          return (
            <div css={[t.mr_3, t.mb_3]} key={option}>
              <FilterTag
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => discoverySearch.togglePackageTag(option)}
                label={PackageTagsWithNoted[option]}
                value={option}
                checked={true}
                removable={true}
              />
            </div>
          );
        })}
        <div css={[t.flex_auto, t.flex, t.justify_end]}>
          <motion.div variants={clearButtonVariants}>
            <UnderlineButton onClick={props.clearFilters}>Clear filters</UnderlineButton>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
}

const filterVariants: Variants = {};
filterVariants[SelectedFiltersAnimationStates.none] = {
  height: 0,
  transition: { ease: 'easeInOut', duration: 0.3 },
};
filterVariants[SelectedFiltersAnimationStates.active] = {
  height: 'auto',
  transition: { ease: 'easeInOut', duration: 0.3 },
};

const filterListVariants: Variants = {};
filterListVariants[SelectedFiltersAnimationStates.none] = {
  opacity: 0,
  transition: { ease: 'easeInOut', duration: 0.3 },
};
filterListVariants[SelectedFiltersAnimationStates.active] = { opacity: 1, transition: { ease: 'easeInOut', duration: 0.3 } };

export const ArtistActiveFiltersList = () => {
  const discoverySearch = useArtistSearch();
  if (!discoverySearch.state) return null;

  const hasActiveFilters = GetTotalArtistFilters(discoverySearch) > 0;

  return (
    <motion.div css={[t.bg_tint_5, hasActiveFilters ? t.border_b : t.border_b_0, t.border_tint_3, t.sticky, t.top('56px')]}>
      <motion.div
        initial={SelectedFiltersAnimationStates.none}
        animate={hasActiveFilters ? SelectedFiltersAnimationStates.active : SelectedFiltersAnimationStates.none}
        variants={filterVariants}
      >
        <motion.div css={[t.pt_4, t.pb_1, t.md([t.pt_5, t.pb_2])]} variants={filterListVariants}>
          <MainContainer>
            <SelectedArtistFilters clearFilters={discoverySearch.clearAllFilters} />
          </MainContainer>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default ArtistActiveFiltersList;
