/** @jsx jsx */
import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/core'; // eslint-disable-line
import { FirebaseSDK, FirebaseObjects } from '../contexts/FirebaseContext';

export interface PaymentSplitContent {
  percentage: number;
}

const PaymentSplitContentContext = React.createContext<PaymentSplitContent | null>(null);
export function PaymentSplitContentProvider(props: { children?: React.ReactNode }) {
  const [state, setState] = React.useState<PaymentSplitContent | null>(null);
  React.useEffect(() => {
    const observer = (snapshot: firebase.default.firestore.DocumentSnapshot) => {
      const content = snapshot.data() as PaymentSplitContent;
      setState(content);
    };

    const error = (error: Error) => {
      console.error(error);
    };

    const discoveryDoc = FirebaseSDK.firestore().doc(`/content/paymentSplits`);
    return discoveryDoc.onSnapshot(observer, error);
  }, []);
  return <PaymentSplitContentContext.Provider value={state}>{props.children}</PaymentSplitContentContext.Provider>;
}

export function usePaymentSplitContent() {
  return React.useContext(PaymentSplitContentContext);
}

export async function PublishPaymentSplits() {
  await FirebaseObjects.adminRequest({ action: 'publishPaymentSplits', data: {} });
}

export async function EditPaymentSplit(percentage: number) {
  await FirebaseObjects.adminRequest({ action: 'editPaymentSplits', data: { split: { action: 'editSplit', percentage } } });
}
