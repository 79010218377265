import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { FormCheckbox } from '../../../components/FormCheckbox';
import { Form, Formik, FormikProps } from 'formik';
import { AdminButton } from '../components/AdminButton';
import { FirebaseObjects } from '../../../contexts/FirebaseContext';
import { useArtistProfile } from '../../../contexts/ArtistProfileContext';
import { ArtistInstantBookData } from '../../../types/shared-types';
import dayjs from 'dayjs';

export const AdminArtistDetailsInstant = (props: { id: string }) => {
  const [isRequestInFlight, setIsRequestInFlight] = React.useState(false);

  const artist = useArtistProfile();
  const instantBookData = artist.Profile?.instantBookData;

  if (!instantBookData) return null;

  const handleSubmit = async (formData: Partial<ArtistInstantBookData>) => {
    setIsRequestInFlight(true);
    // Capture the date artist became instant book and increase occurance counter
    if (formData.isInstantBook) {
      instantBookData.isInstantBook = formData.isInstantBook;
      instantBookData.instantBookStatusStartDate = dayjs.utc().toISOString();
      instantBookData.timesAsInstantBookArtist += 1;
    }
    // Capture the date artist was removed from instant book
    if (formData.isInstantBook === false && instantBookData.instantBookStatusStartDate) {
      instantBookData.isInstantBook = formData.isInstantBook;
      instantBookData.instantBookStatusEndDate = dayjs.utc().toISOString();
    }

    try {
      await FirebaseObjects.adminRequest({
        action: 'editArtistInstantBookData',
        data: { artistInstantBookData: instantBookData, id: props.id },
      });
      setIsRequestInFlight(false);
    } catch (error) {
      setIsRequestInFlight(false);
      console.log('ERROR', error);
    }
  };

  return (
    <div css={[t.border_b, t.border_solid, t.border_tint_3, t.pt_5, t.pb_6, t.pl_6, t.flex, t.flex_col]}>
      <Formik initialValues={instantBookData} onSubmit={handleSubmit} enableReinitialize={true}>
        {(formikProps: FormikProps<ArtistInstantBookData>) => {
          return (
            <Form>
              <div css={[t.mb_5]}>
                <FormCheckbox name="isInstantBook" label="Instant Commission Artist" />
              </div>
              <AdminButton type="button" buttonType="submit" label="Update Artist Instant Commission Data" disabled={isRequestInFlight} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
