import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import t, { CSSStyle } from '../theme/newstyles';
import { Link } from 'react-router-dom';
import * as H from 'history';
import { Spinner } from './Spinner';
import { palette } from '../theme/palette';

const baseStyle = css`
  min-width: 140px;
  padding: 13px 40px 15px 40px;
  min-height: 56px;
  max-height: 56px;
`;

const secondaryPaddingStyle = [t.pt('12px'), t.pb('14px')];

// PRIMARY STYLING
const primaryStyle = (color?: palette, hoverColor?: palette, activeColor?: palette, primaryStyle?: CSSStyle) => {
  return [
    baseStyle,
    t.text_tint_5,
    color ? t.bg_color(color) : t.bg_primary_4,
    t.media_hover(t.hover(hoverColor ? t.bg_color(hoverColor) : t.bg_primary_3)),
    t.active([activeColor ? t.bg_color(activeColor) : t.bg_primary_2, t.text_tint_4]),
    primaryStyle,
  ];
};
const focusPrimaryStyle = [t.bg_primary_3];
const disabledPrimaryStyle = [t.bg_tint_2, t.text_tint_5];
const disabledPrimaryStyle2 = [t.bg_primary_4, t.text_tint_5, 'opacity: .65;'];

// SECONDARY STYLING
const secondaryStyle = (color?: palette, hoverColor?: palette, activeColor?: palette, secondaryStyle?: CSSStyle) => {
  return [
    baseStyle,
    secondaryPaddingStyle,
    t.bg_transparent,
    color ? [t.text_color(color), t.border_color(color)] : [t.text_dark_1, t.border_dark_1],
    t.border_1,
    t.border_solid,
    t.media_hover(t.hover([hoverColor ? [t.text_color(hoverColor), t.border_color(hoverColor)] : [t.text_primary_4, t.border_primary_4]])),
    t.active([activeColor ? [t.text_color(activeColor), t.border_color(activeColor)] : [t.text_primary_2, t.border_primary_2]]),
    secondaryStyle,
  ];
};
const focusSecondaryStyle = [t.text_primary_4, t.border_primary_4];
const disabledSecondaryStyle = [t.text_tint_2, t.border_tint_2];

// SECONDARY ON DARK STYLING
const secondaryOnDarkStyle = (color?: palette, hoverColor?: palette, activeColor?: palette) => {
  return [
    baseStyle,
    secondaryPaddingStyle,
    t.bg_transparent,
    color ? [t.text_color(color), t.border_color(color)] : [t.text_tint_5, t.border_tint_5],
    t.border_1,
    t.border_solid,
    t.media_hover(t.hover([t.border_tint_5, t.bg_tint_5, hoverColor ? t.text_color(hoverColor) : t.text_dark_1])),
    t.active([t.border_tint_2, t.bg_tint_2, activeColor ? t.text_color(activeColor) : t.text_dark_1]),
  ];
};
const focusSecondaryOnDarkStyle = [t.border_tint_5];
const disabledSecondaryOnDarkStyle = [t.border_tint_1, t.text_tint_1];

// CRITICAL STYLING
const criticalStyle = [
  baseStyle,
  secondaryPaddingStyle,
  t.bg_transparent,
  t.text_error_1,
  t.border_1,
  t.border_error_1,
  t.border_solid,
  t.media_hover(t.hover([t.text_tint_5, t.border_error_1, t.bg_error_1])),
  t.active([t.text_primary_2, t.text_tint_5, t.border_error_2, t.bg_error_2]),
];
const focusCriticalStyle = [t.text_tint_5, t.border_error_1, t.bg_error_1];
const disabledCriticalStyle = [t.text_tint_2, t.bg_transparent, t.border_tint_2];

// OVERLAY STYLING
const overlayStyle = [
  baseStyle,
  t.bg_tint_5,
  t.text_dark_1,
  t.media_hover(t.hover([t.bg_primary_4, t.text_tint_5])),
  t.active([t.bg_primary_2, t.text_tint_5]),
];
const focusOverlayStyle = [t.bg_primary_4, t.text_tint_5];
const disabledOverlayStyle = [t.bg_tint_5, t.text_tint_2];

type SharedButtonProps = {
  label?: string;
  styleType?: 'primary' | 'secondary' | 'secondaryOnDark' | 'critical' | 'overlay';
  disabled?: boolean;
  useDisabledPrimaryStyle2?: boolean;
  focused?: boolean;
  icon?: JSX.Element;
  color?: palette;
  hoverColor?: palette;
  activeColor?: palette;
  isInProgress?: boolean;
  smallIcon?: boolean;
  fullWidth?: boolean;
  tabIndex?: number;
  outerStyle?: CSSStyle;
  innerStyle?: CSSStyle;
  secondaryStyle?: CSSStyle;
  primaryStyle?: CSSStyle;
  labelStyle?: CSSStyle;
  onFocus?: (event: React.FocusEvent) => void;
  onBlur?: (event: React.FocusEvent) => void;
  id?: string;
};

type ButtonLinkProps<S> = {
  type: 'link';
  linkClickCallback?: () => void;
  to: H.LocationDescriptor<S> | ((location: H.Location<S>) => H.LocationDescriptor<S>);
} & SharedButtonProps;

type ButtonProps<S> =
  | ({
      type: 'button';
      buttonType?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
      onClick?: (event: React.MouseEvent) => void;
    } & SharedButtonProps)
  | ButtonLinkProps<S>
  | ({
      type: 'input';
      formName: string;
      inputType?: React.InputHTMLAttributes<HTMLInputElement>['type'];
      accept?: string;
      onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
    } & SharedButtonProps);

export function Button<S = H.LocationState>(props: ButtonProps<S>) {
  const [focus, SetFocus] = React.useState(props.focused);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);
  const anchorRef = React.useRef<HTMLAnchorElement | null>(null);
  const styleType = props.styleType ? props.styleType : 'primary';

  const initiallyFocused = !!props.focused;
  React.useEffect(() => {
    if (inputRef.current && initiallyFocused) {
      SetFocus(true);
      inputRef.current.focus();
    }
  }, [initiallyFocused, inputRef]);

  function HandleFocus(event: React.FocusEvent) {
    SetFocus(true);
    if (props.onFocus) props.onFocus(event);
  }

  function HandleBlur(event: React.FocusEvent) {
    SetFocus(false);
    if (props.onBlur) props.onBlur(event);
  }

  let innerStyle: CSSStyle =
    props.type === 'input' && props.disabled
      ? [primaryStyle(props.color, props.hoverColor, props.activeColor, props.primaryStyle), disabledPrimaryStyle, t.hover(disabledPrimaryStyle), t.cursor_auto]
      : primaryStyle(props.color, props.hoverColor, props.activeColor, props.primaryStyle);
  let focusStyle = focusPrimaryStyle;
  let disabledStyle = props.useDisabledPrimaryStyle2 ? disabledPrimaryStyle2 : disabledPrimaryStyle;
  switch (styleType) {
    case 'secondary': {
      innerStyle =
        props.type === 'input' && props.disabled
          ? [
              secondaryStyle(props.color, props.hoverColor, props.activeColor),
              disabledSecondaryStyle,
              t.hover([disabledSecondaryStyle]),
              t.cursor_auto,
              props.secondaryStyle,
            ]
          : secondaryStyle(props.color, props.hoverColor, props.activeColor, props.secondaryStyle);
      disabledStyle = disabledSecondaryStyle;
      focusStyle = focusSecondaryStyle;
      break;
    }
    case 'secondaryOnDark': {
      innerStyle =
        props.type === 'input' && props.disabled
          ? [
              secondaryOnDarkStyle(props.color, props.hoverColor, props.activeColor),
              disabledSecondaryOnDarkStyle,
              t.hover([disabledSecondaryOnDarkStyle]),
              t.cursor_auto,
            ]
          : secondaryOnDarkStyle(props.color, props.hoverColor, props.activeColor);
      disabledStyle = disabledSecondaryOnDarkStyle;
      focusStyle = focusSecondaryOnDarkStyle;
      break;
    }
    case 'critical': {
      innerStyle =
        props.type === 'input' && props.disabled ? [criticalStyle, disabledCriticalStyle, t.hover([disabledCriticalStyle]), t.cursor_auto] : criticalStyle;
      disabledStyle = disabledCriticalStyle;
      focusStyle = focusCriticalStyle;
      break;
    }
    case 'overlay': {
      innerStyle =
        props.type === 'input' && props.disabled ? [overlayStyle, disabledOverlayStyle, t.hover([disabledOverlayStyle]), t.cursor_auto] : overlayStyle;
      disabledStyle = disabledOverlayStyle;
      focusStyle = focusOverlayStyle;
      break;
    }
  }

  const iconOnly = props.icon && !props.label;
  const labelAndIcon = props.label && props.icon;

  const iconOnlyContainerStyle = [
    css`
      min-width: ${props.smallIcon ? '36px' : '56px'};
      max-width: ${props.smallIcon ? '36px' : '56px'};
      min-height: ${props.smallIcon ? '36px' : '56px'};
      max-height: ${props.smallIcon ? '36px' : '56px'};
      padding: ${props.smallIcon ? '12px' : '16px'};
    `,
  ];

  let containerStyle = null;
  let iconStyle = null;
  if (labelAndIcon)
    containerStyle =
      props.styleType === 'critical' || props.styleType === 'secondary' || props.styleType === 'secondaryOnDark'
        ? t.p({ top: '12px', right: '40px', bottom: '14px', left: '32px' })
        : t.p({ top: '13px', right: '40px', bottom: '15px', left: '32px' });
  if (iconOnly) {
    containerStyle = [iconOnlyContainerStyle];
    iconStyle = [t.mr_0];
  }
  if (iconOnly && props.styleType === 'secondaryOnDark') {
    containerStyle = [iconOnlyContainerStyle];
  }

  function HandleInnerFocus(event: React.FocusEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  const innerAndOuterStyles = props.fullWidth ? [t.w_full, t.flex_grow_2, t.flex_auto] : null;

  const contents = (
    <span
      css={[
        t.relative,
        t.inline_flex,
        t.flex_row,
        t.justify_center,
        t.items_center,
        t.outline_none,
        t.typeStyle_lg8,
        innerStyle,
        containerStyle,
        t.before([t.focusIndicator, t.pos('-3px'), t.border_primary_4, focus ? t.content_some : t.content_none]),
        focus ? focusStyle : null,
        innerAndOuterStyles,
        props.innerStyle,
      ]}
      tabIndex={-1}
      onFocus={HandleInnerFocus}
    >
      {props.isInProgress ? <Spinner styleType={props.styleType} small={true} /> : null}
      {props.icon && !props.isInProgress ? (
        <div css={[t.inline_block, props.smallIcon ? t.size('16px') : t.size('24px'), t.mr_2, t.flex, t.items_center, t.justify_center, iconStyle]}>
          {props.icon}
        </div>
      ) : null}
      {props.label && !props.isInProgress ? <span css={[t.whitespace_no_wrap, props.labelStyle]}>{props.label}</span> : null}
    </span>
  );

  const outerStyle = [
    t.relative,
    t.bg_transparent,
    t.outline_none,
    t.cursor_pointer,
    t.no_underline,
    t.p_0,
    t.disabled(t.cursor_not_allowed),
    t.disabledSelector('> span', disabledStyle),
    innerAndOuterStyles,
    props.outerStyle,
  ];

  switch (props.type) {
    case 'button': {
      const buttonType = props.buttonType || 'button';

      const HandleClick = (event: React.MouseEvent) => {
        if (props.onClick) props.onClick(event);
      };

      return (
        <button
          ref={buttonRef}
          type={buttonType}
          css={outerStyle}
          disabled={props.disabled}
          aria-disabled={props.disabled}
          onClick={HandleClick}
          onFocus={HandleFocus}
          onBlur={HandleBlur}
          tabIndex={props.tabIndex}
          id={props.id}
          data-cy="SignInButtonCypress"
        >
          {contents}
        </button>
      );
    }
    case 'link': {
      if (!props.disabled) {
        const callback = props.linkClickCallback ? props.linkClickCallback : () => false;
        return (
          <Link ref={anchorRef} to={props.to} css={outerStyle} onFocus={HandleFocus} onBlur={HandleBlur} onClick={callback}>
            {contents}
          </Link>
        );
      } else {
        return (
          <button
            ref={buttonRef}
            css={outerStyle}
            disabled={props.disabled}
            aria-disabled={props.disabled}
            onFocus={HandleFocus}
            onBlur={HandleBlur}
            tabIndex={props.tabIndex}
          >
            {contents}
          </button>
        );
      }
    }
    case 'input': {
      const id = `button-${props.formName}`;

      const HandleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === ' ') {
          if (inputRef.current) {
            inputRef.current.click();
          }
        }
      };

      return (
        <div css={[t.relative]}>
          <label htmlFor={id} css={[outerStyle]}>
            {contents}
            <input
              id={id}
              ref={inputRef}
              css={[t.hidden_input]}
              type={props.inputType}
              accept={props.accept}
              onChange={props.onChange}
              onKeyPress={HandleKeyPress}
              onFocus={HandleFocus}
              onBlur={HandleBlur}
              disabled={props.disabled}
              aria-disabled={props.disabled}
            />
          </label>
        </div>
      );
    }
  }
}

export default Button;
