import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { ArtistProfileProvider } from '../../../contexts/ArtistProfileContext';
import { UserAccountProvider } from '../../../contexts/UserAccountContext';
import { AdminDialogUserHistory } from '../users/AdminDialogUserHistory';
import { AdminDetailsDialog } from '../components/Dialogs/AdminDetailsDialog';
import { AdminArtistDetailsHeader } from './AdminArtistDetailsHeader';
import { AdminArtistDetailsInfo } from './AdminArtistDetailsInfo';
import { AdminArtistDetailsOrders } from './AdminArtistDetailsOrders';
import { AdminArtistDetailsAvailability } from './AdminArtistDetailsAvailability';
import { AdminArtistDetailsAccounting } from './AdminArtistDetailsAccounting';
import { AdminArtistDetailsSongs } from './AdminArtistDetailsSongs';
import { AdminDialogPage } from '../components/Dialogs/AdminDialogPage';
import { AdminArtistDetailsFinancials } from './AdminArtistDetailsFinancials';
import { PartnerRequestProvider } from '../../../contexts/PartnerRequestContext';
import { AdminArtistDetailsRequest } from './AdminArtistDetailsRequest';
import { AdminArtistDetailsMetaData } from './AdminArtistDetailsMetadata';
import { AdminArtistDetailsNoted } from './AdminArtistDetailsNoted';
import { AdminArtistDetailsInstant } from './AdminArtistDetailsInstant';

export const AdminArtistDetailsDialog = (props: {
  isOpen: boolean;
  userId?: string | null;
  onClose: (event: React.MouseEvent | React.KeyboardEvent) => void;
}) => {
  if (!props.userId) return null;

  return (
    <UserAccountProvider userId={props.userId}>
      <ArtistProfileProvider artistId={props.userId}>
        <PartnerRequestProvider id={props.userId}>
          <AdminDetailsDialog isOpen={props.isOpen} onRequestClose={props.onClose}>
            <AdminArtistDetailsHeader />
            <AdminDialogPage>
              <AdminArtistDetailsAvailability />
              <AdminArtistDetailsRequest />
              <div css={[t.flex, t.flex_col, t.h_full]}>
                <AdminArtistDetailsInfo />
                <AdminArtistDetailsMetaData id={props.userId} />
                <AdminArtistDetailsInstant id={props.userId} />
                <AdminArtistDetailsNoted id={props.userId} />
                <AdminArtistDetailsAccounting />
                <div css={[t.flex, t.border_b, t.border_solid, t.border_tint_3]}>
                  <AdminArtistDetailsFinancials />
                </div>
                <div css={[t.flex, t.border_b, t.border_solid, t.border_tint_3]}>
                  <AdminArtistDetailsOrders />
                </div>
                <div css={[t.flex, t.border_b, t.border_solid, t.border_tint_3]}>
                  <AdminArtistDetailsSongs />
                </div>

                <AdminDialogUserHistory uid={props.userId} />
              </div>
            </AdminDialogPage>
          </AdminDetailsDialog>
        </PartnerRequestProvider>
      </ArtistProfileProvider>
    </UserAccountProvider>
  );
};
