import { css } from '@emotion/core';
import { spacing } from './spacing';
import { w, min_w, max_w, h, min_h, max_h } from '../utilities';

export const sizing = {
  w: w,
  min_w: min_w,
  max_w: max_w,

  w_0: css`
    width: ${spacing.s0};
  `,
  w_1: css`
    width: ${spacing.s1};
  `,
  w_2: css`
    width: ${spacing.s2};
  `,
  w_3: css`
    width: ${spacing.s3};
  `,
  w_4: css`
    width: ${spacing.s4};
  `,
  w_5: css`
    width: ${spacing.s5};
  `,
  w_6: css`
    width: ${spacing.s6};
  `,
  w_8: css`
    width: ${spacing.s8};
  `,
  w_10: css`
    width: ${spacing.s10};
  `,
  w_12: css`
    width: ${spacing.s12};
  `,
  w_16: css`
    width: ${spacing.s16};
  `,
  w_auto: css`
    width: auto;
  `,
  w_px: css`
    width: 1px;
  `,
  w_full: css`
    width: 100%;
  `,
  w_screen: css`
    width: 100vw;
  `,

  min_w_unset: css`
    min-width: unset;
  `,
  max_w_xs: css`
    max-width: 20rem;
  `,
  max_w_sm: css`
    max-width: 24rem;
  `,
  max_w_md: css`
    max-width: 28rem;
  `,
  max_w_lg: css`
    max-width: 32rem;
  `,
  max_w_xl: css`
    max-width: 36rem;
  `,
  max_w_2xl: css`
    max-width: 42rem;
  `,
  max_w_3xl: css`
    max-width: 48rem;
  `,
  max_w_4xl: css`
    max-width: 56rem;
  `,
  max_w_5xl: css`
    max-width: 64rem;
  `,
  max_w_6xl: css`
    max-width: 72rem;
  `,
  max_w_full: css`
    max-width: 100%;
  `,

  h: h,
  min_h: min_h,
  max_h: max_h,

  h_0: css`
    height: ${spacing.s0};
  `,
  h_1: css`
    height: ${spacing.s1};
  `,
  h_2: css`
    height: ${spacing.s2};
  `,
  h_3: css`
    height: ${spacing.s3};
  `,
  h_4: css`
    height: ${spacing.s4};
  `,
  h_5: css`
    height: ${spacing.s5};
  `,
  h_6: css`
    height: ${spacing.s6};
  `,
  h_8: css`
    height: ${spacing.s8};
  `,
  h_10: css`
    height: ${spacing.s10};
  `,
  h_12: css`
    height: ${spacing.s12};
  `,
  h_16: css`
    height: ${spacing.s16};
  `,

  h_auto: css`
    height: auto;
  `,
  h_px: css`
    height: 1px;
  `,
  h_full: css`
    height: 100%;
  `,
  h_screen: css`
    height: 100vh;
  `,

  min_h_0: css`
    min-height: 0;
  `,
  min_h_full: css`
    min-height: 100%;
  `,
  min_h_screen: css`
    min-height: 100vh;
  `,
  max_h_full: css`
    max-height: 100%;
  `,
  max_h_screen: css`
    max-height: 100vh;
  `,
};
