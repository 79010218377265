import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import { OrderHistoryEvent } from '../../../contexts/OrderContext';
import { AdminEventList } from '../components/AdminEventList';
import { AdminEventListItem } from '../components/AdminEventListItem';

export const AdminOrderDetailsEventLog = (props: { events?: OrderHistoryEvent[] }) => {
  if (!props.events || props.events.length === 0) return null;
  return (
    <AdminEventList>
      {props.events.map((event, i) => {
        let eventLabel = ``;
        switch (event.type) {
          case 'created':
            eventLabel = `Order created`;
            break;
          case 'active':
            eventLabel = `Order accepted`;
            break;
          case 'pending':
            eventLabel = `Order pending`;
            break;
          case 'submitted':
            eventLabel = `Order submitted`;
            break;
          case 'revision':
            eventLabel = `Revision requested`;
            break;
          case 'canceled':
            eventLabel = `Order canceled`;
            break;
          case 'completed':
            eventLabel = `Order completed`;
            break;

          case 'issueRaised':
            switch (event.issueType) {
              case 'comment':
                eventLabel = `Comment issue raised by ${event.initiator}`;
                break;
              case 'payment_distribution_failure':
                eventLabel = `Payment distribution failed`;
                break;
              case 'payment_network_failure':
                eventLabel = `Payment network error`;
                break;
              case 'payment_settlement_failure':
                eventLabel = `Payment settlement failed`;
                break;
              case 'negative_commissioner_experience':
                eventLabel = 'Negative commissioner experience';
                break;
            }
            break;

          case 'issueBeingReviewed':
            eventLabel = `Negative commissioner experience being reviewed by ${event.initiator}`;
            break;

          case 'issueResolved':
            switch (event.issueType) {
              case 'comment':
                eventLabel = `Comment issue resolved by ${event.initiator}`;
                break;
              case 'payment_distribution_failure':
                eventLabel = `Payment distribution issue resolved by ${event.initiator}`;
                break;
              case 'payment_network_failure':
                eventLabel = `Payment issue resolved by ${event.initiator}`;
                break;
              case 'payment_settlement_failure':
                eventLabel = `Payment settlement issue resolved by ${event.initiator}`;
                break;
              case 'negative_commissioner_experience':
                eventLabel = `Negative commissioner experience issue resolved by ${event.initiator}`;
                break;
            }
            break;

          case 'commentDeleted':
            eventLabel = `Comment deleted by ${event.initiator}`;
            break;

          case 'paymentCancelationSubmitted':
            eventLabel = `Payment cancelation submitted`;
            break;
          case 'paymentDistributed':
            eventLabel = `Payment distributed by ${event.initiator}`;
            break;
          case 'paymentDistributionSubmitted':
            eventLabel = `Payment distribution submitted`;
            break;
          case 'paymentRefunded':
            eventLabel = `Payment refunded by ${event.initiator}`;
            break;
          case 'paymentSettled':
            eventLabel = `Payment settled`;
            break;

          case 'sharingChanged':
            eventLabel = `Sharing ${event.state ? 'enabled' : 'disabled'} by ${event.initiator}`;
            break;

          case 'completedCommissionRatingFollowUp':
            eventLabel = `Link to rate completed commission has been generated by ${event.initiator}`;
            break;
        }

        return <AdminEventListItem key={i} label={eventLabel} timeStamp={event.time} />;
      })}
    </AdminEventList>
  );
};
