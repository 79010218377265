import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';

export const AddCircle = (props: {}) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
      <circle css={[t.stroke_current]} cx="20" cy="20" r="19" stroke="#9F9B94" strokeWidth="2" />
      <path
        css={[t.fill_current]}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 8H21V18.1768L21.8232 19H32V21H21.8229L21 21.8229V32H19V21.8229L18.1771 21H8V19H18.1768L19 18.1768V8Z"
      />
    </svg>
  );
};
