import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';

export const LogoutIcon = (props: {}) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" {...props}>
      <path css={[t.stroke_current]} d="M15 14L20 9.5L15 5" />
      <rect css={[t.fill_current]} x="6" y="9" width="14" height="1" />
      <rect css={[t.fill_current]} width="13" height="1" />
      <rect css={[t.fill_current]} y="19" width="13" height="1" />
      <rect css={[t.fill_current]} width="1" height="20" />
      <rect css={[t.fill_current]} x="12" y="13" width="1" height="7" />
      <rect css={[t.fill_current]} x="12" width="1" height="7" />
    </svg>
  );
};
