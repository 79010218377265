import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../theme/admin/adminStyles';
import { Link, useLocation } from 'react-router-dom';
import { useAccount } from '../../contexts/AccountContext';
import { AdminButton } from './components/AdminButton';
import { DownwriteIcon } from '../../icons/DownwriteIcon';
import { FirebaseSDK } from '../../contexts/FirebaseContext';

export const AdminSidebar = () => {
  const account = useAccount();
  if (!account) return null;

  const logout = (event: React.MouseEvent) => {
    FirebaseSDK.auth().signOut();
  };

  return (
    <aside css={[t.max_h_screen, t.min_h_screen, t.overflow_y_auto, t.flex, t.flex_initial, t.flex_col, t.bg_dark_2, t.w('194px')]}>
      <div css={[t.flex, t.flex_col, t.flex_auto, t.pt_6, t.firstType('div', [t.border_none, t.mt_0])]}>
        <Link to="/" css={[t.mb_3, t.pl_6, t.no_underline, t.cursor_pointer, t.text_tint_5, t.visited(t.text_tint_5)]}>
          <DownwriteIcon />
        </Link>
        <AdminSidebarSection>
          {/* <AdminLink to="/admin/overview">Dashboard</AdminLink> */}
          <AdminLink to="/admin/users">Users</AdminLink>
          <AdminLink to="/admin/artists">Artists</AdminLink>
          <AdminLink to="/admin/orders">Orders</AdminLink>
          <AdminLink to="/admin/songs">Songs</AdminLink>
        </AdminSidebarSection>

        <AdminSidebarSection label="Content">
          <AdminLink to="/admin/discovery">Discovery</AdminLink>
          <AdminLink to="/admin/featured">Featured</AdminLink>
          <AdminLink to="/admin/occasions">Occasions</AdminLink>
        </AdminSidebarSection>

        <AdminSidebarSection label="Site">
          <AdminLink to="/admin/sitesettings">Settings</AdminLink>
          <AdminLink to="/admin/siteswitches">Switches</AdminLink>
          <AdminLink to="/admin/adminpermissions">Permissions</AdminLink>
        </AdminSidebarSection>

        <AdminSidebarSection label="Developers">
          <AdminLink to="/admin/dev">Tools</AdminLink>
          <AdminLink to="/admin/components">Components</AdminLink>
          <AdminLink to="/admin/animationTests">Animation</AdminLink>
        </AdminSidebarSection>
      </div>
      <div css={[t.flex_none, t.flex, t.flex_col, t.border_t, t.border_solid, t.border_dark_4, t.pt_6, t.pb_6, t.px_6]}>
        <div css={[t.text_tint_2, t.text_xs, t.mb_3]}>{account.User.email}</div>
        <AdminButton type="button" styleType="secondaryOnDark" label="Sign out" onClick={logout} innerStyle={[t.w_full]} />
      </div>
    </aside>
  );
};

const AdminSidebarSection = (props: { label?: string; children?: React.ReactNode }) => {
  return (
    <div css={[t.mt_4, t.flex, t.flex_col, t.border_t, t.border_solid, t.border_dark_4]}>
      {props.label && <div css={[t.pl_6, t.mt_6, t.mb_3, t.text_tint_2, t.text_base]}>{props.label}</div>}
      {props.children}
    </div>
  );
};

const AdminLink = (props: { to: string; children?: React.ReactNode }) => {
  const { pathname } = useLocation();
  const active = pathname === props.to || pathname === `${props.to}/`;

  const activeStyle = active ? [t.text_primary_5] : [t.text_tint_1];

  return (
    <Link
      to={props.to}
      css={[t.pl_6, t.py_2, t.text_sm, activeStyle, t.select_none, t.no_underline, t.cursor_pointer, t.visited(activeStyle), t.hover(t.bg_dark_3)]}
    >
      {props.children}
    </Link>
  );
};

export default AdminSidebar;
