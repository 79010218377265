import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t, { CSSStyle } from '../../theme/newstyles';
import { v4 as uuid } from 'uuid';
import { useTipController } from '../../contexts/TipContext';

export type TipProps = {
  isOpen?: boolean;
  toggleOpen: () => void;
};

export function Tip(props: {
  defaultOpen?: boolean;
  onToggle?: (uid: string | null) => void;
  children?: (tipProps: TipProps) => React.ReactNode;
  containerStyle?: CSSStyle;
  closeOnScroll?: boolean;
}) {
  const defaultOpen = props.defaultOpen;
  const [uid] = React.useState(uuid());
  const tipController = useTipController();
  const showTip = tipController.show;

  const ToggleOpen = React.useCallback(() => {
    if (tipController.isShown(uid)) tipController.show(null);
    else tipController.show(uid);
  }, [uid, tipController]);

  React.useEffect(() => {
    if (defaultOpen) showTip(uid);
  }, [defaultOpen, showTip, uid]);

  React.useEffect(() => {
    const closeOnScrollHandler = (): void => showTip(null);

    if (props.closeOnScroll) {
      window.addEventListener('scroll', closeOnScrollHandler);
    }
    return () => {
      window.removeEventListener('scroll', closeOnScrollHandler);
    };
  }, [props.closeOnScroll, showTip]);

  return <div css={[t.relative, props.containerStyle]}>{props.children && props.children({ isOpen: tipController.isShown(uid), toggleOpen: ToggleOpen })}</div>;
}
