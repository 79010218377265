import React, { useState, useRef } from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';
import { InputErrorInline } from './InputErrorInline';
import { Button } from './Button';
import { CheckboxProps } from './CheckboxGroup';

export function MultiSelect<U extends { [key: string]: string }>(props: {
  id: string;
  options: U;
  values?: string[];
  label?: string;
  disabled?: boolean;
  invalid?: boolean;
  error?: string;
  sortingFunction?: (a: string, b: string) => number;
  listItemRender: (checkboxProps: CheckboxProps<U>) => React.ReactNode;
  optionsListOnly?: boolean;
}) {
  const ref = useRef<HTMLDivElement | null>(null);

  const [open, SetOpen] = useState(false);
  const [listFocusIndex, SetListFocusIndex] = useState<number | undefined>(undefined);
  const disabled = props.disabled;

  const values = props.values || [];

  const optionsKeys = Object.keys(props.options);

  if (props.sortingFunction) {
    optionsKeys.sort(props.sortingFunction);
  } else {
    optionsKeys.sort();
  }

  function HandleClick(event: React.MouseEvent) {
    if (!open) {
      SetListFocusIndex(listFocusIndex === undefined ? 0 : listFocusIndex);
    }
    SetOpen(!open);
  }

  function HandleKeyDown(event: React.KeyboardEvent) {
    if (open) {
      switch (event.key) {
        case 'ArrowDown': {
          if (optionsKeys.length > 0) {
            let newIndex = 0;
            if (listFocusIndex !== undefined) {
              if (listFocusIndex === optionsKeys.length - 1) newIndex = listFocusIndex;
              else newIndex = listFocusIndex + 1;
            }
            SetListFocusIndex(listFocusIndex === undefined ? 0 : newIndex);
          }

          break;
        }
        case 'ArrowUp': {
          if (listFocusIndex !== undefined) {
            if (listFocusIndex !== 0) SetListFocusIndex(listFocusIndex - 1);
          }

          break;
        }
        case 'Escape':
        case 'Tab': {
          SetOpen(!open);
        }
      }
    }
  }

  return props.optionsListOnly ? (
    <ul>
      {optionsKeys.map((option, i) => {
        const checked = values.includes(option);

        return (
          <SelectCheckbox key={i} focus={listFocusIndex === i}>
            {props.listItemRender({
              label: props.options[option],
              value: option,
              checked: checked,
              disabled: disabled,
              focused: listFocusIndex === i,
            })}
          </SelectCheckbox>
        );
      })}
    </ul>
  ) : (
    <React.Fragment>
      <div ref={ref} onKeyDown={HandleKeyDown} css={[t.relative]}>
        <Button
          type="button"
          label={props.label}
          onClick={HandleClick}
          styleType="secondary"
          innerStyle={open ? [t.border_primary_4, t.text_primary_4] : null}
        />
        <TagInputDropdown open={open && !disabled} setShowModal={SetOpen}>
          {optionsKeys.map((option, i) => {
            const checked = values.includes(option);

            return (
              <SelectCheckbox key={i} focus={listFocusIndex === i}>
                {props.listItemRender({
                  label: props.options[option],
                  value: option,
                  checked: checked,
                  disabled: disabled,
                  focused: listFocusIndex === i,
                })}
              </SelectCheckbox>
            );
          })}
        </TagInputDropdown>
      </div>
      {props.error && <InputErrorInline>{props.invalid && props.error}</InputErrorInline>}
    </React.Fragment>
  );
}

const SelectCheckbox = (props: { focus?: boolean; children?: React.ReactNode }) => {
  const focus = !!props.focus;
  return <li css={[t.pl_4, t.py_3, focus ? t.bg_tint_4 : null, t.hover([t.bg_tint_4])]}>{props.children}</li>;
};

const TagInputDropdown = (props: { open: boolean; children?: React.ReactNode; setShowModal: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const isOpen = props.open;
  const setShowModal = props.setShowModal;
  React.useEffect(() => {
    const handleClickOutsideModal = (event: MouseEvent) => {
      if (isOpen && containerRef.current && event.target instanceof Node && !containerRef.current.contains(event.target)) {
        setShowModal(false);
      }
    };

    document.addEventListener('click', handleClickOutsideModal);
    return () => {
      document.removeEventListener('click', handleClickOutsideModal);
    };
  }, [setShowModal, isOpen]);

  if (!isOpen) return null;

  return (
    <div ref={containerRef} css={[t.absolute, t.bottom_0, t.inset_x_0]}>
      <ul
        css={[
          t.absolute,
          t.left_0,
          t.mt('18px'),
          t.pt('7px'),
          t.pb_4,
          t.bg_tint_5,
          t.border,
          t.border_solid,
          t.border_primary_4,
          t.z_50,
          t.overflow_y_scroll,
          t.min_w('300px'),
          t.max_h('300px'),
        ]}
      >
        {props.children}
      </ul>
    </div>
  );
};

export default MultiSelect;
