import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { useArtistProfile } from '../../../contexts/ArtistProfileContext';
import { useUserAccount } from '../../../contexts/UserAccountContext';
import { AdminDetailsDialogInfoBlock } from '../components/Dialogs/AdminDetailsDialogInfoBlock';
import { AdminDetailsDialogInfo } from '../components/Dialogs/AdminDetailsDialogInfo';

export const AdminArtistDetailsAccounting = () => {
  const artistProfile = useArtistProfile();
  const artistAccount = useUserAccount();
  const profileData = artistProfile.Profile;

  if (!profileData) return null;
  if (!artistAccount) return null;

  return (
    <div css={[t.border_b, t.border_solid, t.border_tint_3, t.py_5, t.px_6, t.flex, t.flex_col]}>
      <div css={[t.flex, t.flex_row]}>
        <AdminArtistDetailsTaxAddress />
        <AdminArtistDetailsPaypayInfo />
        <AdminArtistDetailsPROInfo />
      </div>
    </div>
  );
};

const AdminArtistDetailsTaxAddress = () => {
  const artistProfile = useArtistProfile();
  const profileData = artistProfile.Profile;

  if (!profileData) return null;

  return (
    <AdminDetailsDialogInfoBlock label="Tax Address">
      {profileData.taxAddress ? (
        <React.Fragment>
          <div css={[t.flex, t.flex_row]}>
            <AdminDetailsDialogInfo label="First Name" text={profileData.taxAddress.FirstName} containerStyle={[t.mr_5]} />
            <AdminDetailsDialogInfo label="Last Name" text={profileData.taxAddress.LastName} />
          </div>
          <div css={[t.mt_2]}>
            <AdminDetailsDialogInfo label="Phone Number" text={profileData.taxAddress.PhoneNumber} />
          </div>
          <div css={[t.flex, t.flex_row, t.mt_2]}>
            <AdminDetailsDialogInfo label="Street Address" text={profileData.taxAddress.StreetAddress} containerStyle={[t.mr_5]} />
            {profileData.taxAddress.StreetAddress2 && <AdminDetailsDialogInfo label="Street Address 2" text={profileData.taxAddress.StreetAddress2} />}
          </div>
          <div css={[t.flex, t.flex_row, t.mt_2]}>
            <AdminDetailsDialogInfo label="City" text={profileData.taxAddress.City} containerStyle={[t.mr_5]} />
            <AdminDetailsDialogInfo label="State" text={profileData.taxAddress.State} containerStyle={[t.mr_5]} />
            <AdminDetailsDialogInfo label="Postal Code" text={profileData.taxAddress.PostalCode} />
          </div>
          <div css={[t.flex, t.flex_row, t.mt_2]}>
            <AdminDetailsDialogInfo label="Country" text={profileData.taxAddress.CountryName} />
          </div>
        </React.Fragment>
      ) : (
        <div css={[t.text_sm, t.font_normal, t.text_tint_2]}>No info</div>
      )}
    </AdminDetailsDialogInfoBlock>
  );
};

const AdminArtistDetailsPaypayInfo = () => {
  const artistProfile = useArtistProfile();
  const profileData = artistProfile.Profile;

  if (!profileData) return null;

  return (
    <AdminDetailsDialogInfoBlock label="PayPal">
      {profileData.paypalId !== '' ? (
        <React.Fragment>
          <div css={[t.flex, t.flex_row]}>
            <AdminDetailsDialogInfo label="Id" text={profileData.paypalId} />
          </div>
          <div css={[t.flex, t.flex_row, t.mt_2]}>
            <AdminDetailsDialogInfo label="Email" text={profileData.paypalEmail} />
          </div>
        </React.Fragment>
      ) : (
        <div css={[t.text_sm, t.font_normal, t.text_tint_2]}>No info</div>
      )}
    </AdminDetailsDialogInfoBlock>
  );
};

const AdminArtistDetailsPROInfo = () => {
  const artistProfile = useArtistProfile();
  const profileData = artistProfile.Profile;

  if (!profileData) return null;

  return (
    <AdminDetailsDialogInfoBlock label="PRO">
      {profileData.proAffiliation ? (
        <React.Fragment>
          <div css={[t.flex, t.flex_row]}>
            <AdminDetailsDialogInfo label="Affiliation" text={profileData.proAffiliation.affiliation} />
          </div>
          <div css={[t.flex, t.flex_row, t.mt_2]}>
            <AdminDetailsDialogInfo label="Id" text={profileData.proAffiliation.number} />
          </div>
        </React.Fragment>
      ) : (
        <div css={[t.text_sm, t.font_normal, t.text_tint_2]}>No info</div>
      )}
    </AdminDetailsDialogInfoBlock>
  );
};
