import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';

export const PopupIcon = (props: {}) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" {...props}>
      <path css={[t.fill_current]} d="M0,48H39.9V8.1H0ZM35.77,12.23V43.87H4.13V12.23Z" />
      <polygon css={[t.fill_current]} points="8.1 0 8.1 4.13 43.87 4.13 43.87 39.9 48 39.9 48 0 8.1 0" />
    </svg>
  );
};
