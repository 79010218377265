import * as React from 'react';
import { FirebaseSDK } from './FirebaseContext';

interface AdminAccount {
  permissions: string[];
  searchKey: string;
}

const AdminAccountContext = React.createContext<AdminAccount | null>(null);
export function AdminAccountProvider(props: { userId: string; children: React.ReactNode }) {
  const [account, setAccount] = React.useState<AdminAccount | null>(null);
  React.useEffect(() => {
    if (props.userId === '') {
      setAccount(null);
      return;
    }

    const observer = (snapshot: firebase.default.firestore.DocumentSnapshot) => {
      setAccount(snapshot.data() as AdminAccount);
    };

    const error = (error: Error) => {
      setAccount(null);
    };

    const accountDoc = FirebaseSDK.firestore().doc(`/admins/${props.userId}`);
    return accountDoc.onSnapshot(observer, error);
  }, [props.userId]);
  return <AdminAccountContext.Provider value={account}>{props.children}</AdminAccountContext.Provider>;
}

export function useAdminAccount() {
  return React.useContext(AdminAccountContext);
}
