import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../theme/newstyles';
import { MainContainer } from '../../components/MainContainer';

export interface SocialMediaLink {
  url: string;
  label: string;
  icon: JSX.Element;
}

export const ArtistPageSocialMedia = (props: { links?: SocialMediaLink[] }) => {
  if (!props.links || props.links.length === 0) return null;

  const socialLinks = props.links.map((value, index) => {
    return <SocialMediaLink key={index} to={`${value.url}`} label={value.label} icon={value.icon} />;
  });

  return (
    <MainContainer containerCSS={[t.mt_1, t.pl_0, t.md([t.pl_0])]}>
      <div css={[t.flex_auto, t.flex, t.flex_row, t.justify_start]}>{socialLinks}</div>
    </MainContainer>
  );
};

const SocialMediaLink = (props: { to: string; label: string; icon: JSX.Element }) => {
  return (
    <a href={props.to} target="_blank" rel="noopener noreferrer" aria-label={props.label} css={[t.mr_4, t.first(t.ml_0), t.sm([t.mr_0, t.ml_4])]}>
      <div css={[t.text_dark_1]}>{props.icon}</div>
    </a>
  );
};
