import React, { useState } from 'react'; // eslint-disable-line
import ReactPlayer from 'react-player';

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../theme/newstyles';

interface ReactPlayerState {
  played: number;
  playedSeconds: number;
  loaded: number;
  loadedSeconds: number;
}

export const ReactPlayerComponent = (props: { url: string }) => {
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0.8);
  const [muted, setMuted] = useState(false);
  const [duration, setDuration] = useState(0);
  const [playerState, setPlayerState] = useState<ReactPlayerState>({ played: 0, playedSeconds: 0, loaded: 0, loadedSeconds: 0 });
  const [loop, setLoop] = useState(false);

  const handleDuration = (duration: number) => {
    setDuration(duration);
  };

  const handleProgress = (state: ReactPlayerState) => {
    setPlayerState(state);
  };

  return (
    <React.Fragment>
      <ReactPlayer
        url={props.url}
        playing={playing}
        controls={false}
        width="10px"
        height="10px"
        volume={volume}
        duration={duration}
        onDuration={handleDuration}
        onProgress={handleProgress}
        muted={muted}
        loop={loop}
      />
      <div>
        <button onClick={() => setPlaying(true)}>Play</button>
        <button onClick={() => setPlaying(false)}>Pause</button>
        <button onClick={() => (volume < 0.9 ? setVolume(volume + 0.1) : setVolume(1))}>volume +</button>
        <button onClick={() => (volume > 0.1 ? setVolume(volume - 0.1) : setVolume(0))}>volume -</button>
        <button onClick={() => (!muted ? setMuted(true) : setMuted(false))}>Mute</button>
        <button onClick={() => (!loop ? setLoop(true) : setLoop(false))}>Loop</button>
        <div css={t.block}>Duration: {duration}</div>
        <div css={t.block}> Played: {playerState.playedSeconds}</div>
        <div css={t.block}>Time Remaining: {duration - playerState.playedSeconds}</div>
      </div>
    </React.Fragment>
  );
};
