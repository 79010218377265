import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { useAccount } from '../contexts/AccountContext';
import { TextField } from '../components/TextField';
import { Button } from '../components/Button';
import { Formik, Form, FormikProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import * as H from 'history';
import { MainContainer } from '../components/MainContainer';
import { MainPage } from '../components/MainPage';
import { Helmet } from 'react-helmet-async';
import { ErrorMessage } from '../components/ErrorMessage';
import { FirebaseSDK } from '../contexts/FirebaseContext';
import useOrderProposal from '../contexts/OrderProposalContext';

interface FormData {
  email: string;
  password: string;
}

const Schema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required Field'),
  password: Yup.string().required('Required Field'),
});

const initialValues = { email: '', password: '' };

export const SignInPage = (props: unknown) => {
  const account = useAccount();
  const location = useLocation<{
    packageId?: string;
    artistRoute?: string;
    orderId?: string;
    orderType?: string;
    artistName: string;
    isUpdatingRating?: boolean;
    isRatingCompletedCommission?: boolean;
  }>();
  const [redirect, setRedirect] = React.useState<H.LocationState | null>(null);
  const [error, setError] = React.useState('');
  const proposalContext = useOrderProposal();
  const formName = 'signin';

  let redirectReason = '';
  if (location && location.state) {
    if (location.state.packageId && location.state.artistRoute) {
      redirectReason = 'isCommissioning';
    } else if (location.state.orderId && location.state.orderType) {
      redirectReason = 'isViewingCommission';
    } else if (location.state.orderId && location.state.artistName && location.state.isUpdatingRating) {
      redirectReason = 'isUpdatingRating';
    } else if (location.state.orderId && location.state.isRatingCompletedCommission) {
      redirectReason = 'isRatingCompletedCommission';
    }
  }

  let redirectPath: React.SetStateAction<H.History.UnknownFacade>;

  switch (redirectReason) {
    case 'isCommissioning':
      redirectPath = { pathname: `/artists/${location.state.artistRoute}/request`, state: { packageId: location.state.packageId } };
      break;
    case 'isViewingCommission':
      redirectPath = {
        pathname: `/share/${location.state.orderType}/${location.state.orderId}`,
        state: { orderType: location.state.orderType, orderId: location.state.orderId },
      };
      break;
    case 'isUpdatingRating':
      redirectPath = {
        pathname: `/update-rating/${location.state.orderId}/${location.state.artistName}`,
        state: { orderId: location.state.orderId, artistName: location.state.artistName },
      };
      break;
    case 'isRatingCompletedCommission':
      redirectPath = {
        pathname: `/rate-completed-commission/${location.state.orderId}`,
        state: { orderId: location.state.orderId },
      };
      break;
    default:
      redirectPath = { pathname: '/' };
      break;
  }

  async function handleSubmit(formData: FormData, actions: FormikHelpers<FormData>) {
    let credentials;
    try {
      credentials = await FirebaseSDK.auth().signInWithEmailAndPassword(formData.email, formData.password);
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        actions.setFieldError('password', 'Incorrect password');
      } else if (error.code === 'auth/user-not-found') {
        actions.setFieldError('email', 'This email is not registered as a Downwrite account');
      } else if (error.code === 'auth/user-disabled') {
        actions.setFieldError('email', 'This account has been disabled');
      } else setError(error.message);
    }
    if (credentials && credentials.user && !error && proposalContext.guestProposals?.length) {
      proposalContext.claimGuestProposals(credentials.user.uid);
    }
    setRedirect(redirectPath);
  }

  React.useEffect(() => {
    if (account) setRedirect(redirectPath);
  }, [account, redirectPath]);

  if (account && redirect) return <Redirect to={redirect} />;

  return (
    <MainPage>
      <Helmet>
        <title>Downwrite - Sign in</title>
      </Helmet>
      <MainContainer>
        <Formik initialValues={initialValues} validationSchema={Schema} validateOnMount={true} onSubmit={handleSubmit}>
          {(formikProps: FormikProps<FormData>) => {
            const submitDisabled = !formikProps.isValid || formikProps.isSubmitting;
            return (
              <Form noValidate={true}>
                <div css={[t.mt_6, t.mb_10, t.breakpointGrid_12]}>
                  <div css={[t.sm([t.col_span_12]), t.md([t.col_span_8, t.col_start_2]), t.lg([t.col_span_6, t.col_start_2])]}>
                    <h1 css={[t.gridTypeStyle_3]}>
                      {redirectReason === 'isCommissioning'
                        ? `To get started with a commission, please log in to your Downwrite account`
                        : `Sign in to Downwrite`}
                    </h1>
                    <div css={[t.mt_6]}>
                      <TextField<FormData> formName={formName} name="email" label="Email" containerStyle={[t.min_w_unset, t.mb_4]} />
                      <TextField<FormData> formName={formName} name="password" type="password" label="Password" containerStyle={[t.min_w_unset]} />
                    </div>
                    <ErrorMessage>{error}</ErrorMessage>
                    <div
                      css={[
                        t.fixed,
                        t.inset_x_0,
                        t.bottom_0,
                        t.bg_tint_5,
                        t.border_t,
                        t.border_solid,
                        t.border_tint_3,
                        t.p_5,
                        t.flex,
                        t.sm([t.relative, t.col_span_12, t.p_0, t.mt_6, t.border_0]),
                      ]}
                    >
                      <Button
                        type="button"
                        buttonType="submit"
                        disabled={submitDisabled}
                        label="Sign in"
                        outerStyle={[t.flex_auto, t.sm([t.flex_initial])]}
                        innerStyle={[t.w_full, t.sm(t.w_auto)]}
                        id="SignInButton"
                      />
                    </div>
                    <div css={[t.flex, t.flex_col, t.mt_6]}>
                      <Link to="/forgot-password">Forgot password?</Link>
                    </div>
                    <div css={[t.flex, t.items_center, t.mt_6, t.sm([t.hidden])]}>
                      <div css={[t.mr_3, t.typeStyle_6, t.text_dark_1]}>{`Don't have an account?`}</div>
                      <Link to={{ pathname: '/signup', state: location.state }}>Sign up</Link>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </MainContainer>
    </MainPage>
  );
};

export default SignInPage;
