import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { FeaturedArtist } from '../../../contexts/FeaturedArtistContentContext';
import { ShuffleDirection } from '../components/AdminShuffleCard';
import { AdminArtistDetailsDialog } from './AdminArtistDetailsDialog';
import { AdminArtistShuffleCard } from './AdminArtistShuffleCard';
import { AdminButton } from '../components//AdminButton';
import { ArtistListDialog, ArtistSearchHitProps } from '../components/Dialogs/ArtistListDialog';
import { AdminSearchTableCell } from '../components/AdminSearchTableCell';
import { AdminSearchTableActionCell } from '../components/AdminSearchTableActionCell';

export const AdminArtistList = (props: {
  artists: FeaturedArtist[];
  onAddArtist?: (artistId: string) => void;
  onRemoveArtist?: (artistId: string) => void;
  onArtistShuffle?: (artistId: string, direction: ShuffleDirection) => void;
  label?: string;
  dialogLabel?: string;
}) => {
  const [detailsActive, SetDetailsActive] = React.useState(false);
  const [isArtistListOpen, setArtistListOpen] = React.useState(false);
  const [objectID, SetObjectID] = React.useState<string | null>(null);

  function HandleDetailsClick(objectID: string) {
    SetObjectID(objectID);
    SetDetailsActive(true);
  }

  function HandleClose(event: React.MouseEvent | React.KeyboardEvent) {
    event.stopPropagation();
    SetDetailsActive(false);
  }

  const handleArtistList = () => {
    setArtistListOpen(true);
  };

  const handleArtistListCancel = () => {
    setArtistListOpen(false);
  };

  return (
    <React.Fragment>
      <div css={[t.flex, t.flex_col]}>
        <div css={[t.p_6, t.bg_tint_3, t.shadow_md_inset]}>
          <div css={[t.flex, t.flex_col, t.shadow_md]}>
            <div css={[t.p_2, t.pl_4, t.flex, t.flex_row, t.bg_tint_2]}>
              <div css={[t.flex_auto, t.flex, t.flex_row, t.items_center]}>
                {props.label ? <div css={[t.text_sm, t.text_dark_2]}>{props.label}</div> : null}
              </div>
              <div css={[t.flex_none]}>
                <AdminButton type="button" label="Add artist" onClick={handleArtistList} />
              </div>
            </div>
            {props.artists.map((artist, i, array) => {
              const handleShuffle = (direction: ShuffleDirection) => {
                if (props.onArtistShuffle) props.onArtistShuffle(artist.id, direction);
              };

              const HandleDelete = (event: React.MouseEvent) => {
                if (props.onRemoveArtist) props.onRemoveArtist(artist.id);
              };
              return (
                <AdminArtistShuffleCard
                  key={i}
                  artist={artist}
                  first={i === 0}
                  last={i === array.length - 1}
                  onDetailsClick={HandleDetailsClick}
                  onShuffle={handleShuffle}
                  onDelete={HandleDelete}
                />
              );
            })}
          </div>
        </div>
      </div>
      <AdminArtistDetailsDialog isOpen={detailsActive} userId={objectID} onClose={HandleClose} />
      <ArtistListDialog isOpen={isArtistListOpen} label={props.dialogLabel} artists={props.artists} onClose={handleArtistListCancel}>
        {(artistHitProps: ArtistSearchHitProps) => {
          const HandleAddClick = () => {
            if (props.onAddArtist) props.onAddArtist(artistHitProps.objectID);
          };

          const HandleRemoveClick = () => {
            if (props.onRemoveArtist) props.onRemoveArtist(artistHitProps.objectID);
          };

          return (
            <React.Fragment>
              <AdminSearchTableCell>
                {artistHitProps.featured && <div css={[t.absolute, t.inset_y_0, t.left_0, t.border_solid, t.border_l_2, t.border_primary_4]} />}
              </AdminSearchTableCell>
              <AdminSearchTableCell>{artistHitProps.artistHit.name}</AdminSearchTableCell>
              <AdminSearchTableCell>{artistHitProps.artistHit.artistId}</AdminSearchTableCell>
              <AdminSearchTableCell>{artistHitProps.objectID}</AdminSearchTableCell>
              <AdminSearchTableActionCell cellStyle={[t.py_2]} innerStyle={[t.pr_0]}>
                {artistHitProps.featured ? (
                  <AdminButton type="button" label="Remove" styleType="critical" onClick={HandleRemoveClick} />
                ) : (
                  <AdminButton type="button" label="Add" styleType="secondary" onClick={HandleAddClick} />
                )}
              </AdminSearchTableActionCell>
            </React.Fragment>
          );
        }}
      </ArtistListDialog>
    </React.Fragment>
  );
};
