/** @jsx jsx */
import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/core'; // eslint-disable-line

import { ArtistPageSearchRecord } from '../types/shared-types';
import { publicDownloadUrlBase } from './FirebaseContext';

export interface FeaturedArtists {
  featured: ArtistPageSearchRecord[];
}

const FeaturedArtistsContext = React.createContext<FeaturedArtists | null>(null);
export function FeaturedArtistsProvider(props: { children?: React.ReactNode }) {
  const [state, setState] = React.useState<FeaturedArtists | null>(null);
  React.useEffect(() => {
    const asyncWrapper = async () => {
      const url = `${publicDownloadUrlBase}/pages/featured/pagedata`;
      try {
        const fetchResult = await fetch(url);
        const fetchJson = await fetchResult.json();
        setState(fetchJson as FeaturedArtists);
      } catch (error) {
        // Do nothing...
      }
    };
    asyncWrapper();
  }, []);
  return <FeaturedArtistsContext.Provider value={state}>{props.children}</FeaturedArtistsContext.Provider>;
}

export function useFeaturedArtists() {
  return React.useContext(FeaturedArtistsContext);
}
