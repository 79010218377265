import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { CheckboxIcon } from '../../../icons/CheckboxIcon';

export function AdminCheckbox(props: {
  value?: string | number;
  checked?: boolean;
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}) {
  return (
    <div>
      <label css={[t.flex, t.items_center, t.mr_4, t.cursor_pointer]}>
        <input css={[t.hidden_input]} type="checkbox" checked={props.checked} value={props.value} onChange={props.onChange} />
        <span css={[t.text_primary_4, t.size('18px'), t.flex, t.flex_col, t.justify_center, t.items_center, t.border, t.border_solid, t.border_tint_1]}>
          <CheckboxIcon checked={props.checked} css={[t.size('12px')]} />
        </span>
        {props.label ? <span css={[t.ml_2, t.select_none, t.whitespace_no_wrap]}>{props.label}</span> : null}
      </label>
    </div>
  );
}
