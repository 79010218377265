import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t, { CSSStyle } from '../theme/newstyles';

export const MainContainer = (props: {
  children?: React.ReactNode;
  containerCSS?: CSSStyle;
  centerContainerCSS?: CSSStyle;
  innerCSS?: CSSStyle;
  leftCSS?: CSSStyle;
  rightCSS?: CSSStyle;
}) => {
  return (
    <div css={[t.content_container, t.relative, t.flex_auto, t.flex, t.flex_row, props.containerCSS]}>
      <div css={[t.max_w_page, t.mx_auto, t.flex_auto, t.w_full, props.centerContainerCSS]}>{props.children}</div>
    </div>
  );
};
