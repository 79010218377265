import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { useUserAccount } from '../../../contexts/UserAccountContext';
import { AdminActionsPopover } from '../components/AdminActionsPopover';
import { AdminActionsButton } from '../components/AdminActionButton';
import { useDiscoveryContent, SetMainFeatureArtist, AddOccasionArtist, RemoveOccasionArtist } from '../../../contexts/DiscoveryContentContext';
import { useFeaturedArtistsContent, AddFeaturedArtist, RemoveFeaturedArtist } from '../../../contexts/FeaturedArtistContentContext';
import { AdminCheckbox } from '../components/AdminCheckbox';
import { AdminCaretIcon } from '../icons/AdminCaretIcon';

export const AdminArtistFeaturedSelect = () => {
  const [actionsPopover, SetActionsPopover] = React.useState(false);
  const [popoverRect, SetPopoverRect] = React.useState<DOMRect | undefined>(undefined);
  const artistAccount = useUserAccount();
  const discoveryContent = useDiscoveryContent();
  const featuredArtistsContent = useFeaturedArtistsContent();

  if (!artistAccount) return null;

  const handleActionsClick = (rect: DOMRect | undefined, event: React.MouseEvent) => {
    SetActionsPopover(true);
    SetPopoverRect(rect);
  };

  const handleActionsClose = () => {
    SetActionsPopover(false);
  };

  let anyFeatured = false;
  const featuredOptions = ['Headliner', 'Featured'];

  if (discoveryContent) {
    for (const occasion of discoveryContent.occasions) {
      if (occasion.title !== '') featuredOptions.push(occasion.title);
    }
  }

  const currentFeaturedList: string[] = [];
  const featuredOptionElements = featuredOptions.map((value, index) => {
    const handleChange = async (event: React.FormEvent<HTMLInputElement>) => {
      console.log(event.currentTarget.value);
      if (value === 'Headliner') {
        if (event.currentTarget.checked) await SetMainFeatureArtist(artistAccount.id);
        else await SetMainFeatureArtist('');
      } else if (value === 'Featured') {
        if (event.currentTarget.checked) await AddFeaturedArtist(artistAccount.id);
        else await RemoveFeaturedArtist(artistAccount.id);
      } else if (discoveryContent) {
        const occasion = discoveryContent.occasions.find((x) => x.title === value);
        if (occasion) {
          if (event.currentTarget.checked) await AddOccasionArtist(occasion.id, artistAccount.id);
          else await RemoveOccasionArtist(occasion.id, artistAccount.id);
        }
      }
    };

    let checked = false;
    if (discoveryContent) {
      if (value === 'Headliner') {
        checked = discoveryContent.mainFeature.artist ? discoveryContent.mainFeature.artist.id === artistAccount.id : false;
        anyFeatured = anyFeatured || checked;
        if (checked) currentFeaturedList.push(value);
      } else if (value === 'Featured') {
        if (featuredArtistsContent) {
          checked = featuredArtistsContent.featured.findIndex((x) => x.id === artistAccount.id) !== -1;
          anyFeatured = anyFeatured || checked;
          if (checked) currentFeaturedList.push(value);
        }
      } else if (discoveryContent) {
        const occasion = discoveryContent.occasions.find((x) => x.title === value);
        if (occasion) {
          checked = occasion?.artists.findIndex((x) => x.id === artistAccount.id) !== -1;
          anyFeatured = anyFeatured || checked;
          if (checked) currentFeaturedList.push(value);
        }
      }
    }

    return (
      <li css={[t.inline, t.px_4, t.py_2, t.cursor_pointer, t.hover([t.bg_tint_3])]} key={index}>
        <AdminCheckbox label={value} checked={checked} value={value} onChange={handleChange} />
      </li>
    );
  });

  return (
    <div>
      <div css={[t.text_xs, t.text_tint_1, t.font_normal, t.mb_1]}>Feature in</div>
      <AdminActionsButton onClick={handleActionsClick}>
        <div css={[t.relative, t.flex]}>
          <div
            css={[
              t.flex,
              t.flex_row,
              t.py_3,
              t.pl_3,
              t.pr('36px'),
              t.border_1,
              t.border_tint_2,
              t.border_solid,
              t.text_xs,
              t.text_dark_4,
              t.hover(t.border_tint_1),
              t.focus(t.border_primary_4),
            ]}
          >
            {currentFeaturedList.length > 0 ? <div>{currentFeaturedList.join(', ')}</div> : <div css={[t.text_tint_1]}>None</div>}
          </div>
          <div css={[t.pointer_events_none, t.absolute, t.right_0, t.inset_y_0, t.flex, t.items_center, t.mr('12px')]}>
            <AdminCaretIcon direction={'down'} />
          </div>
        </div>

        {actionsPopover ? (
          <AdminActionsPopover anchor="left" positionRect={popoverRect} onClose={handleActionsClose}>
            <ul css={[t.pt_3, t.pb_2, t.flex, t.flex_col]}>{featuredOptionElements}</ul>
          </AdminActionsPopover>
        ) : null}
      </AdminActionsButton>
    </div>
  );
};
