import React, { Children, ReactNode } from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../theme/newstyles';
import { useThemeController } from '../../contexts/ThemeContext';
import { LogoutIcon } from '../../icons/LogoutIcon';
import { ListButton } from '../ListButton';
import { FirebaseSDK } from '../../contexts/FirebaseContext';

export const AccountDropdownList = (props: { children?: ReactNode }) => {
  const { dark } = useThemeController();

  const logout = (event: React.MouseEvent) => {
    event.stopPropagation();
    FirebaseSDK.auth().signOut();
  };

  return (
    <ul css={[t.flex_auto, t.pt_2, t.pb_4]}>
      {Children.toArray(props.children).map((child, i) => {
        return <li key={i}>{child}</li>;
      })}
      <div css={[t.my_2, t.mx_4, t.border_b, t.border_solid, dark ? t.border_dark_2 : t.border_tint_3]} />
      <li>
        <ListButton type="button" label="Sign out" icon={<LogoutIcon />} onClick={logout} id="SignOutButton" />
      </li>
    </ul>
  );
};
