import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { CSSStyle } from '../../../theme/newstyles';

export function AdminCard(props: { children?: React.ReactNode; containerStyle?: CSSStyle }) {
  return (
    <div css={[t.max_w_5xl, t.mb_8, t.flex, t.flex_col, t.w_full, props.containerStyle]}>
      <div css={[t.w_full, t.bg_white, t.shadow]}>{props.children}</div>
    </div>
  );
}
