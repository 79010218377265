import React from 'react'; // eslint-disable-line
/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';

export const LinkIcon = (props: unknown) => {
  return (
    <svg role="img" aria-labelledby="linkIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <title id="linkIconTitleID">Link</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.86949 12.3449L6.43478 14.7586C5.09009 16.0917 5.09009 18.2531 6.43478 19.5862C7.77947 20.9192 9.95965 20.9192 11.3043 19.5862L15.4782 15.4482C16.8229 14.1151 16.8229 11.9538 15.4782 10.6207C14.1336 9.28756 11.9534 9.28756 10.6087 10.6207L9.56514 11.6552L10.2608 12.3449L11.3043 11.3103C12.2648 10.3581 13.8221 10.3581 14.7826 11.3103C15.7431 12.2625 15.7431 13.8064 14.7826 14.7586L10.6087 18.8965C9.64819 19.8487 8.09093 19.8487 7.13043 18.8965C6.16994 17.9443 6.16994 16.4004 7.13043 15.4482L9.56515 13.0345L8.86949 12.3449Z"
        css={[t.fill_current]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.21735 9.24132L13.3913 5.1034C14.3517 4.15119 15.909 4.15119 16.8695 5.1034C17.83 6.05561 17.83 7.59946 16.8695 8.55167L15.826 9.58618L16.5217 10.2758L17.5652 9.24132C18.9099 7.90822 18.9099 5.74684 17.5652 4.41375C16.2205 3.08065 14.0403 3.08065 12.6956 4.41375L8.52169 8.55167C7.177 9.88476 7.177 12.0461 8.52169 13.3792C9.86639 14.7123 12.0466 14.7123 13.3913 13.3792L15.1304 11.6551L14.4347 10.9655L12.6956 12.6896C11.7351 13.6418 10.1778 13.6418 9.21735 12.6896C8.25685 11.7374 8.25685 10.1935 9.21735 9.24132Z"
        css={[t.fill_current]}
      />
    </svg>
  );
};
