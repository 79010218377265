import React from 'react'; // eslint-disable-line
/** @jsx jsx */
import { ArtistSearchState, ArtistSearchAction } from './types';

export function ArtistSearchReducer(state: ArtistSearchState, action: ArtistSearchAction): ArtistSearchState {
  switch (action.type) {
    case 'setHitsPerPage':
      return { ...state, hitsPerPage: action.count };
    case 'setSearchQuery':
      return { ...state, query: action.query };
    case 'loadMoreResults':
      return { ...state, pageBump: state.pageBump + 1 };
    case 'setResults':
      return { ...state, results: action.results };

    case 'toggleGenre': {
      const newFilter = [...state.genreFilters];
      const index = newFilter.indexOf(action.genre);
      if (index >= 0) newFilter.splice(index, 1);
      else newFilter.push(action.genre);
      return { ...state, genreFilters: newFilter };
    }

    case 'toggleInstrument': {
      const newFilter = [...state.instrumentFilters];
      const index = newFilter.indexOf(action.instrument);
      if (index >= 0) newFilter.splice(index, 1);
      else newFilter.push(action.instrument);
      return { ...state, instrumentFilters: newFilter };
    }

    case 'togglePackageTag': {
      const newFilter = [...state.packageTagFilters];
      const index = newFilter.indexOf(action.packageTag);
      if (index >= 0) newFilter.splice(index, 1);
      else newFilter.push(action.packageTag);
      return { ...state, packageTagFilters: newFilter };
    }

    case 'toggleUnavailable': {
      let newFilter = undefined;
      if (state.availableFilters.includes('false')) newFilter = ['true'];
      else newFilter = ['true', 'false'];
      return { ...state, availableFilters: newFilter };
    }

    case 'toggleNotedFilter': {
      return { ...state, notedFilter: action.forceState !== undefined ? action.forceState : !state.notedFilter };
    }

    case 'clearFilters': {
      return { ...state, packageTagFilters: [], instrumentFilters: [], genreFilters: [], availableFilters: ['true', 'false'] };
    }

    case 'setSortBy':
      return { ...state, sortIndex: action.index };
  }
}

export default ArtistSearchReducer;
