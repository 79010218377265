import * as React from 'react';
import { FirebaseSDK, FirebaseObjects } from '../contexts/FirebaseContext';
import { v4 as uuid } from 'uuid';
import deepEqual from 'deep-equal';
import Dinero from 'dinero.js';
import { PostalAddress, ArtistPackage } from '../types/shared-types';
import { AccountNotificationSettings } from './AccountContext';
import { PaymentSplitContent } from './PaymentSplitsContentContext';
import { PaymentSplit } from './PaymentSplitsContext';

export enum ArtistProfileState {
  Unknown = 'Unknown',
  Loading = 'Loading',
  LoggedOut = 'LoggedOut',
  Ready = 'Ready',
  Missing = 'Missing',
}

export async function UploadImage(blobURL: string): Promise<string | undefined> {
  const currentUser = FirebaseSDK.app().auth().currentUser;
  if (!currentUser) return undefined;

  const uid = currentUser.uid;
  const filename = `${uuid()}`;
  const docref = FirebaseObjects.uploadBucket.ref(`${uid}/${filename}`);
  const fileBlob = await fetch(blobURL).then((r) => r.blob());
  await docref.put(fileBlob);

  return filename;
}

export class ArtistProfile {
  constructor(state: ArtistProfileState, profile: ArtistProfileDescriptor | null) {
    this.State = state;
    this.Profile = profile;
  }

  public State: ArtistProfileState = ArtistProfileState.Unknown;
  public Profile: ArtistProfileDescriptor | null = null;

  public async Edit(update: PartialArtistProfile) {
    const finalUpdate: UntypedDictionary = {};
    const profileUpdate: UntypedDictionary = update;
    const profileData = this.Profile as UntypedDictionary;
    for (const key in update) {
      const profileValue = profileData[key];
      const profileUpdateValue = profileUpdate[key];
      if (!deepEqual(profileUpdateValue, profileValue, { strict: true })) finalUpdate[key] = profileUpdateValue;
    }

    if (Object.keys(finalUpdate).length === 0) return true;
    try {
      if (finalUpdate.photoURL) finalUpdate.photoURL = await UploadImage(finalUpdate.photoURL);
      if (finalUpdate.additionalImages) {
        finalUpdate.additionalImages = [...finalUpdate.additionalImages];
        const uploadedImages: string[] | undefined = profileData.additionalImages;
        for (let i = 0; i < finalUpdate.additionalImages.length; ++i) {
          const imageUrl = finalUpdate.additionalImages[i];
          if (!uploadedImages || uploadedImages.findIndex((x) => x === imageUrl) === -1) finalUpdate.additionalImages[i] = await UploadImage(imageUrl);
        }
      }
    } catch (error) {
      console.error(error);
      return false;
    }

    try {
      const result = await FirebaseObjects.artistRequest({ action: 'editArtistProfile', data: finalUpdate });
      if (!result.data || !result.data.success) {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
    return true;
  }

  public async EditPackage(id: string, artistPackage: ArtistPackage) {
    const finalPackage: any = artistPackage;
    delete finalPackage.order;
    try {
      await FirebaseObjects.artistRequest({ action: 'editArtistPackage', data: { update: { id: id, package: finalPackage } } });
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  }

  public async AddPackage(artistPackage: ArtistPackage) {
    const finalPackage: any = artistPackage;
    delete finalPackage.order;
    try {
      await FirebaseObjects.artistRequest({ action: 'editArtistPackage', data: { add: finalPackage } });
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  }

  public async DeletePackage(id: string) {
    try {
      await FirebaseObjects.artistRequest({ action: 'editArtistPackage', data: { delete: id } });
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  }

  public async MovePackage(id: string, order: number) {
    try {
      await FirebaseObjects.artistRequest({ action: 'editArtistPackage', data: { move: { id, order } } });
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  }

  public async EditSample(id: string, artistSample: ArtistSample) {
    const sample: any = artistSample;
    delete sample.order;
    try {
      await FirebaseObjects.artistRequest({ action: 'editArtistSample', data: { update: { id: id, sample: sample } } });
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  }

  public async AddSample(artistSample: ArtistSample) {
    const sample: any = artistSample;
    delete sample.order;
    try {
      await FirebaseObjects.artistRequest({ action: 'editArtistSample', data: { add: artistSample } });
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  }

  public async DeleteSample(id: string) {
    try {
      await FirebaseObjects.artistRequest({ action: 'editArtistSample', data: { delete: id } });
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  }

  public async MoveSample(id: string, order: number) {
    try {
      await FirebaseObjects.artistRequest({ action: 'editArtistSample', data: { move: { id, order } } });
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  }

  public async EditApproach(approach: ArtistApproach) {
    if (!this.Profile) return false;
    const currentApproach = this.Profile.approach;
    for (const image of approach.images) {
      if (!currentApproach || currentApproach.images.findIndex((x) => x.url === image.url) === -1) {
        const uploadUrl = await UploadImage(image.url);
        if (!uploadUrl) {
          console.error('Upload failed');
          return false;
        }
        image.url = uploadUrl;
      }
    }

    try {
      await FirebaseObjects.artistRequest({ action: 'editArtistApproach', data: approach });
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  }

  public async EditTaxAddress(address: PostalAddress) {
    try {
      await FirebaseObjects.artistRequest({ action: 'editArtistSettings', data: { taxAddress: address } });
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  }

  public async EditPROAffiliation(proAffiliation: PROAffiliation) {
    try {
      await FirebaseObjects.artistRequest({ action: 'editArtistSettings', data: { proAffiliation } });
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  }

  public async EditNotificationSettings(settings: ArtistNotificationSettings & AccountNotificationSettings) {
    try {
      await FirebaseObjects.artistRequest({ action: 'editArtistSettings', data: { notificationSettings: settings } });
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  }
}

export async function DeleteAccount() {
  await FirebaseObjects.userRequest({ action: 'deleteAccount', data: {} });
  await FirebaseSDK.app().auth().signOut();
}

export async function EditArtistDeactivationSettings(deactivated: boolean) {
  try {
    await FirebaseObjects.artistRequest({
      action: 'editArtistSettings',
      data: { deactivated },
    });
  } catch (error) {
    console.log(error);
    return false;
  }
  return true;
}

export async function EditArtistAvailabilitySettings(availability: ArtistAvailability) {
  try {
    await FirebaseObjects.artistRequest({
      action: 'editArtistSettings',
      data: { availability },
    });
  } catch (error) {
    console.log(error);
    return false;
  }
  return true;
}

export async function EditArtistRatings(rating: ArtistOrderRating) {
  try {
    await FirebaseObjects.userRequest({
      action: 'editArtistRatings',
      data: rating,
    });
  } catch (error) {
    console.log(error);
  }
}

export async function HandleOrderRating(orderId: string, hasArtistRating: boolean, createdAt?: string) {
  try {
    await FirebaseObjects.userRequest({
      action: 'handleOrderRating',
      data: { orderId, hasArtistRating, createdAt },
    });
  } catch (error) {
    console.log(error);
  }
}

export async function MakeArtistProfileLive() {
  try {
    await FirebaseObjects.artistRequest({
      action: 'artistProfileGoLive',
      data: {},
    });
  } catch (error) {
    console.log(error);
    return false;
  }
  return true;
}

export async function UnlinkArtistPaypal() {
  try {
    await FirebaseObjects.artistRequest({ action: 'artistUnlinkPaypal', data: {} });
  } catch (error) {
    console.error(error);
    return false;
  }
  return true;
}

export function UploadSample(songFile: File): { uploadTask: firebase.default.storage.UploadTask; uploadId: string } | undefined {
  const currentUser = FirebaseSDK.app().auth().currentUser;
  if (!currentUser) return undefined;

  const uid = currentUser.uid;
  const filename = `${uuid()}`;
  const docref = FirebaseObjects.uploadBucket.ref(`${uid}/${filename}`);
  const uploadTask = docref.put(songFile);
  return { uploadTask: uploadTask, uploadId: filename };
}

export interface TranscodeSampleResult {
  uploadId: string;
  listenURL: string;
  duration: number;
  isrc: string;
}

export async function TranscodeSample(uploadName: string) {
  let response: firebase.default.functions.HttpsCallableResult | null = null;
  try {
    response = await FirebaseObjects.artistLongRequest({ action: 'transcodeSample', data: { uploadId: uploadName } });
  } catch (error) {
    console.log(error);
    return null;
  }

  return response.data as TranscodeSampleResult;
}

export interface ArtistSample {
  url: string;
  name: string;
  description?: string;
  duration: number;
  order: number;
  genres?: string[];
}

export interface ArtistApproachImage {
  url: string;
  caption: string;
}

export interface ArtistApproach {
  videoUrl: string;
  quote: string;
  description: string;
  images: ArtistApproachImage[];
}

export interface ArtistOrderStats {
  pendingOrders: number;
  activeOrders: number;
  completedOrders: number;
  rejectedOrders: number;
}

export interface PROAffiliation {
  affiliation: string;
  number: string;
}

export interface ArtistNotificationSettings {
  notifyDeadlines: boolean;
}

export interface ArtistAvailability {
  available: boolean;
  message: string;
  bandwidth: number;
}

export interface DashboardTimeStats {
  earnings: Dinero.DineroObject;
  trackPlays: number;
  commissions: number;
}

export interface DashboardStats {
  timePeriodStats: {
    thisMonth: DashboardTimeStats;
    lastMonth: DashboardTimeStats;
    threeMonths: DashboardTimeStats;
    sixMonths: DashboardTimeStats;
    oneYear: DashboardTimeStats;
  };
}

export interface ArtistMetaData {
  metaTitle: string;
  metaDescription: string;
}

export interface ArtistOrderRating {
  starRating: number;
  tags?: string[];
  writtenReview?: string;
  orderId: string;
  commissionerId: string;
  commissionerFirstName: string;
  createdAt: string;
  isPublishable: boolean;
  isActive: boolean;
  resolvedAt?: string;
  reported: boolean;
  shouldDelete: boolean;
}

export interface ArtistNotedData {
  isEligible: boolean;
  isNoted: boolean;
  notedStatusStartDate: string | null;
  socialLinks: string[];
  averageCommissionValue: Dinero.DineroObject;
  totalCommissionValue: Dinero.DineroObject;
  averageRating: number;
  hasApplied: boolean;
  applicationDate: string | null;
  isHomePage: boolean;
  isNotedPage: boolean;
  isNotedFeatured: boolean;
  notedBlurb: string;
}

export interface ArtistInstantBookData {
  // phase 1
  isInstantBook: boolean;
  lastCommissionDate: string | null;
  totalInstantBookCommissions: number;

  // phase 2
  isEligible: boolean;
  hasApplied: boolean;
  applicationDate: string | null;
  instantBookStatusStartDate: string | null;

  // possibly wanted, need to confirm
  instantBookStatusEndDate: string | null; // when they were last removed from instant book pool of artists
  timesAsInstantBookArtist: number; // number of times they've been in the instant book pool of artists
}

export interface ArtistProfileDescriptor {
  pageDisabled: boolean;
  name?: string;
  photoURL?: string;
  additionalImages?: string[];
  genreTags?: string[];
  instrumentTags?: string[];
  shortBio?: string;
  profileRoute?: string;
  websiteURL?: string;
  facebookURL?: string;
  instagramURL?: string;
  twitterURL?: string;
  youtubeURL?: string;
  spotifyURL?: string;
  wizardComplete?: boolean;
  packages: { [key: string]: ArtistPackage };
  packageOrder: string[];
  samples: { [key: string]: ArtistSample };
  sampleId: string;
  sampleOrder: string[];
  approach?: ArtistApproach;
  relatedProjects?: string[];
  orderStats: ArtistOrderStats;
  songsProduced: number;

  paypalId: string;
  paypalEmail: string;
  paypalVerified: boolean;
  paypalVerifiedEmail: boolean;

  taxAddress?: PostalAddress;
  proAffiliation?: PROAffiliation;
  notificationSettings: ArtistNotificationSettings;
  deactivated: boolean;

  availability: ArtistAvailability;
  dashboardStats: DashboardStats;
  paymentSplit?: PaymentSplitContent;

  partnered: boolean;

  metaData: ArtistMetaData;

  notedData: ArtistNotedData;
  instantBookData: ArtistInstantBookData;

  artistRatings: ArtistOrderRating[];
}

export type PartialArtistProfile = Partial<ArtistProfileDescriptor>;

interface ArtistProfileDocument {
  State: ArtistProfileState;
  Profile: ArtistProfileDescriptor | null;
}

export function useArtistDocument(artistId: string | null) {
  const [profileDoc, setProfileDoc] = React.useState<ArtistProfileDocument>({ State: ArtistProfileState.Loading, Profile: null });
  React.useEffect(() => {
    if (!artistId) {
      setProfileDoc({ State: ArtistProfileState.Loading, Profile: null });
      return;
    }

    const onUpdate = (snapshot: firebase.default.firestore.DocumentSnapshot) => {
      const data = snapshot.data() as ArtistProfileDescriptor;
      if (data) {
        const samples: { [key: string]: ArtistSample } = data.samples;
        const sampleOrder = samples ? Object.keys(samples).sort((a, b) => samples[a].order - samples[b].order) : [];

        const packages: { [key: string]: ArtistPackage } = data.packages;
        const packageOrder = packages ? Object.keys(packages).sort((a, b) => packages[a].order - packages[b].order) : [];

        setProfileDoc({ State: ArtistProfileState.Ready, Profile: { ...data, sampleOrder, packageOrder } });
      } else setProfileDoc({ State: ArtistProfileState.Missing, Profile: null });
    };

    const onError = (error: Error) => {
      setProfileDoc({ State: ArtistProfileState.Missing, Profile: null });
    };

    const unsubscribe = FirebaseSDK.app().firestore().doc(`/artists/${artistId}`).onSnapshot(onUpdate, onError);

    return () => {
      unsubscribe();
    };
  }, [artistId]);

  return profileDoc;
}

const ArtistProfileContext = React.createContext<ArtistProfile>(null as any); // eslint-disable-line
export function ArtistProfileProvider(props: { artistId: string | null; children: React.ReactNode }) {
  const artistDocument = useArtistDocument(props.artistId);
  const artistProfile = new ArtistProfile(artistDocument.State, artistDocument.Profile);
  return <ArtistProfileContext.Provider value={artistProfile}>{props.children}</ArtistProfileContext.Provider>;
}

export function useArtistProfile() {
  return React.useContext(ArtistProfileContext);
}

export async function AddArtistPaymentSplit(artistId: string) {
  await FirebaseObjects.adminRequest({ action: 'editPaymentSplits', data: { split: { action: 'addSplit' }, artist: artistId } });
}

export async function RemoveArtistPaymentSplit(artistId: string) {
  await FirebaseObjects.adminRequest({ action: 'editPaymentSplits', data: { split: { action: 'removeSplit' }, artist: artistId } });
}

export async function EditArtistPaymentSplit(artistId: string, percentage: number) {
  await FirebaseObjects.adminRequest({ action: 'editPaymentSplits', data: { split: { action: 'editSplit', percentage }, artist: artistId } });
}

export function DetermineSongSplit(defaultSplits: PaymentSplit, artistSplit?: PaymentSplitContent): number {
  if (artistSplit && artistSplit.percentage !== undefined) return artistSplit.percentage;
  return defaultSplits.percentage;
}
