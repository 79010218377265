import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../theme/admin/adminStyles';
import { withAdminAccount } from '../../hocs/withAdminAccount';
import { AdminPage } from './components/AdminPage';
import { AdminCard } from './components/AdminCard';
import { FirebaseObjects } from '../../contexts/FirebaseContext';
import { AdminTextField } from './components/AdminTextField';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { AdminButton } from './components/AdminButton';
import { AdminCardHeader } from './components/AdminCardHeader';
import { AdminErrorMessage } from './components/AdminErrorMessage';

interface GrantForm {
  email: string;
}

interface RevokeForm {
  email: string;
}

const AdminPermissionsComponent = () => {
  const handleGrantPermissions = React.useCallback(async (values: GrantForm, formik: FormikHelpers<GrantForm>) => {
    let success = false;
    try {
      const result = await FirebaseObjects.adminRequest({ action: 'editAdmin', data: { action: 'grant', email: values.email } });
      success = !!result.data.success;
    } catch (error) {
      console.log(error);
    }

    if (success) {
      formik.resetForm();
    } else {
      formik.setFieldError('email', `Failed to grant rights for ${values.email}`);
    }
  }, []);

  const handleRevokePermissions = React.useCallback(async (values: RevokeForm, formik: FormikHelpers<RevokeForm>) => {
    let success = false;
    try {
      const result = await FirebaseObjects.adminRequest({ action: 'editAdmin', data: { action: 'revoke', email: values.email } });
      success = !!result.data.success;
    } catch (error) {
      console.log(error);
    }

    if (success) {
      formik.resetForm();
    } else {
      formik.setFieldError('email', `Failed to revoke rights for ${values.email}`);
    }
  }, []);

  return (
    <AdminPage pageHeading="Permissions">
      <AdminCard>
        <AdminCardHeader>
          <div css={[t.flex_auto, t.flex, t.flex_row, t.px_4]}>
            <div css={[t.flex_auto, t.text_dark_3, t.text_base, t.flex, t.flex_col, t.justify_center]}>Admin Access</div>
          </div>
        </AdminCardHeader>
        <div css={[t.p_6]}>
          <Formik initialValues={{ email: '' }} onSubmit={handleGrantPermissions} enableReinitialize={true}>
            {(formikProps: FormikProps<GrantForm>) => {
              return (
                <div css={[t.mb_8]}>
                  <Form>
                    <div css={[t.flex, t.items_center]}>
                      <div css={[t.w('340px'), t.mr_2]}>
                        <AdminTextField<GrantForm>
                          label="Grant access to:"
                          type="text"
                          formName={'grantRights'}
                          name="email"
                          placeholder="Email address"
                          formikValues={formikProps.values}
                        />
                        {formikProps.errors.email ? <AdminErrorMessage>{formikProps.errors.email}</AdminErrorMessage> : null}
                      </div>
                      <AdminButton buttonType="submit" type="button" label="Grant" />
                    </div>
                  </Form>
                </div>
              );
            }}
          </Formik>
          <Formik initialValues={{ email: '' }} onSubmit={handleRevokePermissions} enableReinitialize={true}>
            {(formikProps: FormikProps<RevokeForm>) => {
              return (
                <div css={[t.mb_5]}>
                  <Form>
                    <div css={[t.flex, t.items_center]}>
                      <div css={[t.w('340px'), t.mr_2]}>
                        <AdminTextField<GrantForm>
                          label="Revoke access for:"
                          type="text"
                          formName={'revokeRights'}
                          name="email"
                          placeholder="Email address"
                          formikValues={formikProps.values}
                        />
                        {formikProps.errors.email ? <AdminErrorMessage>{formikProps.errors.email}</AdminErrorMessage> : null}
                      </div>
                      <AdminButton buttonType="submit" type="button" label="Revoke" styleType="critical" />
                    </div>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </div>
      </AdminCard>
    </AdminPage>
  );
};

export const AdminPermissions = withAdminAccount(AdminPermissionsComponent);
