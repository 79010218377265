/** @jsx jsx */
import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/core'; // eslint-disable-line
import { FirebaseSDK, FirebaseObjects } from '../contexts/FirebaseContext';
import { v4 as uuid } from 'uuid';
import { FeaturedArtist } from './FeaturedArtistContentContext';

export interface DiscoveryContentMainFeature {
  artist?: FeaturedArtist;
  centerImage: string;
  description: string;
}

export interface DiscoveryContentOccasion {
  id: string;
  title: string;
  description: string;
  artists: FeaturedArtist[];
}

export interface DiscoveryContent {
  mainFeature: DiscoveryContentMainFeature;
  popularGenres: string[];
  popularPackageTags?: string[];
  occasions: DiscoveryContentOccasion[];
}

const DiscoveryContentContext = React.createContext<DiscoveryContent | null>(null);
export function DiscoveryContentProvider(props: { children?: React.ReactNode }) {
  const [state, setState] = React.useState<DiscoveryContent | null>(null);
  React.useEffect(() => {
    const observer = (snapshot: firebase.default.firestore.DocumentSnapshot) => {
      const content = snapshot.data() as DiscoveryContent;
      setState(content);
    };

    const error = (error: Error) => {
      console.error(error);
    };

    const discoveryDoc = FirebaseSDK.firestore().doc(`/content/discovery`);
    return discoveryDoc.onSnapshot(observer, error);
  }, []);
  return <DiscoveryContentContext.Provider value={state}>{props.children}</DiscoveryContentContext.Provider>;
}

export function useDiscoveryContent() {
  return React.useContext(DiscoveryContentContext);
}

export async function PublishDiscovery() {
  await FirebaseObjects.adminRequest({ action: 'publishDiscovery', data: {} });
}

export async function PublishOccasions() {
  await FirebaseObjects.adminRequest({ action: 'publishOccasions', data: {} });
}

export async function AddOccasion() {
  await FirebaseObjects.adminRequest({ action: 'editDiscoveryPage', data: { occasion: { action: 'add' } } });
}

export async function RemoveOccasion(id: string) {
  await FirebaseObjects.adminRequest({ action: 'editDiscoveryPage', data: { occasion: { action: 'remove', id } } });
}

export async function MoveOccasion(id: string, direction: 'up' | 'down') {
  await FirebaseObjects.adminRequest({ action: 'editDiscoveryPage', data: { occasion: { action: 'move', id, direction } } });
}

export async function SetOccasionTitle(id: string, title: string) {
  await FirebaseObjects.adminRequest({ action: 'editDiscoveryPage', data: { occasion: { action: 'setTitle', id, title } } });
}

export async function SetOccasionDescription(id: string, description: string) {
  await FirebaseObjects.adminRequest({ action: 'editDiscoveryPage', data: { occasion: { action: 'setDescription', id, description } } });
}

export async function AddOccasionArtist(id: string, artistId: string) {
  await FirebaseObjects.adminRequest({ action: 'editDiscoveryPage', data: { occasion: { action: 'addArtist', id, artistId } } });
}

export async function RemoveOccasionArtist(id: string, artistId: string) {
  await FirebaseObjects.adminRequest({ action: 'editDiscoveryPage', data: { occasion: { action: 'removeArtist', id, artistId } } });
}

export async function MoveOccasionArtist(id: string, artistId: string, direction: 'up' | 'down') {
  await FirebaseObjects.adminRequest({ action: 'editDiscoveryPage', data: { occasion: { action: 'moveArtist', id, artistId, direction } } });
}

export async function AddPopularGenre(genre: string) {
  await FirebaseObjects.adminRequest({ action: 'editDiscoveryPage', data: { popularGenre: { action: 'add', genre } } });
}

export async function RemovePopularGenre(genre: string) {
  await FirebaseObjects.adminRequest({ action: 'editDiscoveryPage', data: { popularGenre: { action: 'remove', genre } } });
}

export async function AddPopularPackageTag(tag: string) {
  await FirebaseObjects.adminRequest({ action: 'editDiscoveryPage', data: { popularPackageTag: { action: 'add', tag } } });
}

export async function RemovePopularPackageTag(tag: string) {
  await FirebaseObjects.adminRequest({ action: 'editDiscoveryPage', data: { popularPackageTag: { action: 'remove', tag } } });
}

async function UploadImage(image: File): Promise<string | undefined> {
  const currentUser = FirebaseSDK.app().auth().currentUser;
  if (!currentUser) return undefined;

  const uid = currentUser.uid;
  const filename = `${uuid()}`;
  const docref = FirebaseObjects.uploadBucket.ref(`${uid}/${filename}`);
  await docref.put(image);

  return filename;
}

export async function SetMainFeatureImage(image: File, imageAction: 'setCenterImage') {
  const url = await UploadImage(image);
  if (url) await FirebaseObjects.adminRequest({ action: 'editDiscoveryPage', data: { mainFeature: { action: imageAction, url } } });
}

export async function SetMainFeatureArtist(artistId: string) {
  await FirebaseObjects.adminRequest({ action: 'editDiscoveryPage', data: { mainFeature: { action: 'setArtist', artistId } } });
}

export async function SetMainFeatureDescription(description: string) {
  await FirebaseObjects.adminRequest({ action: 'editDiscoveryPage', data: { mainFeature: { action: 'setDescription', description } } });
}

export async function SetMainFeatureSample(sampleId: string) {
  await FirebaseObjects.adminRequest({ action: 'editDiscoveryPage', data: { mainFeature: { action: 'setSample', sampleId } } });
}
