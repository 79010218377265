import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { CSSStyle } from '../../../theme/newstyles';

const listItemCellStyle = [t.border_b, t.border_tint_3, t.border_solid];

export const AdminSearchTableActionCell = (props: { children?: React.ReactNode; cellStyle?: CSSStyle; innerStyle?: CSSStyle }) => {
  return (
    <td css={[t.overflow_hidden, listItemCellStyle, t.p_0, props.cellStyle]}>
      <div css={[t.pr_6, t.text_tint_1, t.flex, t.flex_col, t.items_center, props.innerStyle]}>{props.children}</div>
    </td>
  );
};
