import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import { InstantSearch } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import { envPrefix } from './FirebaseContext';
import { useAdminAccount } from './AdminAccountContext';

export function AdminSearchContext(props: { indexName: 'USERS' | 'ARTISTPAGES' | 'ARTISTS' | 'ORDERS' | 'SONGS' | 'USEREVENTS'; children: React.ReactNode }) {
  const adminAccount = useAdminAccount();
  if (!adminAccount) return null;
  if (!adminAccount.searchKey) return null;

  const searchClient = algoliasearch('QKXMUJRSCM', adminAccount.searchKey);
  const indexName = `${envPrefix}${props.indexName}`;
  return (
    <InstantSearch searchClient={searchClient} indexName={indexName}>
      {props.children}
    </InstantSearch>
  );
}
