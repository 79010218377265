import React from 'react'; // eslint-disable-line
import { motion, Variants } from 'framer-motion';

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../theme/newstyles';

const variants: Variants = {
  active: { color: t.dark_1 },
  inactive: { color: t.tint_1 },
};

const lineVariants: Variants = {
  active: { opacity: 1, y: 0 },
  inactive: { opacity: 0, y: -2 },
};

export const Tab = (props: {
  label: string;
  value?: number;
  index?: number;
  selected?: boolean;
  onChange?: (event: React.ChangeEvent<unknown>, newValue: number) => void;
  onClick?: (event: React.MouseEvent) => void;
}) => {
  const [focus, SetFocus] = React.useState(false);

  const selected = props.selected ? props.selected : props.value === props.index;
  const HandleClick = (event: React.ChangeEvent<unknown>) => {
    if (!props.selected && props.onChange && props.value !== undefined) {
      props.onChange(event, props.value);
    }
  };

  const HandleFocus = React.useCallback(() => {
    SetFocus(true);
  }, []);

  const HandleBlur = React.useCallback(() => {
    SetFocus(false);
  }, []);

  return (
    <motion.button
      css={[
        t.relative,
        t.p_0,
        t.pb_4,
        t.cursor_pointer,
        t.bg_transparent,
        t.border_0,
        t.before([
          t.focusIndicator,
          t.pos({ top: '0px', right: '-8px', bottom: '-3px', left: '-8px' }),
          t.border_primary_4,
          focus ? t.content_some : t.content_none,
        ]),
        t.hover(t.text_dark_1),
      ]}
      initial="inactive"
      animate={selected ? 'active' : 'inactive'}
      variants={variants}
      transition={{ ease: 'easeInOut' }}
      onClick={HandleClick}
      onFocus={HandleFocus}
      onBlur={HandleBlur}
    >
      <motion.div
        css={[t.absolute, t.inset_x_0, t.bottom_0, t.border_b, t.border_solid, t.border_dark_1]}
        variants={lineVariants}
        transition={{ ease: 'easeInOut', duration: 0.2 }}
      />
      <div css={[t.typeStyle_lg6, t.text_left]}>{props.label}</div>
    </motion.button>
  );
};
