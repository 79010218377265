import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { withAdminAccount } from '../../../hocs/withAdminAccount';
import { AdminPage } from '../components/AdminPage';
import { AdminListControl } from '../components/AdminListControl';
import { ListColumnDescriptor } from '../components/AdminListControl';
import { AdminArtistsSearchHits } from './AdminArtistsSearchHits';
import { AdminSearchContext } from '../../../contexts/AdminSearchContext';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { AdminLinkTab } from '../components/AdminLinkTab';
import { AdminPendingRequests } from './AdminPendingRequests';
import { AdminNotedArtistsList } from './AdminNotedArtistsList';
import { AdminInstantArtistsList } from './AdminInstantArtistList';
import { AdminCard } from '../components/AdminCard';
import { AdminButton } from '../components/AdminButton';
import { HiddenFilter } from '../components/AdminFilter';
import { FirebaseObjects } from '../../../contexts/FirebaseContext';

const UserListColumns: ListColumnDescriptor<string>[] = [
  { width: '2' },
  { label: 'Name', width: '180' },
  { label: 'Artist ID', width: '15%' },
  { label: 'Noted Status', width: '80' },
  { label: 'Instant Artist', width: '80' },
  { label: 'Pending', width: '44' },
  { label: 'Active', width: '44' },
  { label: 'Closed', width: '44' },
  { label: 'User ID', width: '25%' },
  { width: '66' },
];

const AdminArtistsComponent = () => {
  const { path } = useRouteMatch();

  return (
    <AdminSearchContext indexName="ARTISTS">
      <AdminPage
        pageHeading="Artists"
        tabChildren={
          <div css={[t.flex, t.flex_row]}>
            <AdminLinkTab label="Requests" to={`/admin/artists/pending`} />
            <AdminLinkTab label="Noted Artists" to={`/admin/artists/noted`} />
            <AdminLinkTab label="Instant Commission" to={`/admin/artists/instant`} />
            <AdminLinkTab label="All Artists" to={`/admin/artists`} />
          </div>
        }
      >
        <Switch>
          {/* NOTE if you add a new table where you will be filtering the artists to show a subsection or specific set of artists, you will need to update the 'Facets' settings for the ARTIST indexes in Algolia */}
          <Route exact path={`${path}/pending`} component={AdminPendingRequests} />
          <Route exact path={`${path}/noted`} component={AdminNotedArtistsList} />
          <Route exact path={`${path}/instant`} component={AdminInstantArtistsList} />
          <Route exact path={`${path}/:userId`} component={AdminArtistsList} />
          <Route component={AdminArtistsList} />
        </Switch>
      </AdminPage>
    </AdminSearchContext>
  );
};

export async function DownloadArtistsReport() {
  const result = await FirebaseObjects.adminRequest({
    action: 'exportArtists',
    data: {},
  });
  if (result.data.url) {
    const link = document.createElement('a');
    link.href = result.data.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

const AdminArtistsList = () => {
  const [refreshKey, setRefreshKey] = React.useState(0);
  const onRefresh = React.useCallback(() => {
    setRefreshKey(refreshKey + 1);
  }, [refreshKey]);

  const params = useParams<{ userId: string; filters?: string }>();
  const stateFilterDefault = params.filters ? JSON.parse(params.filters) : ['active'];
  const partneredFilterDefault: string[] = [];
  const filters = [
    { label: 'Account Status', attribute: 'accountState', options: ['active', 'deleted'], defaults: stateFilterDefault },
    { label: 'Partnered Status', attribute: 'partneredState', options: ['partnered', 'unpartnered'], defaults: partneredFilterDefault },
  ];
  return (
    <AdminSearchContext indexName="ARTISTS">
      <AdminListControl
        listColumns={UserListColumns}
        sortColumn="name"
        defaultSearch={params.userId}
        filters={filters}
        placeholderSearch="Search by artist or user id"
        onRefresh={onRefresh}
      >
        <HiddenFilter attribute="wizardState" defaultRefinement={['complete']} />
        <AdminArtistsSearchHits key={`search_${refreshKey}`} />
      </AdminListControl>

      <AdminCard containerStyle={[t.mb_0, t.mt_8]}>
        <div css={[t.p_6]}>
          <div css={[t.text_dark_3, t.text_base, t.pb_2]}>Artist Export</div>
          <div css={[t.text_tint_1, t.text_xs, t.pb_4]}>Download a CSV containing information about all artists</div>
          <div css={[t.flex]}>
            <div css={[t.flex, t.flex_col, t.justify_center]}>
              <AdminButton type="button" label="Export" onClick={() => DownloadArtistsReport()} />
            </div>
          </div>
        </div>
      </AdminCard>
    </AdminSearchContext>
  );
};

export const AdminArtists = withAdminAccount(AdminArtistsComponent);
