import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t, { CSSStyle } from '../theme/newstyles';
import { Link } from 'react-router-dom';
import * as H from 'history';
import { useThemeController } from '../contexts/ThemeContext';

type SharedButtonProps = {
  children?: React.ReactNode;
  ariaLabel?: string;
  disabled?: boolean;
  id?: string;
  outerStyles?: CSSStyle;
};

type ButtonLinkProps<S> = {
  type: 'link';
  to: H.LocationDescriptor<S> | ((location: H.Location<S>) => H.LocationDescriptor<S>);
} & SharedButtonProps;

type ButtonProps<S> =
  | ({
      type: 'button';
      buttonType?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
      onClick?: (event: React.MouseEvent) => void;
    } & SharedButtonProps)
  | ButtonLinkProps<S>
  | ({
      type: 'input';
      formName: string;
      inputType?: React.InputHTMLAttributes<HTMLInputElement>['type'];
      accept?: string;
      onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
    } & SharedButtonProps);

export function IconButton<S = H.LocationState>(props: ButtonProps<S>) {
  const { dark } = useThemeController();
  const inputElement = React.useRef<HTMLInputElement | null>(null);

  function HandleInnerFocus(event: React.FocusEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  const contents = (
    <span
      css={[
        t.relative,
        t.inline_flex,
        t.flex_row,
        t.w_full,
        t.outline_none,
        t.typeStyle_lg6,
        t.bg_transparent,
        dark ? t.text_tint_5 : t.text_dark_1,
        t.active([dark ? t.text_tint_5 : t.text_primary_4]),
        t.before([t.focusIndicator, t.pos('-3px'), t.border_primary_4, t.content_none]),
      ]}
      tabIndex={-1}
      onFocus={HandleInnerFocus}
    >
      <div css={[t.inline_block, t.size('24px'), t.flex, t.items_center, t.justify_center]}>{props.children}</div>
    </span>
  );

  const outerStyle = [
    t.relative,
    t.inline_flex,
    t.flex_col,
    t.h_full,
    t.justify_center,
    t.bg_transparent,
    t.outline_none,
    t.cursor_pointer,
    t.no_underline,
    t.p_0,
    t.disabled(t.cursor_not_allowed),
    t.disabledSelector('> span', [t.text_tint_3]),
    t.focusSelector('> span::before', t.content_some),
    t.focusSelector('> span', [t.text_primary_4]),
    props.outerStyles,
  ];

  switch (props.type) {
    case 'button': {
      const buttonType = props.buttonType || 'button';

      const HandleClick = (event: React.MouseEvent) => {
        if (props.onClick) props.onClick(event);
      };

      return (
        <button
          aria-label={props.ariaLabel}
          type={buttonType}
          css={outerStyle}
          disabled={props.disabled}
          aria-disabled={props.disabled}
          onClick={HandleClick}
          id={props.id}
        >
          {contents}
        </button>
      );
    }
    case 'link': {
      if (!props.disabled) {
        return (
          <Link to={props.to} aria-label={props.ariaLabel} css={outerStyle}>
            {contents}
          </Link>
        );
      } else {
        return (
          <button aria-label={props.ariaLabel} css={outerStyle} disabled={props.disabled} aria-disabled={props.disabled}>
            {contents}
          </button>
        );
      }
    }
    case 'input': {
      const id = `button-${props.formName}`;

      const HandleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === ' ') {
          if (inputElement.current) {
            inputElement.current.click();
          }
        }
      };

      return (
        <div css={[t.relative]}>
          <label htmlFor={id} tabIndex={0} onKeyPress={HandleKeyPress} css={outerStyle}>
            {contents}
            <input id={id} ref={inputElement} tabIndex={-1} css={[t.hidden_input]} type={props.inputType} accept={props.accept} onChange={props.onChange} />
          </label>
        </div>
      );
    }
  }
}
