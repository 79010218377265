import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../theme/admin/adminStyles';
import { FirebaseObjects } from '../../contexts/FirebaseContext';
import { AdminButton } from './components/AdminButton';
import { AdminPage } from './components/AdminPage';
import { AdminCard } from './components/AdminCard';
import { ButtonGroup } from '../../components/ButtonGroup';
import { withAdminAccount } from '../../hocs/withAdminAccount';
import { Form, Formik, FormikProps } from 'formik';
import { AdminTextField } from './components/AdminTextField';
import { useAccount } from '../../contexts/AccountContext';

interface ImportDatabaseData {
  name: string;
}

const AdminDevelopersComponent = () => {
  const account = useAccount();

  const reindexUsers = React.useCallback(async () => {
    await FirebaseObjects.adminRequest({ action: 'reindexSearch', data: { users: true } });
  }, []);

  const reindexArtists = React.useCallback(async () => {
    await FirebaseObjects.adminRequest({ action: 'reindexSearch', data: { artists: true } });
  }, []);

  const reindexOrders = React.useCallback(async () => {
    await FirebaseObjects.adminRequest({ action: 'reindexSearch', data: { orders: true } });
  }, []);

  const reindexSongs = React.useCallback(async () => {
    await FirebaseObjects.adminRequest({ action: 'reindexSearch', data: { songs: true } });
  }, []);

  const reindexArtistPages = React.useCallback(async () => {
    await FirebaseObjects.adminRequest({ action: 'reindexSearch', data: { artistPages: true } });
  }, []);

  const reindexArtistPackages = React.useCallback(async () => {
    await FirebaseObjects.adminRequest({ action: 'reindexSearch', data: { artistPackages: true } });
  }, []);

  const reindexUserEvents = React.useCallback(async () => {
    await FirebaseObjects.adminRequest({ action: 'reindexSearch', data: { userEvents: true } });
  }, []);

  const reindexEverything = React.useCallback(async () => {
    await FirebaseObjects.adminRequest({
      action: 'reindexSearch',
      data: { users: true, artists: true, orders: true, songs: true, artistPages: true, artistPackages: true, userEvents: true },
    });
  }, []);

  const onMigrateArtistNotedData = React.useCallback(async () => {
    await FirebaseObjects.adminRequest({ action: 'migrateArtistNotedData', data: {} });
  }, []);

  const onMigrateArtistRatingsData = React.useCallback(async () => {
    await FirebaseObjects.adminRequest({ action: 'migrateArtistRatingsData', data: {} });
  }, []);

  const onMigrateArtistInstantBookData = React.useCallback(async () => {
    await FirebaseObjects.adminRequest({ action: 'migrateArtistInstantBookData', data: {} });
  }, []);

  const onBackfillProfileLiveDate = React.useCallback(async () => {
    await FirebaseObjects.adminRequest({ action: 'backfillProfileLiveDate', data: {} });
  }, []);

  const onRegenerateSearchKeys = React.useCallback(async () => {
    await FirebaseObjects.adminRequest({ action: 'regenerateSearchKeys', data: {} });
  }, []);

  const onSetupCORS = React.useCallback(async () => {
    await FirebaseObjects.adminRequest({ action: 'setupCORS', data: {} });
  }, []);

  const onTestEmail = React.useCallback(async () => {
    await FirebaseObjects.adminRequest({ action: 'emailTester', data: {} });
  }, []);

  const onTestStats = React.useCallback(async () => {
    await FirebaseObjects.adminRequest({ action: 'statsTester', data: {} });
  }, []);

  const onExportDatabase = React.useCallback(async () => {
    await FirebaseObjects.adminRequest({ action: 'exportDatabase', data: {} });
  }, []);

  const submitImportDatabase = React.useCallback(async (values: ImportDatabaseData) => {
    await FirebaseObjects.adminRequest({ action: 'importDatabase', data: { name: values.name } });
  }, []);

  const deleteOldFirestoreRules = React.useCallback(async () => {
    await FirebaseObjects.adminRequest({ action: 'deleteOldFirestoreRules', data: {} });
  }, []);

  const subscribeToMailingList = React.useCallback(async () => {
    if (!account) return;
    await FirebaseObjects.visitorRequest({ action: 'mailingListSignup', data: { email: account.User.email } });
  }, [account]);

  const publishArtistPages = React.useCallback(async () => {
    await FirebaseObjects.adminRequest({ action: 'publishArtistPages', data: {} });
  }, []);

  const testPayments = React.useCallback(async () => {
    await FirebaseObjects.adminRequest({ action: 'testPayments', data: {} });
  }, []);
  const generateSiteMap = React.useCallback(async () => {
    await FirebaseObjects.adminRequest({ action: 'generateSiteMap', data: {} });
  }, []);

  return (
    <AdminPage pageHeading="Dev tools">
      <AdminCard>
        <div css={[t.p_6]}>
          <div css={[t.text_dark_3, t.text_base, t.pb_4]}>Reindex search</div>
          <ButtonGroup>
            <AdminButton type="button" label="Users" styleType="secondary" onClick={reindexUsers} />
            <AdminButton type="button" label="Artists" styleType="secondary" onClick={reindexArtists} />
            <AdminButton type="button" label="Orders" styleType="secondary" onClick={reindexOrders} />
            <AdminButton type="button" label="Songs" styleType="secondary" onClick={reindexSongs} />
            <AdminButton type="button" label="Artist pages" styleType="secondary" onClick={reindexArtistPages} />
            <AdminButton type="button" label="Artist packages" styleType="secondary" onClick={reindexArtistPackages} />
            <AdminButton type="button" label="User Events" styleType="secondary" onClick={reindexUserEvents} />
            <AdminButton type="button" label="All" styleType="secondary" onClick={reindexEverything} />
          </ButtonGroup>
        </div>
      </AdminCard>

      <AdminCard>
        <div css={[t.p_6]}>
          <div css={[t.text_dark_3, t.text_base, t.pb_4]}>Test Functions</div>
          <div>
            <ButtonGroup>
              <AdminButton type="button" label="Subscribe To Mailing List" styleType="secondary" onClick={subscribeToMailingList} />
              <AdminButton type="button" label="Send Test Email" styleType="secondary" onClick={onTestEmail} />
              <AdminButton type="button" label="Export Dashboard Stats" styleType="secondary" onClick={onTestStats} />
            </ButtonGroup>
          </div>
        </div>
      </AdminCard>

      <AdminCard>
        <div css={[t.p_6]}>
          <div css={[t.text_dark_3, t.text_base, t.pb_4]}>Advanced</div>
          <div>
            <ButtonGroup>
              <AdminButton type="button" label="Publish Artist Pages" styleType="critical" onClick={publishArtistPages} />
              <AdminButton type="button" label="Test Payments" styleType="critical" onClick={testPayments} />
              <AdminButton type="button" label="Delete Old Firestore Rules" styleType="critical" onClick={deleteOldFirestoreRules} />
              <AdminButton type="button" label="Migrate Artist Noted Data" styleType="critical" onClick={onMigrateArtistNotedData} />
              <AdminButton type="button" label="Migrate Artist Ratings Data" styleType="critical" onClick={onMigrateArtistRatingsData} />
              <AdminButton type="button" label="Migrate Artist Instant Book Data" styleType="critical" onClick={onMigrateArtistInstantBookData} />
              <AdminButton type="button" label="Backfill Artist ProfileLive Data" styleType="critical" onClick={onBackfillProfileLiveDate} />
              <AdminButton type="button" label="Setup CORS" styleType="critical" onClick={onSetupCORS} />
              <AdminButton type="button" label="Regenerate Search Keys" styleType="critical" onClick={onRegenerateSearchKeys} />
              <AdminButton type="button" label="Generate SiteMap" styleType="critical" onClick={generateSiteMap} />
            </ButtonGroup>
          </div>
        </div>
      </AdminCard>
      <AdminCard>
        <div css={[t.p_6]}>
          <div css={[t.text_dark_3, t.text_base, t.pb_4]}>Advanced Database</div>
          <div>
            <ButtonGroup>
              <AdminButton type="button" label="Export Database" styleType="critical" onClick={onExportDatabase} />
            </ButtonGroup>
            <Formik initialValues={{ name: '' }} onSubmit={submitImportDatabase} enableReinitialize={true}>
              {(formikProps: FormikProps<ImportDatabaseData>) => {
                return (
                  <div css={[t.mb_2]}>
                    <Form>
                      <div css={[t.flex, t.items_center]}>
                        <div css={[t.mr_2]}>
                          <AdminTextField<ImportDatabaseData> type="text" formName={'importDatabase'} name="name" formikValues={formikProps.values} />
                        </div>
                        <AdminButton buttonType="submit" type="button" label="Import Database" styleType="critical" />
                      </div>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </div>
        </div>
      </AdminCard>
    </AdminPage>
  );
};

export const AdminDevelopers = withAdminAccount(AdminDevelopersComponent);
