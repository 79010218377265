import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../../theme/admin/adminStyles';

export const AdminDetailsDialogInfoBlock = (props: { label?: string; children?: React.ReactNode }) => {
  return (
    <div css={[t.flex_auto, t.flex_wrap, t.flex, t.flex_col]}>
      {props.label && <div css={[t.text_sm, t.text_tint_1, t.mb_3]}>{props.label}</div>}
      {props.children}
    </div>
  );
};
