import React from 'react'; // eslint-disable-line
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { connectRange } from 'react-instantsearch-core';

type RangeRefinement = { min: number | undefined; max: number | undefined };
// This enables searching with arbitrary strings
function HiddenRange(props: {
  attribute: string;
  defaultRefinement: RangeRefinement;
  min: number | undefined;
  max: number | undefined;
  currentRefinement: RangeRefinement;
  refine: (refinement: RangeRefinement) => void;
}) {
  if (props.min !== props.currentRefinement.min || props.max !== props.currentRefinement.max) {
    props.refine({ min: props.min, max: props.max });
  }

  return <React.Fragment></React.Fragment>;
}

export default connectRange(HiddenRange);
