import React from 'react'; // eslint-disable-line

/** @jsx jsx */
import { jsx } from '@emotion/core';
import t from '../../../theme/admin/adminStyles';
import { AdminEventList } from '../components/AdminEventList';
import { AdminEventListItem } from '../components/AdminEventListItem';
import { AddSongMetadataComment, useSongMetadata } from '../../../contexts/SongMetadataContext';
import { Form, Formik, FormikProps } from 'formik';
import { useSong } from '../../../contexts/SongContext';
import { AdminButton } from '../components/AdminButton';
import { AdminTextField } from '../components/AdminTextField';

interface AdminSongComment {
  message: string;
}

export const AdminSongDetailsHistory = () => {
  const song = useSong();

  if (!song) return null;

  return (
    <div css={[t.bg_tint_4, t.border_b, t.border_solid, t.border_tint_3, t.pt_5, t.pb_5, t.flex_auto, t.flex, t.flex_col]}>
      <div css={[t.px_6]}>
        <AdminSongNoteInput songId={song.id} />
      </div>
      <div css={[t.px_6, t.text_sm, t.text_tint_1]}>Notes</div>
      <AdminSongNoteList />
    </div>
  );
};

const AdminSongNoteInput = (props: { songId: string }) => {
  const songId = props.songId;
  const handleSubmit = React.useCallback(
    (values: AdminSongComment) => {
      AddSongMetadataComment(songId, values.message);
    },
    [songId]
  );

  return (
    <Formik initialValues={{ message: '' }} onSubmit={handleSubmit} enableReinitialize={true}>
      {(formikProps: FormikProps<AdminSongComment>) => {
        return (
          <div css={[t.mb_4]}>
            <Form>
              <div css={[t.flex, t.items_center]}>
                <AdminTextField<AdminSongComment>
                  formName={'AdminNote'}
                  name="message"
                  placeholder="Leave a note"
                  formikValues={formikProps.values}
                  fullWidth={true}
                />
                <AdminButton type="button" buttonType="submit" styleType="secondary" label="Submit" outerStyle={[t.ml_3]} />
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

const AdminSongNoteList = () => {
  const songMetadata = useSongMetadata();

  if (!songMetadata) return null;
  if (songMetadata.comments.length === 0) return null;

  return (
    <AdminEventList>
      {songMetadata.comments.map((comment, i) => {
        return (
          <AdminEventListItem key={i} label={`${comment.author} : `} timeStamp={comment.time}>
            <div css={[t.ml_1]}>{comment.message}</div>
          </AdminEventListItem>
        );
      })}
    </AdminEventList>
  );
};
